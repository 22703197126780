import { SalesEvent } from "@/services/backend/models/SalesEvent";
import { SalesStage } from "@/services/backend/models/SalesStage";

// based on "20221004_Phasen u. Ereignisse Geschäftspartner.xlsx" + additional change requests

export const salesEventsPerStage: Readonly<
  Record<SalesStage, readonly SalesEvent[]>
> = {
  [SalesStage.LAND_OWNER_STATED]: [SalesEvent.LANDOWNER_REGISTERED],
  [SalesStage.INITIAL_MEETING]: [SalesEvent.INITIAL_MEETING_SCHEDULED],
  [SalesStage.SHAPE_FILES]: [SalesEvent.SHAPE_FILES_RECEIVED],
  [SalesStage.LOCATION_ANALYSIS]: [SalesEvent.LOCATION_CHECK_PASSED],
  [SalesStage.VSV_IN_NEGOTIATION]: [SalesEvent.SERVICE_CONTRACT_IN_NEGOTIATION],
  [SalesStage.VSV_DECLINED]: [SalesEvent.SERVICE_CONTRACT_REFUSED],
  [SalesStage.VSV_COMPLETED]: [SalesEvent.SERVICE_CONTRACT_SIGNED],
  [SalesStage.ABORT]: [
    SalesEvent.LANDOWNER_HAS_CANCELED,
    SalesEvent.ABORTED_MILITARY,
    SalesEvent.ABORTED_BIRD_PROTECTION,
    SalesEvent.ABORTED_CIVIL_AVIATION,
    SalesEvent.ABORTED_NATURAL_RESERVE,
    SalesEvent.ABORTED_UNECONOMICAL,
    SalesEvent.ABORTED_FEEDBACK,
    SalesEvent.ABORTED_SINGLE_TURBINE,
    SalesEvent.ABORTED_OTHER,
  ],
};
