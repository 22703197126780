<script lang="ts" setup>
import { useRouter } from "vue-router";

import { useActivityService } from "@/app/process/service/ActivityService";

const props = defineProps<{
  muted?: boolean;
  onAfter?: () => void;
  activityId: string;
}>();

const router = useRouter();
const activityService = useActivityService();

function extend() {
  activityService.extendActivity(props.activityId, props.onAfter).then(
    (newActivityId) => {
      router
        .push({
          name: "activity",
          params: {
            activityId: newActivityId,
          },
        })
        .catch((error) => console.error(error));
    },
    (error) => console.error(error),
  );
}
</script>

<template>
  <VBtn
    :title="
      $t('processes.extendProcessTemplateButton.addProcessStepButtonLabel')
    "
    variant="plain"
    size="tiny"
    color="caeli5"
    icon="mdi-plus-box-multiple-outline"
    data-testid="add-activity-button"
    @click.stop.prevent="extend"
  />
</template>
