<script lang="ts" setup>
import { v4 as uuidv4 } from "uuid";
import { computed } from "vue";
import { useRouter } from "vue-router";

import FloatingActionButton from "@/app/base/button/FloatingActionButton.vue";
import { useI18n } from "@/app/base/utils/i18n";
import FilteredDataTable from "@/app/process/list/FilteredDataTable.vue";
import { useDocumentService } from "@/app/process/service/DocumentService";

const { t } = useI18n();
const router = useRouter();
const documentService = useDocumentService();

const isLoading = computed(() => documentService.isLoading());
const listRowItems = computed(() => documentService.getListRowItems());

const mandatoryColumns = [t("document.name")];
const initialColumns = [t("document.name"), t("document.entityType")];

async function createDocument() {
  await router.push({
    name: "documentEdit",
    params: { documentId: uuidv4() },
  });
}
</script>

<template>
  <VCard
    class="bg-grey-lighten-5 pa-10 pt-3 h-screen overflow-auto"
    variant="flat"
    :loading="isLoading"
  >
    <template #loader="{ isActive }">
      <VProgressLinear
        :active="isActive"
        color="caeli5"
        height="4"
        :indeterminate="true"
      />
    </template>
    <FilteredDataTable
      :searchByTextColumns="documentService.displayColumns"
      :exposedColumns="documentService.displayColumns"
      :initialColumns="initialColumns"
      :mandatoryColumns="mandatoryColumns"
      contextKey="documentListView"
      :rowItems="listRowItems"
      :availableTags="[]"
    />
  </VCard>

  <VTooltip location="start" :text="$t('document.create')">
    <template #activator="{ props }">
      <FloatingActionButton
        v-bind="props"
        icon="mdi-plus"
        primary
        data-testid="create-document"
        @click="createDocument"
      />
    </template>
  </VTooltip>
</template>
