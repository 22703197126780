<script setup lang="ts">
import { computed } from "vue";

import CheckboxGroupRow from "./CheckboxGroupRow.vue";
import { formStyles } from "./formStyles";
import { useRowCount } from "./RowProvider";

const props = withDefaults(
  defineProps<{
    minRows?: number;
    dataColumns?: number;
  }>(),
  {
    minRows: 0,
    dataColumns: 1,
  },
);

const rowCount = useRowCount();

const blankRows = computed(() => Math.max(0, props.minRows - rowCount.value));
</script>
<template>
  <VField v-bind="formStyles" class="checkbox-group">
    <div class="area-checkboxes">
      <slot />
      <CheckboxGroupRow v-for="i of blankRows" :key="i" placeholder />
    </div>
  </VField>
</template>

<style scoped>
.v-field {
  --v-input-padding-top: var(--v-input-padding-bottom, 6px);
  flex: 0;
}

.area-checkboxes {
  flex: 1;
  margin: 8px;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: [checkbox] max-content 1fr repeat(
      v-bind(dataColumns),
      max-content
    );
  align-items: center;
}

.v-field > :deep(.v-field__field) {
  overflow-y: auto;
}

:deep(.full-width) {
  grid-column: 1 / -1;
}
</style>
