import {
  computed,
  inject,
  type InjectionKey,
  onUnmounted,
  provide,
  readonly,
  type Ref,
  ref,
} from "vue";

export enum FunnelColumn {
  FUNNEL = "FUNNEL",
  LABEL = "LABEL",
}

interface FunnelProvider {
  registerGroup(widths: Readonly<Ref<FunnelColumn[]>>): void;

  register(): Ref<boolean>;
  count?: Ref<number>;
}

const FunnelProviderKey: InjectionKey<FunnelProvider> =
  Symbol("FunnelProviderKey");

/**
 * Define a group of funnels.
 *
 * This will count all nested funnels, displaying labels only for the last
 * child. Note that it cannot be nested inside another funnel group.
 *
 * @returns The number of funnels in the group
 */
export const useGroupFunnels = () => {
  const count = ref(0);
  const columns = computed(() => [
    ...Array.from<FunnelColumn>({ length: count.value }).fill(
      FunnelColumn.FUNNEL,
    ),
    FunnelColumn.LABEL,
  ]);
  const parent = inject(FunnelProviderKey, undefined);
  parent?.registerGroup(columns);

  provide(FunnelProviderKey, {
    register() {
      count.value += 1;
      const countAtInsertion = count.value;

      onUnmounted(() => {
        count.value -= 1;
      });

      return computed(() => countAtInsertion === count.value);
    },
    registerGroup() {
      throw new Error("cannot nest useGroupFunnels");
    },
    count: readonly(count),
  });

  return count;
};

/**
 * Define a container of funnels.
 *
 * This will keep a track of the number of funnels and labels, so that the grid
 * layout can be correctly applied.
 *
 * @returns The number of funnels in the group
 */
export const useChildFunnels = () => {
  type ChildColumn = Readonly<Ref<readonly FunnelColumn[]>>;
  const childColumns = ref<ChildColumn[]>([]);

  const columns = computed(() =>
    childColumns.value.flatMap((childColumn) => childColumn.value),
  );
  const parent = inject(FunnelProviderKey, undefined);
  parent?.registerGroup(columns);

  function removeColumn(childColumn: ChildColumn) {
    const index = childColumns.value.indexOf(childColumn);
    if (index > -1) {
      childColumns.value.splice(index, 1);
    }
  }

  provide(FunnelProviderKey, {
    register() {
      const childColumn = readonly(
        ref([FunnelColumn.FUNNEL, FunnelColumn.LABEL]),
      );
      childColumns.value.push(childColumn);
      onUnmounted(() => removeColumn(childColumn));
      return computed(() => true);
    },
    registerGroup(childColumn) {
      childColumns.value.push(childColumn);

      onUnmounted(() => removeColumn(childColumn));
    },
  });

  return columns;
};

/**
 * Register a funnel
 *
 * @returns the number of columns that should be labelled by this funnel,
 * or zero if no labels should be shown.
 */
export const registerFunnel = (): Ref<number> => {
  const provider = inject(FunnelProviderKey, undefined);

  const shouldShowLabels = provider?.register();

  return computed(() => {
    if (!provider) {
      return 1;
    }
    if (!shouldShowLabels?.value) {
      return 0;
    } else {
      return provider.count?.value ?? 1;
    }
  });
};
