/* istanbul ignore file */
/* eslint-disable */
import type { AfterSalesStage } from "../models/AfterSalesStage";
import type { Area } from "../models/Area";
import type { Project } from "../models/Project";
import type { ProjectView } from "../models/ProjectView";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ProjectControllerService {
  /**
   * @returns Project OK
   * @throws ApiError
   */
  public static getProject({
    projectId,
  }: {
    projectId: number;
  }): CancelablePromise<Project> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/pisys/v1/projects/{projectId}",
      path: {
        projectId: projectId,
      },
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static updateProject({
    projectId,
    requestBody,
  }: {
    projectId: number;
    requestBody: Project;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/api/pisys/v1/projects/{projectId}",
      path: {
        projectId: projectId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static deleteProject({
    projectId,
  }: {
    projectId: number;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/api/pisys/v1/projects/{projectId}",
      path: {
        projectId: projectId,
      },
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static updateProjectStage({
    projectId,
    afterSalesStage,
  }: {
    projectId: number;
    afterSalesStage: AfterSalesStage;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/api/pisys/v1/projects/{projectId}/stage/{afterSalesStage}",
      path: {
        projectId: projectId,
        afterSalesStage: afterSalesStage,
      },
    });
  }

  /**
   * @returns ProjectView OK
   * @throws ApiError
   */
  public static getProjects({
    customerId,
    notLinkedToLandOwner,
    notLinkedToProjectDeveloper,
    withInfrawindInfo,
  }: {
    customerId?: number;
    notLinkedToLandOwner?: boolean;
    notLinkedToProjectDeveloper?: boolean;
    withInfrawindInfo?: boolean;
  }): CancelablePromise<Array<ProjectView>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/pisys/v1/projects",
      query: {
        customerId: customerId,
        notLinkedToLandOwner: notLinkedToLandOwner,
        notLinkedToProjectDeveloper: notLinkedToProjectDeveloper,
        withInfrawindInfo: withInfrawindInfo,
      },
    });
  }

  /**
   * @returns Project OK
   * @throws ApiError
   */
  public static createProject({
    requestBody,
  }: {
    requestBody: Project;
  }): CancelablePromise<Project> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/pisys/v1/projects",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static linkProjectWithCustomers({
    projectId,
    customerIds,
  }: {
    projectId: number;
    customerIds: Array<number>;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/pisys/v1/projects/{projectId}/customer-links",
      path: {
        projectId: projectId,
      },
      query: {
        customerIds: customerIds,
      },
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static addAreaLink({
    projectId,
    areaId,
  }: {
    projectId: number;
    areaId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/pisys/v1/projects/{projectId}/area-links",
      path: {
        projectId: projectId,
      },
      query: {
        areaId: areaId,
      },
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static deleteAreaLinks({
    projectId,
    areaId,
  }: {
    projectId: number;
    areaId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/api/pisys/v1/projects/{projectId}/area-links",
      path: {
        projectId: projectId,
      },
      query: {
        areaId: areaId,
      },
    });
  }

  /**
   * @returns Area OK
   * @throws ApiError
   */
  public static getLinkableAreas({
    projectId,
  }: {
    projectId: number;
  }): CancelablePromise<Array<Area>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/pisys/v1/projects/{projectId}/linkable-areas",
      path: {
        projectId: projectId,
      },
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static unlinkProjectFromCustomer({
    projectId,
    customerId,
  }: {
    projectId: number;
    customerId: number;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/api/pisys/v1/projects/{projectId}/customer-links/{customerId}",
      path: {
        projectId: projectId,
        customerId: customerId,
      },
    });
  }
}
