import { type Component, h } from "vue";

/**
 * Extract all props from all components, ignoring typechecking
 * @param components The components to extract the props from.
 */
const combineProps = (components: Component[]) => {
  const props = new Set<string>();
  for (const component of components) {
    if ("props" in component) {
      if (component.props instanceof Array) {
        component.props.forEach((prop) => props.add(prop));
      } else if (typeof component.props === "object") {
        Object.keys(component.props).forEach((prop) => props.add(prop));
      } else {
        // Do nothing, props should be in an array or an object
      }
    }
  }
  return [...props];
};

/**
 * Construct a fragment containing all the provided components
 * @param components The components to render
 * @returns A component that renders to a sequence of the provided components
 */
export const Sequence = (...components: Component[]): Component =>
  Object.assign<Component, { props: string[] }>(
    (props) => components.map((component) => h(component, props)),
    { props: combineProps(components) },
  );
