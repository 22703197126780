<script setup lang="tsx">
import { computed, onMounted, onUnmounted, ref } from "vue";

import listViewBackground from "@/assets/listViewBackground.jpg";

const props = withDefaults(
  defineProps<{
    showBackgroundImage?: boolean;
    noMarginWidth?: number;
  }>(),
  {
    showBackgroundImage: true,
    noMarginWidth: 1400,
  },
);

// Resize the left padding to get a nicer view on the image if space is available
const windowHeight = ref(window.innerHeight);
const windowWidth = ref(window.innerWidth);
const onWindowSizeChange = () => {
  windowHeight.value = window.innerHeight;
  windowWidth.value = window.innerWidth;
};
onMounted(() => window.addEventListener("resize", onWindowSizeChange));
onUnmounted(() => window.removeEventListener("resize", onWindowSizeChange));

const marginPxDefault = props.showBackgroundImage ? 64 : 16;

const marginPxLeft = computed(() => {
  if (props.showBackgroundImage && windowWidth.value > props.noMarginWidth) {
    return windowWidth.value / 5;
  } else {
    return marginPxDefault;
  }
});

const marginPxTop = props.showBackgroundImage ? marginPxDefault : 0;
</script>

<template>
  <div
    v-if="showBackgroundImage"
    class="data-table-container"
    :style="{ backgroundImage: `url(${listViewBackground})` }"
  >
    <div class="data-table-wrapper">
      <slot />
    </div>
  </div>
  <div v-else class="data-table-container">
    <div class="data-table-wrapper">
      <slot />
    </div>
  </div>
</template>

<style scoped>
.data-table-container {
  position: sticky;
  height: 100%;
  width: 100%;
  background-size: cover;
  overflow-y: hidden;
}

.data-table-wrapper {
  background-color: white;
  margin-left: calc(v-bind(marginPxLeft) * 1px);
  margin-right: calc(v-bind(marginPxDefault) * 1px);
  margin-top: calc(v-bind(marginPxTop) * 1px);
  height: calc(100% - v-bind(marginPxTop) * 1px);
}
</style>
