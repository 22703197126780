<script lang="ts" setup>
import { computed } from "vue";

import TextDisplay from "@/app/base/form/TextDisplay.vue";
import { useI18n } from "@/app/base/utils/i18n";
import ProcessOutputField from "@/app/process/output/ProcessOutputField.vue";
import { useActivityService } from "@/app/process/service/ActivityService";
import { useProcessService } from "@/app/process/service/ProcessService";
import { isValueProvided } from "@/app/process/utils";

const { t } = useI18n();

const props = defineProps<{
  processActivityId: string;
  activityOutputId: string;
  customCaption?: string;
}>();

const activityService = useActivityService();
const processService = useProcessService();

const output = computed(() => {
  return processService.getProcessOutput(
    props.processActivityId,
    props.activityOutputId,
  );
});

const isOutputImportant = computed(() => {
  return output.value?.important ?? activityOutput.value?.important ?? false;
});

function toggleImportant() {
  if (!output.value) {
    return;
  }
  processService
    .createOrUpdateOutput({
      id: output.value?.id,
      important: !isOutputImportant.value,
      processActivityId: props.processActivityId,
      activityOutputId: props.activityOutputId,
    })
    .catch((reason) => {
      console.error(reason);
    });
}

const activityOutput = computed(() => {
  return activityService.getOutput(props.activityOutputId);
});
</script>

<template>
  <VCard v-if="activityOutput" variant="flat">
    <div class="mt-n1 pl-4 text-caption text-blue-grey-lighten-3">
      {{ props.customCaption ?? t("processes.output") }}
    </div>
    <div class="d-flex ga-4 align-start px-3">
      <VCheckboxBtn
        :modelValue="isValueProvided(activityOutput, output)"
        color="caeli6"
        density="compact"
        :disabled="true"
      />
      <div class="d-flex flex-column w-100">
        <div class="d-flex justify-space-between">
          <span
            class="text-caeli6 text-wrap text-body-2"
            style="padding-top: 0.3rem"
            >{{ activityOutput.name ?? "" }}
          </span>
          <VTooltip location="start" :text="t('processes.outputFavorite')">
            <template #activator="{ props: activator }">
              <VIcon
                v-bind="activator"
                :icon="isOutputImportant ? 'mdi-star' : 'mdi-star-outline'"
                class="text-caeli5"
                @click="toggleImportant"
              />
            </template>
          </VTooltip>
        </div>
        <TextDisplay
          v-if="activityOutput.description"
          :value="activityOutput.description"
          markdown
        />
        <div
          class="d-flex justify-start align-center text-caeli6 w-100 text-body-2 mt-2"
        >
          <ProcessOutputField
            :processActivityId="props.processActivityId"
            :activityOutputId="props.activityOutputId"
          />
        </div>
      </div>
    </div>
  </VCard>
</template>
