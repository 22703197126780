import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import { useI18n } from "@/app/base/utils/i18n";
import { Action, usePromptService } from "@/app/notification/PromptService";
import { createFieldCells } from "@/app/process/list/FilteredDataTableUtil";
import { type CellContent, type RowItem } from "@/app/process/list/TableTypes";
import { useFieldService } from "@/app/process/service/FieldService";
import { usePersonStore } from "@/app/process/service/persistence/person/PersonStore";
import {
  type EntityRelationship,
  EntityType,
  type FieldKeyEto,
  GetPersonReferencesDocument,
  type PersonDto,
  type PersonEto,
  useGetFieldKeysTopUsedQuery,
} from "@/gql/types";
import { apolloClient } from "@/plugins/apollo";

export const usePersonService = defineStore("PersonService", () => {
  const personStore = usePersonStore();
  const promptService = usePromptService();
  const fieldService = useFieldService();
  const router = useRouter();
  const { t } = useI18n();

  const translations = {
    yes: t("boolean.yes"),
    no: t("boolean.no"),
    person: {
      name: t("person.name"),
      reference: t("person.reference"),
      referenceName: t("person.referenceName"),
      referenceType: t("person.referenceType"),
    },
  };

  const { onResult: onResultTopUsed, loading: loadingTopUsed } =
    useGetFieldKeysTopUsedQuery({
      limit: 3,
      entityType: EntityType.Person,
    });
  onResultTopUsed((result) => {
    if (loadingTopUsed.value) {
      return;
    }
    topUsedFieldKeys.value = result.data.fieldKeysTopUsed;
    fieldService.registerLoadedFieldKeys(result.data.fieldKeysTopUsed);
  });
  const topUsedFieldKeys = ref<FieldKeyEto[]>();

  // todo refactor this to use a UiService where each dialog has its own enum
  const isDeleteDialogOpen = ref(false);

  function openDeleteDialog() {
    isDeleteDialogOpen.value = true;
  }

  function closeDeleteDialog() {
    isDeleteDialogOpen.value = false;
  }

  function deleteAndGoToList(id: string) {
    personStore.deleteById(id).then(
      () => {
        promptService.success(id, Action.DELETE);
        void router.push({
          name: "personList",
        });
      },
      (reason) => promptService.failure(id, Action.DELETE, reason),
    );
  }

  function getListRowItems() {
    const results: RowItem[] = [];
    const persons = personStore.getAll();
    for (const person of persons) {
      const cells: Record<string, CellContent> = {
        ...createPersonNameCell(person),
        ...createFieldCells(person.id),
      };

      const tags = fieldService
        .getTagFieldValues(person.id)
        .flatMap(
          (fieldValue) =>
            fieldService.getFieldKey(fieldValue.fieldKeyId)?.name ?? [],
        );

      const rowItem: RowItem = {
        key: person.id,
        cells: cells,
        tags: tags,
      };

      results.push(rowItem);
    }
    return results;
  }

  function createPersonNameCell(
    person: PersonEto,
  ): Record<string, CellContent> {
    return {
      [translations.person.name]: {
        content: person.name?.toString(),
        props: { class: "pointer" },
        events: {
          click: () =>
            router.push({
              name: "personView",
              params: { personId: person.id },
            }),
        },
      },
    };
  }

  const displayColumns = computed((): string[] => {
    return [translations.person.name].concat(
      fieldService
        .getNonTagFieldKeys(EntityType.Person)
        .flatMap((key) => (key.name ? [key.name] : []))
        .sort((a, b) => a.localeCompare(b)),
    );
  });

  function createOrUpdate(personDto: PersonDto) {
    const id = personDto.id;
    personStore.createOrUpdate(personDto).then(
      () => promptService.success(id, Action.SAVE, personDto.name ?? ""),
      (reason) => promptService.failure(id, Action.SAVE, reason),
    );
    return id;
  }

  async function getPersonReferenceRowItems(id: string) {
    const person = await apolloClient
      .query<Record<string, PersonEto[]>>({
        query: GetPersonReferencesDocument,
        variables: {
          filter: {
            ids: [id],
          },
        },
      })
      .then((result) => {
        return result.data.person[0];
      });

    if (person.references && person.references.length > 0) {
      return createPersonReferenceRowItems(person.references);
    } else {
      return [];
    }
  }

  function createPersonReferenceRowItems(
    entityRelationships: EntityRelationship[],
  ): RowItem[] {
    return entityRelationships.map((entityRelationship) => {
      return {
        cells: {
          [translations.person.reference]: {
            content: entityRelationship.fieldName,
            props: { class: "pointer" },
          },
          [translations.person.referenceName]: {
            content: entityRelationship.name ?? undefined,
            props: { class: "pointer" },
            events: {
              click: () =>
                router.push({
                  name: "personView",
                  params: { personId: entityRelationship.entityId },
                }),
            },
          },
          [translations.person.referenceType]: {
            content: entityRelationship.entityType,
            props: { class: "pointer" },
          },
        },
        tags: [],
        key: entityRelationship.id,
      };
    });
  }

  return {
    isLoading: (id?: string) =>
      id ? personStore.isLoading(id) : personStore.isLoadingAll,
    getById: (id: string) => personStore.getById(id),
    getAll: () => personStore.getAll(),
    createOrUpdate,
    deleteAndGoToList,
    openDeleteDialog,
    closeDeleteDialog,
    isDeleteDialogOpen,
    displayColumns,
    getListRowItems,
    getPersonReferenceRowItems,
    getTopUsedFieldKeys: computed(() => topUsedFieldKeys.value),
  };
});
