<script setup lang="ts">
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import FloatingActionButton from "@/app/base/button/FloatingActionButton.vue";
import StartProcessDialogAny from "@/app/process/action/start/StartProcessDialogAny.vue";
import ProcessTable from "@/app/process/list/ProcessTable.vue";
import { useProcessService } from "@/app/process/service/ProcessService";
import {
  PROCESS_DIALOG,
  useProcessUIStore,
} from "@/app/process/service/ProcessUIStore";
import { RestService } from "@/app/process/service/RestService";
import { compareByName } from "@/app/process/utils";

const router = useRouter();

const processService = useProcessService();
const processUiStore = useProcessUIStore();

const isLoading = computed(() => processService.isLoading());
const isDownloading = ref<boolean>(false);

const processes = computed(() => {
  return processService.getProcesses().sort(compareByName);
});

async function downloadProcessListStats() {
  isDownloading.value = true;
  await RestService.downloadProcessListStats();
  isDownloading.value = false;
}
</script>

<template>
  <VCard
    class="bg-grey-lighten-5 pa-10 pt-3 h-screen overflow-y-auto text-caeli6"
    variant="flat"
    :loading="isLoading"
  >
    <template #loader="{ isActive }">
      <VProgressLinear
        :active="isActive"
        color="caeli5"
        height="4"
        :indeterminate="true"
        data-testid="process-list-view-loading"
      ></VProgressLinear>
    </template>
    <VTooltip location="start" :text="$t('processes.startButton.startProcess')">
      <template #activator="{ props }">
        <FloatingActionButton
          v-bind="props"
          icon="mdi-play"
          primary
          @click="() => processUiStore.openDialog(PROCESS_DIALOG.START_ANY)"
        />
      </template>
    </VTooltip>
    <VTooltip location="start" :text="$t('processes.listView.goToTemplates')">
      <template #activator="{ props }">
        <FloatingActionButton
          v-bind="props"
          icon="mdi-pencil-box-multiple"
          data-testid="go-to-process-templates"
          @click="
            () => {
              router.push({ name: 'processTemplateList' });
            }
          "
        />
      </template>
    </VTooltip>
    <VTooltip location="start" :text="$t('processes.listView.downloadJSON')">
      <template #activator="{ props }">
        <FloatingActionButton
          v-bind="props"
          :loading="isDownloading"
          icon="mdi-download"
          data-testid="download-json-data"
          secondary
          @click="downloadProcessListStats()"
        />
      </template>
    </VTooltip>
    <StartProcessDialogAny />
    <div class="d-flex flex-column align-start flex-nowrap ga-4 mx-8">
      <div v-if="!processes.length" class="text-center w-100">
        <VIcon
          class="mt-16"
          color="blue-grey-lighten-3"
          size="100"
          icon="mdi-sim-off-outline"
        />
        <VCardTitle class="mt-4 text-blue-grey-lighten-2"
          >{{ $t("processes.listView.noProcesses") }}
        </VCardTitle>
      </div>
      <div v-else class="w-100 text-caeli6">
        <p class="text-h6 py-3">
          {{ $t("processes.listView.processesTitle") }}
        </p>
        <ProcessTable />
      </div>
    </div>
  </VCard>
</template>
