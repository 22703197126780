import { defineAttachmentStore } from "@/app/common/attachments/store/AbstractAttachmentStore";
import { type Note } from "@/services/backend/models/Note";
import { NoteControllerService } from "@/services/backend/services/NoteControllerService";

export const useNoteStore = defineAttachmentStore<Note>({
  storeId: "note",
  service: {
    getAll: (params) => NoteControllerService.getNotes({ ...params }),
    create: (requestBody) => NoteControllerService.createNote({ requestBody }),
    update: (id, requestBody) =>
      NoteControllerService.updateNote({ noteId: id, requestBody }),
    remove: (id) => NoteControllerService.deleteNote({ noteId: id }),
  },
});
