<script setup lang="ts">
defineProps<{
  stageLabel: string;
  onClickLabel?: () => void;
}>();
</script>

<template>
  <a :class="{ link: onClickLabel }" @click="onClickLabel">
    <h5 class="text-pre-title pt-4">{{ stageLabel }}</h5>
  </a>
</template>

<style scoped>
a.link {
  cursor: pointer;
  text-decoration: underline;
}
</style>
