<script setup lang="ts">
import { computed, type Ref, ref, watch } from "vue";
import { useRouter } from "vue-router";

import FloatingActionButton from "@/app/base/button/FloatingActionButton.vue";
import AppDialog from "@/app/base/dialog/AppDialog.vue";
import { useI18n } from "@/app/base/utils/i18n";
import ReadonlyField from "@/app/process/field/ReadonlyField.vue";
import TagsDisplay from "@/app/process/field/TagsDisplay.vue";
import FilteredDataTable from "@/app/process/list/FilteredDataTable.vue";
import { type RowItem } from "@/app/process/list/TableTypes";
import { useMessageService } from "@/app/process/service/MessageService";
import { usePersonService } from "@/app/process/service/PersonService";
import EntityFieldsCard from "@/app/process/single/EntityFieldsCard.vue";
import { EntityType } from "@/gql/types";

const props = defineProps<{
  personId: string;
}>();

const personService = usePersonService();
const messageService = useMessageService();
const router = useRouter();
const { t } = useI18n();
const tab = ref<"persons" | "messages">("persons");
const person = computed(() => personService.getById(props.personId));
const personReferencesColumns = [
  t("person.reference"),
  t("person.referenceName"),
  t("person.referenceType"),
];
const personReferencesRowItems: Ref<RowItem[]> = ref([]);
const messageReferencesMandatoryColumns = [t("message.title")];
const messageReferencesInitialColumns = [t("message.creationDate")];
const messageReferencesRowItems: Ref<RowItem[]> = ref([]);

watch(
  [tab, () => props.personId],
  async ([newTab]) => {
    switch (newTab) {
      case "persons":
        personReferencesRowItems.value =
          await personService.getPersonReferenceRowItems(props.personId);
        break;
      case "messages":
        messageReferencesRowItems.value =
          await messageService.getMessageReferenceRowItems(props.personId);
        break;
    }
  },
  { immediate: true },
);

async function navigateToEditPage() {
  await router.push({
    name: "personEdit",
    params: { personId: props.personId },
  });
}
</script>

<template>
  <AppDialog
    v-if="person"
    v-model="personService.isDeleteDialogOpen"
    :title="t('person.deletePerson')"
    :confirmMessage="t('action.delete')"
    :cancelMessage="t('action.cancel')"
    destructive
    @confirm="
      () => {
        personService.deleteAndGoToList(props.personId);
        personService.closeDeleteDialog();
      }
    "
    @cancel="personService.closeDeleteDialog"
  >
    <p>
      {{
        t("person.deletePersonText", {
          name: person?.name,
        })
      }}
    </p>
  </AppDialog>

  <VCard
    v-if="person"
    class="activity bg-grey-lighten-5 pa-10 pt-3 h-screen overflow-y-auto text-caeli6"
    variant="flat"
  >
    <div class="d-flex align-center justify-space-between">
      <VCardTitle>{{ t("person.person") }}</VCardTitle>
      <TagsDisplay :entityId="props.personId" justify="end" />
    </div>
    <VCard class="pa-4 d-flex flex-column ga-4">
      <ReadonlyField
        :label="t('person.name')"
        :value="person.name ?? ''"
        data-testid="person-view-name"
      />

      <EntityFieldsCard
        :entityType="[EntityType.Person]"
        :entityId="props.personId"
        readonly
      />
    </VCard>
    <VCardTitle class="mt-4">{{ t("person.reference", 2) }}</VCardTitle>
    <VCard>
      <VTabs v-model="tab">
        <VTab value="persons"> {{ t("person.person", 2) }}</VTab>
        <VTab value="messages" data-testid="person-view-messages">
          {{ t("message.message", 2) }}
        </VTab>
      </VTabs>
      <VCardText>
        <VWindow v-model="tab">
          <VWindowItem value="persons">
            <FilteredDataTable
              :searchByTextColumns="personReferencesColumns"
              :exposedColumns="personReferencesColumns"
              contextKey="personViewPersonReferences"
              :rowItems="personReferencesRowItems"
              :availableTags="[]"
              :no-data-text="t('person.noReferences')"
            />
          </VWindowItem>
          <VWindowItem value="messages">
            <FilteredDataTable
              :mandatoryColumns="messageReferencesMandatoryColumns"
              :initialColumns="messageReferencesInitialColumns"
              contextKey="personViewMessageReferences"
              :rowItems="messageReferencesRowItems"
              :availableTags="[]"
              :no-data-text="t('person.noMessageReferences')"
              :searchByTextColumns="messageService.listDisplayColumns"
              :exposedColumns="messageService.listDisplayColumns"
              :sortBy="[{ key: t('message.creationDate'), order: 'desc' }]"
              :mustSort="true"
            />
          </VWindowItem>
        </VWindow>
      </VCardText>
    </VCard>
    <FloatingActionButton
      icon="mdi-pencil"
      first
      primary
      data-testid="person-edit"
      @click="navigateToEditPage"
    />
    <FloatingActionButton
      icon="mdi-trash-can-outline"
      data-testid="person-delete"
      color="error"
      @click="personService.openDeleteDialog"
    />
  </VCard>
</template>
