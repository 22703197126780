<script setup lang="ts">
import { computed, nextTick, ref, toRef, watch } from "vue";

import AppButton from "@/app/base/button/AppButton.vue";
import { useDialog } from "@/app/base/dialog/useDialog";
import { useFontsReady } from "@/app/base/utils/fonts";
import { DateFormat, useI18n } from "@/app/base/utils/i18n";
import { useEventStore } from "@/app/common/attachments/store/EventStore";
import { type Event } from "@/services/backend/models/Event";

const { t, d } = useI18n();

const { confirm } = useDialog();

const eventStore = useEventStore();

const props = defineProps<{
  shown: boolean;
  event: Event;
  editBlocked?: boolean;
}>();

const shown = toRef(props, "shown");

const emit = defineEmits<(e: "edit") => void>();

const dateLabel = computed(() => {
  if (props.event.lastModificationDate) {
    return t("attachment.lastEdit");
  } else {
    return t("attachment.createdAt");
  }
});

const date = computed(() => {
  return props.event.lastModificationDate ?? props.event.creationDate;
});

const deleteAttachment = async () => {
  if (
    props.event.id &&
    (await confirm({
      title: t("attachment.deleteWarningTitle"),
      message: t("ui.deleteWarningMessage"),
      destructive: true,
      confirmMessage: t("ui.delete"),
    }))
  ) {
    await eventStore.remove(props.event.id);
  }
};

// The first paragraph, if multiple paragraphs (marked by a double line break)
// are present. This is used to force the “expand” button to be shown.
const firstParagraph = computed(() => {
  if (!props.event.customDescription) {
    return undefined;
  }
  const match = /\n\s*?\n/.exec(props.event.customDescription);
  if (match?.index) {
    return props.event.customDescription.slice(0, match.index) + "…";
  }
  return undefined;
});

const contentElement = ref<HTMLElement | undefined>();
const expanded = ref(false);
const expandable = ref(true);

// When any fonts have been loaded, recalculate whether the body is expandable
const fontsReady = useFontsReady();
watch(
  () => [
    shown.value,
    contentElement.value,
    expanded.value,
    props.event.customDescription,
    fontsReady.value,
  ],
  async () => {
    const noteEl = contentElement.value;

    if (
      !props.event.customDescription ||
      props.event.customDescription.length === 0
    ) {
      expandable.value = false;
      return;
    }

    if (!noteEl || expanded.value) {
      return;
    }
    await nextTick();
    expandable.value =
      !!firstParagraph.value || noteEl.offsetHeight < noteEl.scrollHeight;
  },
  { immediate: true },
);

const eventType = (event: Event): string => {
  return eventStore.getEventTypeLabel(
    event.qualificationEvent?.toString() ??
      event.salesEvent?.toString() ??
      event.afterSalesEvent?.toString() ??
      "",
  );
};

function toggleExpand() {
  if (!expandable.value) {
    return;
  }
  expanded.value = !expanded.value;
}
</script>

<template>
  <VCard
    class="container border1"
    :elevation="0"
    v-on="expandable ? { click: toggleExpand } : {}"
  >
    <VTooltip openDelay="500" activator="parent" location="bottom">
      <p v-if="date">{{ dateLabel }}: {{ d(date, DateFormat.MEDIUM) }}</p>
      <p v-if="event.creator">
        {{ t("attachment.editor") }}: {{ event.creator }}
      </p>
    </VTooltip>

    <div class="event-container">
      <div class="event-header gradient1">
        <div class="text-attachment-title">
          {{ eventType(event) }}
        </div>
        <VSpacer />
        <AppButton
          icon="mdi-pencil"
          :label="t('ui.edit')"
          :disabled="editBlocked === false"
          @clickWithEvent.stop="emit('edit')"
        />
        <AppButton
          icon="mdi-delete-outline"
          :label="t('ui.delete')"
          @clickWithEvent.stop="deleteAttachment"
        />
      </div>

      <VDivider v-if="event.customDescription" />

      <div
        v-if="event.customDescription"
        ref="contentElement"
        :class="
          !expanded && expandable ? 'event-content-collapsed' : 'event-content'
        "
      >
        {{ event.customDescription }}
      </div>
    </div>
  </VCard>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 100%;
}

.event-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.event-header {
  padding: 0.5em;
  display: flex;
  flex: 1;
  align-items: center;
}

.event-content {
  padding: 0.5rem;
}

.event-content-collapsed {
  -webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
  max-height: 3rem;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
