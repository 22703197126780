<script setup lang="ts">
import { toRef } from "vue";
import { useRouter } from "vue-router";

import { useI18n } from "@/app/base/utils/i18n";
import DataTable from "@/app/common/table/DataTable.vue";
import DataTableContainer from "@/app/common/table/DataTableContainer.vue";
import { tableDef } from "@/app/common/table/DataTableRenderer";
import { type FunnelStage } from "@/app/reporting/common/FunnelStage";
import ReportingFunnel from "@/app/reporting/common/ReportingFunnel.vue";
import {
  ContractReportingStatus,
  type LandOwnerSalesStageByOpportunityWithContractStatus,
} from "@/app/reporting/landOwnerContract/LandOwnerContractReportingUtil";

const props = defineProps<{
  funnelData: FunnelStage[];
  landOwnersByOpp: LandOwnerSalesStageByOpportunityWithContractStatus[];
}>();
const { t, tEnum, locale } = useI18n();

const router = useRouter();

const contractStatusSortOrder = [
  ContractReportingStatus.EMPTY,
  ContractReportingStatus.VSV_SENT,
  ContractReportingStatus.VSV_COMPLETED,
  ContractReportingStatus.VSV_REJECTED,
];

const table = tableDef<LandOwnerSalesStageByOpportunityWithContractStatus>(
  (col) => [
    col
      .index("landOwnerName")
      .label(t("reporting.landOwnerReports"))
      .searchable(true)
      .comparator((a, b) => a.localeCompare(b, locale.value))
      .onClick(async (row) => {
        try {
          await router.push(
            router.resolve({
              name: "landOwnerSingle",
              params: { customerId: row.landOwnerId },
            }).href,
          );
        } catch (error) {
          console.error(error);
        }
      })
      .grow(),
    col
      .index("opportunityName")
      .label(t("reporting.opportunity"))
      .searchable(true)
      .comparator((a, b) => a.localeCompare(b, locale.value))
      .onClick(async (row) => {
        try {
          await router.push(
            router.resolve({
              name: "opportunitySingle",
              params: { opportunityId: row.opportunityId },
              hash: "#areas",
            }).href,
          );
        } catch (error) {
          console.error(error);
        }
      })
      .grow(),
    col
      .index("contractStatus")
      .label(t("reporting.contractStatus"))
      .stringifier((status) =>
        tEnum("reporting.landOwnerContract.contractReportingStatus", status),
      )
      .comparator(
        (a, b) =>
          contractStatusSortOrder.indexOf(a) -
          contractStatusSortOrder.indexOf(b),
      )
      .searchable(true),
  ],
  "opportunityId",
).withData(toRef(props, "landOwnersByOpp"));
</script>

<template>
  <a class="title mb-4">{{
    t("reporting.landOwnerContract.landOwnerContractReports")
  }}</a>

  <ReportingFunnel
    :subtitle="`(${t('reporting.landOwnerContract.oppContractStatus')})`"
    :stages="funnelData"
  />
  <DataTableContainer class="table" :showBackgroundImage="false">
    <DataTable :table="table" />
  </DataTableContainer>
</template>

<style scoped>
.title {
  grid-column: span 3;
  text-align: center;
  font-size: 1.5em; /* h2 font size */
}

.table {
  width: 50em;
  grid-column: 3;
  grid-row: funnel;
}
</style>
