import {
  inject,
  type InjectionKey,
  onUnmounted,
  provide,
  readonly,
  ref,
} from "vue";

interface CountProvider {
  register(): void;
  unregister(): void;
}

/**
 * This is for counting descendants of a certain type within a parent component
 *
 * `useChildCount` returns a ref to the count of children registered using
 * `registerChild`.
 *
 * `registerChild` should be called in the child’s setup function.
 */
export const makeCounterPair = (provideKey: InjectionKey<CountProvider>) => {
  const useChildCount = () => {
    const count = ref(0);
    const parent = inject(provideKey, undefined);

    provide(provideKey, {
      register: () => {
        count.value++;
        parent?.register();
      },
      unregister: () => {
        count.value--;
        parent?.unregister();
      },
    });
    return readonly(count);
  };

  const registerChild = () => {
    const provider = inject(provideKey);
    if (provider) {
      provider.register();
      onUnmounted(() => {
        provider.unregister();
      });
    }
  };

  return { useChildCount, registerChild };
};
