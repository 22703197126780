import { defineStore } from "pinia";
import { computed, reactive } from "vue";

export const BG_CLASS_FAIL = "bg-red-lighten-5";
export const BG_CLASS_INFO = "bg-light-blue-lighten-5";
export const BG_CLASS_SUCCESS = "bg-green-lighten-5";
export const BG_CLASS_WARNING = "bg-yellow-lighten-4";

const CHECK_DECAY_INTERVAL_MILLIS = 500;
const SUCCESS_DECAY_MILLIS = 3000;

export enum Level {
  Error = 0,
  Warning = 1,
  Info = 2,
}

export enum Action {
  DELETE = "DELETE",
  SAVE = "SAVE",
  RELEASE = "RELEASE",
  REVISION = "REVISION",
  UPGRADE = "UPGRADE",
  UPLOAD = "UPLOAD",
}

export interface Prompt {
  key: string;
  description?: string;
  level: Level;
  creationTime: number;
  title?: string;
  decayMillis?: number;
  customClass?: string;
  icon?: string;
  action?: Action;
  validationErrors?: ValidationError[];
}

export interface ApolloError {
  graphQLErrors: ValidationError[];
}

export interface ValidationError {
  message: string;
  locations?: { line: number; column: number }[];
  path?: string[];
  extensions: { classification: string };
}
export enum ValidationCode {
  NOT_FOUND = "NOT_FOUND",
  NOT_NULL = "NOT_NULL",
  ALREADY_EXISTS = "ALREADY_EXISTS",
  NULL = "NULL",
  DELETION_FAILED = "DELETION_FAILED",
  HAS_CYCLE = "HAS_CYCLE",
  UNIQUE_VIOLATION = "UNIQUE_VIOLATION",
  INVALID_FORMAT = "INVALID_FORMAT",
}

export const usePromptService = defineStore("prompts", () => {
  const promptMap = reactive(new Map<string, Prompt>());
  const prompts = computed(() => Array.from(promptMap.values()));

  function customPrompt(key: string, fields: Partial<Prompt>) {
    promptMap.set(key, {
      ...fields,
      key,
      description: fields.description,
      level: fields.level ?? Level.Info,
      creationTime: Date.now(),
    });
  }

  function success(key: string, action: Action, name?: string) {
    customPrompt(`${key}-${action.toString().toLowerCase()}-success`, {
      icon: "mdi-check",
      title: name,
      decayMillis: SUCCESS_DECAY_MILLIS,
      action,
    });
  }

  function failure(key: string, action: Action, reason: string | ApolloError) {
    customPrompt(`${key}-${action.toString().toLowerCase()}-fail`, {
      level: Level.Error,
      description: typeof reason === "string" ? reason : undefined,
      action,
      validationErrors:
        typeof reason === "string" ? undefined : reason.graphQLErrors,
    });
    console.error(reason);
  }

  function checkPrompts() {
    for (const key of promptMap.keys()) {
      const prompt = promptMap.get(key);
      if (
        prompt?.decayMillis &&
        prompt.creationTime <= Date.now() - prompt.decayMillis
      ) {
        promptMap.delete(key);
      }
    }
  }

  setInterval(checkPrompts, CHECK_DECAY_INTERVAL_MILLIS);

  return {
    prompts,
    success,
    failure,
    customPrompt,
    deletePrompt: (key: string) => promptMap.delete(key),
  };
});
