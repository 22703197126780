<script lang="ts" setup>
import { v4 as uuidv4 } from "uuid";
import { computed } from "vue";

import TextDisplay from "@/app/base/form/TextDisplay.vue";
import { Action, usePromptService } from "@/app/notification/PromptService";
import { useProcessService } from "@/app/process/service/ProcessService";
import { type ActivityTaskEto } from "@/gql/types";

const props = defineProps<{
  processActivityId: string;
  activityTask: ActivityTaskEto;
}>();

const processService = useProcessService();

const task = computed(() => {
  return processService.getTask(props.activityTask.id, props.processActivityId);
});

async function updateTask(taskDone: boolean) {
  const taskDto = task.value;
  if (!taskDone && taskDto) {
    processService
      .deleteTask(taskDto.id)
      .catch((reason) =>
        usePromptService().failure(taskDto.id, Action.DELETE, reason),
      );
  } else {
    await processService.createTask({
      id: uuidv4(),
      processActivityId: props.processActivityId,
      activityTaskId: props.activityTask.id,
    });
  }
}
</script>

<template>
  <VCard variant="flat">
    <div class="mt-n1 pl-4 text-caption text-blue-grey-lighten-3">
      {{ $t("processes.task") }}
    </div>
    <div
      class="px-3 d-flex ga-4"
      :class="props.activityTask.description ? 'align-start' : 'align-center'"
    >
      <VCheckboxBtn
        :modelValue="task !== undefined"
        color="caeli6"
        density="compact"
        @update:modelValue="updateTask"
      />
      <div class="w-100" style="margin-top: 0.3rem">
        <div class="d-flex justify-space-between">
          <p class="text-body-2 text-caeli6">{{ props.activityTask.title }}</p>
        </div>
        <div class="mt-3">
          <TextDisplay :value="props.activityTask.description ?? ''" markdown />
        </div>
      </div>
    </div>
  </VCard>
</template>
