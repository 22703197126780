<script setup lang="ts">
import { type ChartData } from "chart.js";
import { computed } from "vue";
import { useRouter } from "vue-router";

import { useI18n } from "@/app/base/utils/i18n";
import ProjectDeveloperReportingCharts from "@/app/reporting/projectDeveloper/ProjectDeveloperReportingCharts.vue";
import { BackendService } from "@/services/backend/BackendService";
import { type CompanyTypeCount } from "@/services/backend/models/CompanyTypeCount";
import { type ProbabilityCount } from "@/services/backend/models/ProbabilityCount";
import { type QualificationStageCount } from "@/services/backend/models/QualificationStageCount";
import { type SourceCount } from "@/services/backend/models/SourceCount";
import { ReportingControllerService } from "@/services/backend/services/ReportingControllerService";

const { tEnum } = useI18n();
const router = useRouter();

const BAR_COLOR = "#9EC2CE"; //funnel3

const getCounts = (
  data:
    | QualificationStageCount[]
    | ProbabilityCount[]
    | CompanyTypeCount[]
    | SourceCount[]
    | undefined,
) => data?.map((i) => i.count) ?? [];

//we can only set a max width for labels, so we pad the labels with many whitespaces that it always gets truncated
const LABEL_PADDING = "                                                  ";

const stageCount = BackendService.fetchReactively(() => {
  return ReportingControllerService.countProjectDevelopersByStage();
});

const stageChartData = computed(() => {
  return {
    labels: stageCount.value?.map(
      (c) => LABEL_PADDING + tEnum("customers.pdQualificationStage", c.stage),
    ),
    datasets: [
      {
        data: getCounts(stageCount.value),
        backgroundColor: BAR_COLOR,
      },
    ],
  } as ChartData;
});

const probabilityCount = BackendService.fetchReactively(() => {
  return ReportingControllerService.countProjectDevelopersByProbability();
});

const probabilityChartData = computed(() => {
  return {
    labels: probabilityCount.value?.map(
      (c) => LABEL_PADDING + tEnum("customers.pdProbability", c.probability),
    ),
    datasets: [
      {
        data: getCounts(probabilityCount.value),
        backgroundColor: BAR_COLOR,
      },
    ],
  } as ChartData;
});

const companyTypeCount = BackendService.fetchReactively(() => {
  return ReportingControllerService.countProjectDevelopersByCompanyType();
});

const companyTypeChartData = computed(() => {
  return {
    labels: companyTypeCount.value?.map(
      (c) => LABEL_PADDING + tEnum("customers.pdCompanyType", c.companyType),
    ),
    datasets: [
      {
        data: getCounts(companyTypeCount.value),
        backgroundColor: BAR_COLOR,
      },
    ],
  } as ChartData;
});

const sourceCount = BackendService.fetchReactively(() => {
  return ReportingControllerService.countProjectDevelopersBySource();
});

const sourceChartData = computed(() => {
  return {
    labels: sourceCount.value?.map(
      (c) => LABEL_PADDING + tEnum("customers.pdSource", c.source),
    ),
    datasets: [
      {
        data: getCounts(sourceCount.value),
        backgroundColor: BAR_COLOR,
      },
    ],
  } as ChartData;
});

async function openPdList(attribute: string, value: string) {
  const { href } = router.resolve({
    name: "projectDeveloperList",
    params: {
      attribute,
      value,
    },
  });
  await router.push(href);
}

// Clickhandlers that handle opening the filtered project-developer lists
const openPdListForStage = async (index: number) => {
  if (stageCount.value) {
    await openPdList("stage", `${stageCount.value[index].stage}`);
  }
};
const openPdListForProbability = async (index: number) => {
  if (probabilityCount.value) {
    await openPdList(
      "probability",
      `${probabilityCount.value[index].probability}`,
    );
  }
};
const openPdListForCompanyType = async (index: number) => {
  if (companyTypeCount.value) {
    await openPdList(
      "companyType",
      `${companyTypeCount.value[index].companyType}`,
    );
  }
};
const openPdListForSource = async (index: number) => {
  if (sourceCount.value) {
    await openPdList("source", `${sourceCount.value[index].source}`);
  }
};
</script>

<template>
  <ProjectDeveloperReportingCharts
    :stageChartData="stageChartData"
    :sourceChartData="sourceChartData"
    :probabilityChartData="probabilityChartData"
    :companyTypeChartData="companyTypeChartData"
    :openPdListForStage="openPdListForStage"
    :openPdListForProbability="openPdListForProbability"
    :openPdListForCompanyType="openPdListForCompanyType"
    :openPdListForSource="openPdListForSource"
  />
</template>
