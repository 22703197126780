<script setup lang="ts">
import { formatArea, formatCapacity } from "@/app/common/area/area";
import { type WindStats } from "@/app/common/area/WindStats";

defineProps<{
  area: WindStats;
}>();
</script>
<template>
  <div>{{ area.name }}</div>
  <div v-if="area.area || area.capacity">
    {{ formatArea(area.area) }}
    |
    {{ formatCapacity(area.capacity) }}
  </div>
</template>
