import { type Chart as ChartJS, type ChartEvent } from "chart.js";

export interface BarChartPlugin {
  id: string;
  afterEvent: (
    chart: ChartJS<"bar">,
    event: {
      event: ChartEvent;
      inChartArea: boolean;
    },
  ) => void;
}
