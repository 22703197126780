<script setup lang="ts">
import { ref, watch } from "vue";

import AppButton from "@/app/base/button/AppButton.vue";
import { useDefaultModel } from "@/app/base/utils/useModel";

import { defaultSidebarWidth } from "./sidebarWidth";

const props = withDefaults(
  defineProps<{
    modelValue?: boolean;
    location?: "left" | "bottom" | "right";
    permanent?: boolean;
    expandOnHover?: boolean;
    rail?: boolean;
    hideOverlay?: boolean;
    width?: number;
    fixed?: boolean;
    header?: string;
    closeButton?: boolean;
    noContainer?: boolean;

    /** When true, the contents are destroyed when hidden */
    transient?: boolean;
  }>(),
  {
    modelValue: undefined,
    location: "right",
    width: undefined,
    permanent: true,
    closeButton: false,
    header: undefined,
    noContainer: false,
  },
);

const emit = defineEmits<(e: "update:modelValue", value: boolean) => void>();

const model = useDefaultModel(props, "modelValue", true)(emit);

const transientShown = ref(model.value);

watch(model, (shown) => {
  if (shown) {
    transientShown.value = true;
  }
});

function onTransitionEnd() {
  if (!model.value) {
    transientShown.value = false;
  }
}
</script>

<template>
  <VNavigationDrawer
    v-model="model"
    class="detail-pane gradient1"
    :location="location"
    :width="width || defaultSidebarWidth"
    :hideOverlay="hideOverlay"
    :permanent="permanent"
    :rail="rail"
    :expandOnHover="expandOnHover"
    :fixed="fixed"
    @transitionend.self="onTransitionEnd"
  >
    <VLayout v-if="!transient || transientShown" class="text-caeli6">
      <div
        v-if="header || closeButton"
        class="detail-pane__header gradient1 separator-bottom"
      >
        <h5 v-if="header" class="text-pre-title">{{ header }}</h5>
        <VSpacer />
        <AppButton
          highlight
          class="cancel-button"
          icon="mdi-close-box-outline"
          @click="model = false"
        />
      </div>
      <slot v-if="noContainer" />
      <div v-else class="sidebar-container" :class="{ 'pt-4': header }">
        <slot />
      </div>
    </VLayout>
  </VNavigationDrawer>
</template>

<style>
.detail-pane > .v-navigation-drawer__content {
  display: flex;
  flex-direction: column;
  overflow: visible;
}
</style>

<style scoped>
.v-layout {
  /*
   * 1px used as negative margin and padding
   * to ensure correct sizing of nested navigation panels
   */
  margin-left: -1px;
  padding-left: 1px;
  flex-direction: column;
  overflow: visible;
  min-height: 100%;
}

.detail-pane .sidebar-container {
  padding: 0 1em 1em 1em;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
  overflow-x: hidden;
}

.detail-pane__header {
  display: flex;
  align-items: center;
  padding: 0.5em 1em;
}

:deep(hr) {
  border: none;
  border-bottom: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
}

.cancel-button {
  transform: translateX(1.5em);
}
</style>
