/* istanbul ignore file */
/* eslint-disable */
import type { Note } from '../models/Note';
import type { NoteStructure } from '../models/NoteStructure';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NoteControllerService {

  /**
   * @returns Note OK
   * @throws ApiError
   */
  public static getNote({
noteId,
}: {
noteId: number,
}): CancelablePromise<Note> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/pisys/v1/attachments/note/{noteId}',
      path: {
        'noteId': noteId,
      },
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static updateNote({
noteId,
requestBody,
}: {
noteId: number,
requestBody: Note,
}): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/pisys/v1/attachments/note/{noteId}',
      path: {
        'noteId': noteId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static deleteNote({
noteId,
}: {
noteId: number,
}): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/pisys/v1/attachments/note/{noteId}',
      path: {
        'noteId': noteId,
      },
    });
  }

  /**
   * @returns NoteStructure OK
   * @throws ApiError
   */
  public static getNotes({
projectId,
customerId,
contactId,
opportunityId,
}: {
projectId?: number,
customerId?: number,
contactId?: number,
opportunityId?: number,
}): CancelablePromise<NoteStructure> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/pisys/v1/attachments/note',
      query: {
        'projectId': projectId,
        'customerId': customerId,
        'contactId': contactId,
        'opportunityId': opportunityId,
      },
    });
  }

  /**
   * @returns Note OK
   * @throws ApiError
   */
  public static createNote({
requestBody,
}: {
requestBody: Note,
}): CancelablePromise<Note> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/pisys/v1/attachments/note',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns Note OK
   * @throws ApiError
   */
  public static getUnlinkedNotes(): CancelablePromise<Array<Note>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/pisys/v1/attachments/note/unlinked',
    });
  }

}
