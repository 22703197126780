<script setup lang="ts">
import { loadDevMessages } from "@apollo/client/dev";
import { BrowserAuthError, BrowserAuthErrorCodes } from "@azure/msal-browser";
import { onErrorCaptured, ref } from "vue";
import { RouterView, useRouter } from "vue-router";

import DialogScope from "@/app/base/dialog/DialogScope.vue";
import { useI18n } from "@/app/base/utils/i18n";
import AppHeaderContainer from "@/app/common/header/AppHeaderContainer.vue";
import AppDrawer from "@/app/common/navigation/AppDrawer.vue";
import { setRouter } from "@/app/common/store/useStoreCache";
import PromptList from "@/app/notification/PromptList.vue";
import { ApiError } from "@/services/backend/core/ApiError";

import ErrorDialog from "./app/notification/ErrorDialog.vue";

const router = useRouter();
const error = ref<Error>();
setRouter(router);

onErrorCaptured((capturedError, _instance, info) => {
  console.error("Error occurred from", info, "\n", capturedError);

  //if error is an ApiError, go to ErrorPage
  if (capturedError instanceof ApiError) {
    const href = router.resolve({
      path: "/error",
      query: { error: capturedError.status, message: capturedError.message },
    });
    void router.push(href);
  } else if (
    capturedError instanceof BrowserAuthError &&
    capturedError.errorCode === BrowserAuthErrorCodes.interactionInProgress
  ) {
    // TODO: For now we ignore this error in the dialog but we should reimplement the login flow
    router.go(0);
    return;
  } else {
    //else show error dialog
    error.value = capturedError;
  }
});

const { locale } = useI18n();
if (!import.meta.env.PROD) {
  loadDevMessages();
}
</script>

<template>
  <VApp :lang="locale">
    <!--  PrimeVue Dialog Handlers  -->
    <DynamicDialog />
    <ConfirmDialog
      :style="{ width: '50vw' }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    />
    <!-------------------------------->

    <DialogScope>
      <AppDrawer />
      <AppHeaderContainer />

      <VMain class="text-caeli6">
        <RouterView />
      </VMain>
    </DialogScope>
    <PromptList />
    <ErrorDialog v-if="error" :error="error" @dismiss="error = undefined" />
  </VApp>
</template>

<style>
.w-form {
  max-width: 1200px;
}

.border-card {
  border: 1px solid rgb(var(--v-theme-caeli8));
  border-bottom: none;
}

.pointer {
  cursor: pointer;
}

.ml-n20 {
  margin-left: -5rem;
}

.r-8 {
  right: 2rem;
}

.t-24 {
  top: 6rem;
}

.z-1 {
  z-index: 1;
}
.z-10k {
  z-index: 10000;
}

#app .v-text-field.bg-white input {
  background-color: white;
}

#app .tight .v-input {
  --v-input-control-height: 36px;
}
#app .tight .v-field__input {
  padding-top: 6px;
  padding-bottom: 6px;
}

.field-border {
  border: 1px solid #bcd;
}
.field-border:hover {
  border: 1px solid #789;
}
</style>

<style scoped>
/*
 * These flex rules are needed to allow full-height scrollable regions in the
 * app without causing overflow. Without an explicit min-height, the elements
 * would overflow as they would not shrink to less than their content’s height.
 */
.v-main {
  display: flex;
  flex-shrink: 1;
  flex-direction: column;
  min-height: 0;
}

.v-main > :deep(.v-main__wrap) {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  overflow-y: auto;
}
</style>
