<script lang="ts" setup>
import { v4 as uuidv4 } from "uuid";
import { computed, ref } from "vue";
import { VTextField } from "vuetify/components";

import { useI18n } from "@/app/base/utils/i18n";
import EditFieldsDialog from "@/app/process/field/action/EditFieldsDialog.vue";
import RenameTagsDialog from "@/app/process/field/action/RenameTagsDialog.vue";
import { useFieldService } from "@/app/process/service/FieldService";
import {
  PROCESS_DIALOG,
  useProcessUIStore,
} from "@/app/process/service/ProcessUIStore";
import { type EntityType, FieldType } from "@/gql/types";

const { t } = useI18n();

const props = defineProps<{
  entityId: string;
  showEditItemFields?: boolean;
  entityType: EntityType;
}>();
const emits = defineEmits<(e: "delete", id: string) => void>();

const fieldService = useFieldService();
const processUiStore = useProcessUIStore();

const fieldValues = computed(() => {
  return fieldService.getTagFieldValues(props.entityId);
});

const availableTags = computed(() => {
  return fieldService.getTagFieldKeys(props.entityType);
});

const usedTags = computed(() => {
  return availableTags.value.filter((tag) =>
    fieldValues.value.some((fieldValue) => fieldValue.fieldKeyId === tag.id),
  );
});
const usedTagIndices = computed(() => {
  const results: number[] = [];
  usedTags.value.forEach((usedTag) => {
    results.push(
      availableTags.value.findIndex(
        (availableTag) => availableTag.id === usedTag.id,
      ),
    );
  });
  return results;
});

const tagInput = ref<HTMLElement | null>(null);

const creationActive = ref(false);
const tagName = ref("");
const canCreate = computed(() => {
  return (
    tagName.value.length > 0 &&
    !availableTags.value.some((it) => it.name === tagName.value)
  );
});

function createTagField() {
  if (!canCreate.value) {
    return;
  }
  fieldService.createOrUpdateFieldKey(
    {
      id: uuidv4(),
      name: tagName.value,
      type: FieldType.Tag,
      entityTypes: [props.entityType],
    },
    {
      noSuccessPrompt: true,
      onSuccess: (fieldKeyId) =>
        fieldService.createOrUpdateFieldValue({
          id: uuidv4(),
          fieldKeyId,
          entityId: props.entityId,
        }),
    },
  );
  tagName.value = "";
}

function updateTagSelection(changedIndices: number[]) {
  const removedIndex = usedTagIndices.value.find(
    (index) => !changedIndices.includes(index),
  );
  if (removedIndex !== undefined) {
    const removedFieldValue = fieldValues.value.find(
      (fieldValue) =>
        fieldValue.fieldKeyId === availableTags.value[removedIndex].id,
    );
    if (removedFieldValue) {
      fieldService.deleteFieldValue(removedFieldValue.id);
    }
    return;
  }
  const addedIndex = changedIndices.find(
    (index) => !usedTagIndices.value.includes(index),
  );
  if (addedIndex !== undefined) {
    fieldService.createOrUpdateFieldValue({
      id: uuidv4(),
      fieldKeyId: availableTags.value[addedIndex].id,
      entityId: props.entityId,
    });
  }
}
</script>

<template>
  <RenameTagsDialog
    :entityType="props.entityType"
    @delete="(id: string) => emits('delete', id)"
  />
  <EditFieldsDialog
    :entityId="props.entityId"
    :entityType="props.entityType"
    @delete="(id: string) => emits('delete', id)"
  />
  <div
    class="bg-white pl-4 pr-1 py-1 field-border"
    data-testid="tags-edit-card"
  >
    <div class="d-flex justify-space-between align-start">
      <div>
        <VLabel class="text-caeli6">{{ t("processes.tag", 2) }}</VLabel>
        <div class="d-flex flex-wrap align-center justify-start">
          <VChipGroup
            :column="true"
            :multiple="true"
            :modelValue="usedTagIndices"
            @update:modelValue="updateTagSelection"
          >
            <VChip
              v-for="fieldKey in availableTags"
              :key="fieldKey.id"
              class="chip-select"
              :color="`#${fieldKey.colorHex ?? '000000'}`"
              variant="tonal"
              data-testid="available-tag-name"
              :filter="true"
            >
              {{ fieldKey.name }}
            </VChip>
          </VChipGroup>
          <VChip
            variant="text"
            :class="creationActive ? 'border' : 'px-0'"
            style="z-index: 1; border-color: #aaa !important"
            data-testid="add-tag-button"
          >
            <VTextField
              v-show="creationActive"
              ref="tagInput"
              v-model="tagName"
              density="compact"
              hideDetails="auto"
              class="ml-n3 bg-white"
              style="min-width: 8rem"
              :style="`width: ${tagName.length * 0.5 + 4}rem`"
              data-testid="add-tag-name"
              @keyup.enter="createTagField"
            />
            <VBtn
              v-show="creationActive"
              variant="plain"
              color="darkgrey"
              class="ml-n10"
              icon="mdi-close"
              size="small"
              @click="
                () => {
                  creationActive = false;
                  tagName = '';
                }
              "
            />
            <VBtn
              :variant="creationActive ? 'flat' : 'tonal'"
              color="caeli5"
              :icon="creationActive ? 'mdi-content-save' : 'mdi-plus'"
              size="small"
              :class="creationActive ? 'mr-n3' : 'mr-n1'"
              style="z-index: 0"
              :disabled="creationActive && !canCreate"
              data-testid="add-tag-save-or-plus"
              @click="
                () => {
                  if (creationActive) {
                    createTagField();
                    creationActive = false;
                    tagName = '';
                  } else {
                    creationActive = true;
                    $nextTick(() => {
                      if (tagInput !== undefined && tagInput !== null) {
                        tagInput.focus();
                      }
                    });
                  }
                }
              "
            />
          </VChip>
        </div>
      </div>
      <div class="d-flex flex-column">
        <VBtn
          variant="plain"
          icon="mdi-pencil"
          color="caeli6"
          size="tiny"
          class="pa-2 text-caption"
          data-testid="tags-edit-card-rename"
          @click="() => processUiStore.openDialog(PROCESS_DIALOG.RENAME_TAG)"
        />
        <VBtn
          v-if="props.showEditItemFields"
          variant="plain"
          icon="mdi-form-textbox"
          color="caeli6"
          size="tiny"
          class="pa-2 text-caption"
          @click="
            () => processUiStore.openDialog(PROCESS_DIALOG.EDIT_GENERIC_FIELDS)
          "
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.chip-select {
  color: rgb(var(--v-theme-caeli8));
}
.chip-select:hover {
  color: rgb(var(--v-theme-caeli6));
}
.v-chip-group {
  padding-top: 0;
  padding-bottom: 0;
}
#app .v-label {
  font-size: 0.7em;
}
</style>
