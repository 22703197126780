import { readonly, ref } from "vue";

/**
 * A reactive boolean representing whether all fonts have already been loaded.
 */
export const useFontsReady = () => {
  const fontsReady = ref(false);

  void document.fonts.ready.then(() => {
    fontsReady.value = true;
  });

  return readonly(fontsReady);
};
