<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed } from "vue";

import AppButton from "@/app/base/button/AppButton.vue";
import ButtonGroup from "@/app/base/button/ButtonGroup.vue";
import { formStyles } from "@/app/base/form/formStyles";
import { useValidators } from "@/app/base/form/validators";
import { useI18n } from "@/app/base/utils/i18n";
import { AssignmentType } from "@/app/common/attachments/AssignmentType";
import { useNoteStore } from "@/app/common/attachments/store/NoteStore";
import { type ContactView } from "@/services/backend/models/ContactView";
import { NoteContactType } from "@/services/backend/models/NoteContactType";
import { type OpportunityView } from "@/services/backend/models/OpportunityView";
import { type ProjectView } from "@/services/backend/models/ProjectView";

const { t, tEnum } = useI18n();

const { required } = useValidators();

const props = defineProps<{
  noteId: number;
  showAssignmentTypes: AssignmentType[];
}>();

const noteStore = useNoteStore();
const {
  potentialCustomers,
  potentialContacts,
  potentialOpportunities,
  potentialProjects,
  assignedContactLookup,
} = storeToRefs(noteStore);

const customerModel = noteStore.assignmentModelProvider(
  props.noteId,
  potentialCustomers,
  "customerId",
  ["contactId"],
);

const contactWrapper = computed(() => {
  return potentialContacts.value.map((contact: ContactView) => {
    return {
      id: contact.id,
      name: `${
        contact.title ? tEnum("contactEntity.title", contact.title) + " " : ""
      }${contact.firstName} ${contact.lastName}`,
    };
  });
});

const selectedContact = computed(() => {
  const assignedContact = assignedContactLookup.value.get(props.noteId);
  return assignedContact
    ? {
        id: assignedContact.id,
        name: `${
          assignedContact.title
            ? tEnum("contactEntity.title", assignedContact.title) + " "
            : ""
        }${assignedContact.firstName} ${assignedContact.lastName}`,
      }
    : undefined;
});

function updateContact(contact: { id: number; name: string } | null) {
  noteStore.createOrUpdatePending({
    id: props.noteId,
    contactId: contact ? contact.id : undefined,
  });
}

function updateOpportunity(opportunity: OpportunityView | null) {
  noteStore.createOrUpdatePending({
    id: props.noteId,
    opportunityId: opportunity ? opportunity.id : undefined,
  });
}

function updateProject(project: ProjectView | null) {
  noteStore.createOrUpdatePending({
    id: props.noteId,
    projectId: project ? project.id : undefined,
  });
}

const editedNote = computed(() => {
  return noteStore.editedItems.get(props.noteId);
});

const contactTypeModel = computed({
  get: () => {
    const type = editedNote.value?.contactType;
    if (!type) {
      return undefined;
    }
    return { id: type, name: tEnum("attachment.contactTypes", type) };
  },
  set: (value) => {
    noteStore.createOrUpdatePending({
      id: props.noteId,
      contactType: value?.id,
    });
  },
});

const contactTypeItems = Object.values(NoteContactType).map((type) => {
  return { id: type, name: tEnum("attachment.contactTypes", type) };
});
</script>

<template>
  <div>
    <VCard class="gradient1 border1" elevation="0">
      <VForm
        v-if="editedNote"
        @submit.prevent="noteStore.persistChanges(props.noteId)"
      >
        <VCardText>
          <div class="d-flex flex-column" style="gap: 8px">
            <div
              v-if="props.showAssignmentTypes.length"
              class="d-flex flex-wrap"
              style="gap: 8px"
            >
              <div
                v-if="
                  props.showAssignmentTypes.includes(AssignmentType.OPPORTUNITY)
                "
                :style="{
                  ...{ flex: '10 0 200px' },
                }"
              >
                <VSelect
                  :modelValue="noteStore.assignedOpportunity(props.noteId)"
                  :items="potentialOpportunities"
                  :label="t('entities.opportunityAssignment')"
                  :clearable="true"
                  v-bind="formStyles"
                  itemValue="id"
                  itemTitle="name"
                  :disabled="potentialOpportunities.length === 0"
                  returnObject
                  @update:modelValue="updateOpportunity"
                >
                </VSelect>
                <VTooltip
                  v-if="potentialOpportunities.length === 0"
                  activator="parent"
                  location="bottom"
                >
                  {{ t("entities.noOpportunityLinked") }}
                </VTooltip>
              </div>
              <div
                v-if="
                  props.showAssignmentTypes.includes(AssignmentType.PROJECT)
                "
                :style="{
                  ...{ flex: '10 0 200px' },
                }"
              >
                <VSelect
                  :modelValue="noteStore.assignedProject(props.noteId)"
                  :items="potentialProjects"
                  :label="t('entities.projectAssignment')"
                  :clearable="true"
                  v-bind="formStyles"
                  itemValue="id"
                  itemTitle="name"
                  :disabled="potentialProjects.length === 0"
                  returnObject
                  @update:modelValue="updateProject"
                >
                </VSelect>
                <VTooltip
                  v-if="potentialProjects.length === 0"
                  activator="parent"
                  location="bottom"
                >
                  {{ t("entities.noProjectLinked") }}
                </VTooltip>
              </div>
              <div
                v-if="
                  props.showAssignmentTypes.includes(AssignmentType.CUSTOMER)
                "
                :style="{
                  ...{ flex: '10 0 200px' },
                }"
              >
                <VSelect
                  v-model="customerModel"
                  v-bind="formStyles"
                  :items="potentialCustomers"
                  :label="t('customers.customerAssignment')"
                  :clearable="true"
                  itemValue="id"
                  itemTitle="name"
                  :disabled="potentialCustomers.length === 0"
                  returnObject
                >
                </VSelect>
                <VTooltip
                  v-if="potentialCustomers.length === 0"
                  activator="parent"
                  location="bottom"
                >
                  {{ t("customers.noLinked") }}
                </VTooltip>
              </div>
              <div
                v-if="
                  props.showAssignmentTypes.includes(AssignmentType.CONTACT)
                "
                :style="{
                  ...{ flex: '10 0 200px' },
                }"
              >
                <VSelect
                  :modelValue="selectedContact"
                  v-bind="formStyles"
                  :items="contactWrapper"
                  :label="t('contacts.contactAssignment')"
                  :clearable="true"
                  itemValue="id"
                  itemTitle="name"
                  :disabled="
                    potentialContacts.length === 0 ||
                    (!editedNote.customerId &&
                      props.showAssignmentTypes.includes(
                        AssignmentType.CUSTOMER,
                      ))
                  "
                  returnObject
                  @update:modelValue="updateContact"
                >
                </VSelect>
                <VTooltip
                  v-if="potentialContacts.length === 0"
                  activator="parent"
                  location="bottom"
                >
                  {{ t("contacts.noLinked") }}
                </VTooltip>
              </div>
              <div
                :style="{
                  flex: '1 0 180px',
                }"
              >
                <VSelect
                  v-model="contactTypeModel"
                  v-bind="formStyles"
                  :items="contactTypeItems"
                  :label="t('attachment.contactType')"
                  :clearable="true"
                  itemValue="id"
                  itemTitle="name"
                  returnObject
                >
                </VSelect>
              </div>
            </div>
            <div>
              <VTextarea
                v-model="editedNote.content"
                v-bind="formStyles"
                :rules="[required]"
                :label="t('attachment.comment')"
                rows="5"
              ></VTextarea>
            </div>
          </div>
        </VCardText>
        <VCardActions>
          <ButtonGroup>
            <AppButton
              :label="t('ui.cancel')"
              border
              corners
              heavy
              @click="noteStore.discardChanges(props.noteId)"
            />
            <AppButton
              :label="t('ui.save')"
              :disabled="
                editedNote.content === undefined ||
                editedNote.content?.length === 0
              "
              highlight
              solid
              corners
              heavy
              @click="noteStore.persistChanges(props.noteId)"
            />
          </ButtonGroup>
        </VCardActions>
      </VForm>
    </VCard>
  </div>
</template>

<style scoped>
.button-group {
  gap: 1em;
}
</style>
