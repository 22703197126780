import { type EnumTranslationPath, useI18n } from "@/app/base/utils/i18n";

export function makeEnumComparatorByIndex<Enum>(
  enumClass: Record<string, Enum>,
) {
  const enumValueIndices = Object.fromEntries(
    Object.values(enumClass).map((enumValue, i) => [enumValue, i]),
  );

  return (a: Enum | undefined, b: Enum | undefined) =>
    enumValueIndices[a] - enumValueIndices[b];
}

export function makeEnumComparatorByTranslation<Enum extends string>(
  path: EnumTranslationPath<Enum>,
) {
  const { tEnum } = useI18n();
  return (a: Enum | undefined, b: Enum | undefined) =>
    // eslint-disable-next-line no-restricted-syntax,@intlify/vue-i18n/no-dynamic-keys -- this is safe because of the EnumTranslationPath type
    (a ? tEnum(path, a) : "").localeCompare(b ? tEnum(path, b) : "");
}
