import { type CustomerView } from "@/services/backend/models/CustomerView";
import { EventType } from "@/services/backend/models/EventType";
import { QualificationEvent } from "@/services/backend/models/QualificationEvent";
import { EventControllerService } from "@/services/backend/services/EventControllerService";

export enum ContractReportingStatus {
  ARCHIVED = "ARCHIVED",
  DONE = "DONE",
  EMPTY = "EMPTY",
  RELEASED = "RELEASED",
  REQUESTED = "REQUESTED",
  SENT = "SENT",
  SET_UP = "SET_UP",
}

export interface CustomerContractView {
  id: number;
  name: string;
  customerNumber: string;
  contractStatus: ContractReportingStatus;
  selfDisclosure: ContractReportingStatus;
  profile: ContractReportingStatus;
  userAccount: ContractReportingStatus;
  hrData: ContractReportingStatus;
  creditRatingCheck: ContractReportingStatus;
}

export async function computeCustomerContractView(
  projectDevelopers: CustomerView[],
): Promise<CustomerContractView[]> {
  return await Promise.all(
    projectDevelopers.map(async (pd) => {
      const events = await getQualificationEvents(pd.id);
      return {
        id: pd.id,
        name: pd.name,
        customerNumber: pd.customerNumber,
        contractStatus: getContractStatusLabel(events),
        selfDisclosure: getSelfDisclosureLabel(events),
        profile: getProfileLabel(events),
        userAccount: getUserAccountLabel(events),
        hrData: getHRDataLabel(events),
        creditRatingCheck: getCreditRatingCheckLabel(events),
      } as CustomerContractView;
    }),
  );
}

const getQualificationEvents = async (customerId: number) => {
  return await EventControllerService.getEvents({ customerId }).then(
    (response) => {
      return response
        .filter((event) => event.type === EventType.QUALIFICATION_EVENT)
        .map((event) => event.qualificationEvent);
    },
  );
};

const getCreditRatingCheckLabel = (
  events: (QualificationEvent | undefined)[],
) => {
  if (events.indexOf(QualificationEvent.CREDIT_CHECK_PASSED) > -1) {
    return ContractReportingStatus.DONE;
  }
  return ContractReportingStatus.EMPTY;
};

const getHRDataLabel = (events: (QualificationEvent | undefined)[]) => {
  if (events.indexOf(QualificationEvent.TR_DATA_AVAILABLE) > -1) {
    return ContractReportingStatus.ARCHIVED;
  } else if (events.indexOf(QualificationEvent.TR_DATA_REQUESTED) > -1) {
    return ContractReportingStatus.REQUESTED;
  } else {
    return ContractReportingStatus.EMPTY;
  }
};

const getUserAccountLabel = (events: (QualificationEvent | undefined)[]) => {
  if (events.indexOf(QualificationEvent.USER_ACCOUNT_SET_UP) > -1) {
    return ContractReportingStatus.SET_UP;
  } else {
    return ContractReportingStatus.EMPTY;
  }
};

const getProfileLabel = (events: (QualificationEvent | undefined)[]) => {
  if (events.indexOf(QualificationEvent.PROFILE_SET_UP) > -1) {
    return ContractReportingStatus.SET_UP;
  } else if (
    events.indexOf(QualificationEvent.PROFILE_PUBLICATION_CONFIRMED) > -1
  ) {
    return ContractReportingStatus.RELEASED;
  } else {
    return ContractReportingStatus.EMPTY;
  }
};

const getSelfDisclosureLabel = (events: (QualificationEvent | undefined)[]) => {
  if (events.indexOf(QualificationEvent.SELF_DISCLOSURE_RECEIVED) > -1) {
    return ContractReportingStatus.ARCHIVED;
  } else if (events.indexOf(QualificationEvent.SELF_DISCLOSURE_SENT) > -1) {
    return ContractReportingStatus.SENT;
  } else {
    return ContractReportingStatus.EMPTY;
  }
};

const getContractStatusLabel = (events: (QualificationEvent | undefined)[]) => {
  if (events.indexOf(QualificationEvent.BROKERAGE_CONTRACT_SIGNED) > -1) {
    return ContractReportingStatus.ARCHIVED;
  } else if (events.indexOf(QualificationEvent.BROKERAGE_CONTRACT_SENT) > -1) {
    return ContractReportingStatus.SENT;
  } else {
    return ContractReportingStatus.EMPTY;
  }
};
