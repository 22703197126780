<script setup lang="ts">
import { ref, toRef, watch } from "vue";

import AppButton from "@/app/base/button/AppButton.vue";
import CheckboxGroup from "@/app/base/form/CheckboxGroup.vue";
import CheckboxGroupRow from "@/app/base/form/CheckboxGroupRow.vue";
import { formStyles } from "@/app/base/form/formStyles";
import DetailPane from "@/app/base/layout/DetailPane.vue";
import LoadingIndicator from "@/app/base/loading/LoadingIndicator.vue";
import { searchFilter, useFilter } from "@/app/base/search/filter";
import { useI18n } from "@/app/base/utils/i18n";
import { useSet, useSingleSet } from "@/app/base/utils/SetView";
import { useDefaultModel } from "@/app/base/utils/useModel";
import { type LinkedObject } from "@/app/common/linker/LinkedObject";

const props = withDefaults(
  defineProps<{
    open?: boolean;
    single?: boolean;
    location?: "left" | "bottom" | "right";
    loadingNames: string;
    onLink: (value: number[]) => Promise<void> | void;
    createButtonSubtypes: { value: string; title: string }[];
    items: readonly LinkedObject[] | undefined;
    heading: string;
  }>(),
  {
    open: undefined,
    single: false,
    location: "right",
    onCreate: undefined,
  },
);

const emit = defineEmits<(e: "update:open", value: boolean) => void>();

const openModel = useDefaultModel(props, "open", true)(emit);

const { t } = useI18n();

const [selected, getSelectedModel, selectionEmpty, clearSelection] =
  props.single ? useSingleSet<number>() : useSet<number>();

const search = ref("");

const filteredItems = useFilter(
  toRef(props, "items"),
  searchFilter(search, (item) => [item.name]),
);

// Reset the view when the panel is reopened
watch(openModel, (isOpen) => {
  if (isOpen) {
    search.value = "";
    clearSelection();
  }
});

async function onAdd(newValue: Promise<number>) {
  getSelectedModel(await newValue).value = true;
}

const showAdd = ref(false);

const selectedCreateSubtype = ref<string>();

function selectSubtype(subtype: string) {
  selectedCreateSubtype.value = subtype;
  showAdd.value = true;
}
</script>

<template>
  <DetailPane
    v-model="openModel"
    :location="location"
    transient
    fixed
    :header="heading"
    closeButton
  >
    <VTextField
      v-bind="formStyles"
      v-model="search"
      class="flex-grow-0"
      :label="t('table.search')"
    />
    <CheckboxGroup v-if="items" :minRows="5">
      <VMenu v-if="createButtonSubtypes.length > 0" openOnHover>
        <VList>
          <VListItem
            v-for="(item, index) in createButtonSubtypes"
            :key="index"
            @click="selectSubtype(item.value)"
          >
            <VListItemTitle>{{ item.title }}</VListItemTitle>
          </VListItem>
        </VList>
      </VMenu>
      <CheckboxGroupRow
        v-for="item of filteredItems"
        :key="item.id"
        v-model="getSelectedModel(item.id).value"
        :single="single"
      >
        {{ item.name }}
      </CheckboxGroupRow>
    </CheckboxGroup>
    <LoadingIndicator v-else :items="loadingNames" />
    <AppButton
      class="align-self-end"
      :label="t('ui.link')"
      :disabled="selectionEmpty"
      highlight
      solid
      corners
      heavy
      @click="onLink(selected)"
      @success="openModel = false"
    />
    <slot
      :selectedCreateSubtype="selectedCreateSubtype"
      :showAdd="showAdd"
      :onAdd="onAdd"
      :onCancelAdd="() => void (showAdd = false)"
    />
  </DetailPane>
</template>

<style scoped>
.v-list-item {
  cursor: pointer;
}
.checkbox-group {
  flex: 0 max-content;
  min-height: 1em;
  --v-input-control-height: 1em;
}
</style>
