/* istanbul ignore file */
/* eslint-disable */
import type { Customer } from '../models/Customer';
import type { CustomerType } from '../models/CustomerType';
import type { CustomerView } from '../models/CustomerView';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomerControllerService {

  /**
   * @returns Customer OK
   * @throws ApiError
   */
  public static getCustomer({
customerId,
}: {
customerId: number,
}): CancelablePromise<Customer> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/pisys/v1/customers/{customerId}',
      path: {
        'customerId': customerId,
      },
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static updateCustomer({
customerId,
requestBody,
}: {
customerId: number,
requestBody: Customer,
}): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/pisys/v1/customers/{customerId}',
      path: {
        'customerId': customerId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static deleteCustomer({
customerId,
}: {
customerId: number,
}): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/pisys/v1/customers/{customerId}',
      path: {
        'customerId': customerId,
      },
    });
  }

  /**
   * Get customer list. Either without a parameter or with ONE of projectId, contactId or opportunityId.
   * @returns CustomerView Found customers
   * @throws ApiError
   */
  public static getCustomers({
contactId,
projectId,
opportunityId,
customerType,
}: {
contactId?: number,
projectId?: number,
opportunityId?: number,
customerType?: CustomerType,
}): CancelablePromise<Array<CustomerView>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/pisys/v1/customers',
      query: {
        'contactId': contactId,
        'projectId': projectId,
        'opportunityId': opportunityId,
        'customerType': customerType,
      },
      errors: {
        400: `Bad request`,
      },
    });
  }

  /**
   * @returns Customer OK
   * @throws ApiError
   */
  public static createCustomer({
requestBody,
}: {
requestBody: Customer,
}): CancelablePromise<Customer> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/pisys/v1/customers',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static linkCustomerWithProjects({
customerId,
projectIds,
}: {
customerId: number,
projectIds: Array<number>,
}): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/pisys/v1/customers/{customerId}/project-links',
      path: {
        'customerId': customerId,
      },
      query: {
        'projectIds': projectIds,
      },
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static linkCustomerWithOpportunities({
customerId,
opportunityIds,
}: {
customerId: number,
opportunityIds: Array<number>,
}): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/pisys/v1/customers/{customerId}/opportunity-links',
      path: {
        'customerId': customerId,
      },
      query: {
        'opportunityIds': opportunityIds,
      },
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static linkCustomerWithContacts({
customerId,
contactIds,
}: {
customerId: number,
contactIds: Array<number>,
}): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/pisys/v1/customers/{customerId}/contact-links',
      path: {
        'customerId': customerId,
      },
      query: {
        'contactIds': contactIds,
      },
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static unlinkCustomerFromProject({
customerId,
projectId,
}: {
customerId: number,
projectId: number,
}): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/pisys/v1/customers/{customerId}/project-links/{projectId}',
      path: {
        'customerId': customerId,
        'projectId': projectId,
      },
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static unlinkCustomerFromOpportunity({
customerId,
opportunityId,
}: {
customerId: number,
opportunityId: number,
}): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/pisys/v1/customers/{customerId}/opportunity-links/{opportunityId}',
      path: {
        'customerId': customerId,
        'opportunityId': opportunityId,
      },
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static unlinkCustomerFromContact({
customerId,
contactId,
}: {
customerId: number,
contactId: number,
}): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/pisys/v1/customers/{customerId}/contact-links/{contactId}',
      path: {
        'customerId': customerId,
        'contactId': contactId,
      },
    });
  }

}
