/* istanbul ignore file */
/* eslint-disable */

export enum SalesStage {
  LAND_OWNER_STATED = 'LAND_OWNER_STATED',
  INITIAL_MEETING = 'INITIAL_MEETING',
  SHAPE_FILES = 'SHAPE_FILES',
  LOCATION_ANALYSIS = 'LOCATION_ANALYSIS',
  VSV_IN_NEGOTIATION = 'VSV_IN_NEGOTIATION',
  VSV_DECLINED = 'VSV_DECLINED',
  VSV_COMPLETED = 'VSV_COMPLETED',
  ABORT = 'ABORT',
}
