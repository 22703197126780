<script setup lang="ts">
import { computed, onMounted } from "vue";
import { useRouter } from "vue-router";

import DetailPane from "@/app/base/layout/DetailPane.vue";
import { useI18n } from "@/app/base/utils/i18n";
import LandOwnerReportingContainer from "@/app/reporting/landOwner/LandOwnerReportingContainer.vue";
import LandOwnerContractReportingContainer from "@/app/reporting/landOwnerContract/LandOwnerContractReportingContainer.vue";
import ProjectDeveloperReportingContainer from "@/app/reporting/projectDeveloper/ProjectDeveloperReportingContainer.vue";
import ProjectDeveloperContractReportingContainer from "@/app/reporting/projectDeveloperContract/ProjectDeveloperContractReportingContainer.vue";

import FunnelCarousel from "./common/FunnelCarousel.vue";
import FunnelCarouselItem from "./common/FunnelCarouselItem.vue";
import OpportunityReportingContainer from "./opportunity/OpportunityReportingContainer.vue";
import WindfarmReportingContainer from "./windfarms/WindfarmReportingContainer.vue";

const { t } = useI18n();

// These string values appear in the url after the hash sign
enum Page {
  OPPORTUNITIES = "opportunities",
  WINDPARKS = "windparks",
  LAND_OWNERS = "land-owners",
  LAND_OWNERS_CONTRACTS = "land-owners-contracts",
  PROJECT_DEVELOPER = "project-developers",
  PROJECT_DEVELOPER_CONTRACTS = "project-developers-contracts",
}

const stringToPage = (page: string) =>
  Object.values<string>(Page).includes(page) ? (page as Page) : undefined;

const router = useRouter();

// The active page is bound to the url hash
// Use router.push rather than router.replace to enable the back button
// This makes clicking the side arrow repeatedly fill the browser history
const activePage = computed<Page | undefined>({
  get: () => stringToPage(router.currentRoute.value.hash.replace(/^#/, "")),
  set: (newRoute: Page | undefined) =>
    newRoute && void router.replace({ hash: "#" + newRoute }),
});

const SIDEBAR_WIDTH = 270;
const ARROW_WIDTH = 60;

const LOST_WIDTH = SIDEBAR_WIDTH + 2 * ARROW_WIDTH;

/**
 * The text size as a ratio of the diagram size
 *
 * This comes from a mockup at 1728px,
 * so diagram of 1728 - 250 - 2 × 60 = 1358px,
 * with text at 16px;
 */
const TEXT_SIZE_RATIO = 16 / 1358;

//focussing the container to make key-listeners work
onMounted(() => {
  document.getElementById("carousel-wrapper")?.focus();
});

const changePage = (step: number) => {
  const pages = Object.values(Page);
  const currentIndex = pages.indexOf(activePage.value ?? Page.OPPORTUNITIES);
  activePage.value = stringToPage(
    pages[(currentIndex + step + pages.length) % pages.length],
  );
};
</script>

<template>
  <div
    id="carousel-wrapper"
    class="v-layout--full-height"
    tabindex="-1"
    @keydown.right="changePage(1)"
    @keydown.left="changePage(-1)"
  >
    <DetailPane location="left" noContainer :width="SIDEBAR_WIDTH">
      <VTabs v-model="activePage" direction="vertical" class="my-auto">
        <VTab :value="Page.OPPORTUNITIES"
          >{{ t("reporting.opportunity", 2) }}
        </VTab>
        <VTab :value="Page.WINDPARKS"
          >{{ t("reporting.windfarmReports") }}
        </VTab>
        <VTab :value="Page.LAND_OWNERS"
          >{{ t("reporting.landOwnerReports") }}
        </VTab>
        <VTab :value="Page.LAND_OWNERS_CONTRACTS"
          >{{ t("reporting.landOwnerContract.landOwnerContractReports") }}
        </VTab>
        <VTab :value="Page.PROJECT_DEVELOPER"
          >{{ t("reporting.pdReports") }}
        </VTab>
        <VTab :value="Page.PROJECT_DEVELOPER_CONTRACTS"
          >{{ t("reporting.pdContractReports") }}
        </VTab>
      </VTabs>
    </DetailPane>

    <FunnelCarousel
      v-model="activePage"
      class="carousel"
      :arrowWidth="ARROW_WIDTH"
    >
      <FunnelCarouselItem :value="Page.OPPORTUNITIES">
        <OpportunityReportingContainer />
      </FunnelCarouselItem>
      <FunnelCarouselItem :value="Page.WINDPARKS">
        <WindfarmReportingContainer />
      </FunnelCarouselItem>
      <FunnelCarouselItem
        gridRows="repeat(2, max-content)"
        :value="Page.LAND_OWNERS"
      >
        <LandOwnerReportingContainer />
      </FunnelCarouselItem>
      <FunnelCarouselItem
        gridRows="[shared-title] max-content [title] max-content [funnel] 26.5em"
        :value="Page.LAND_OWNERS_CONTRACTS"
      >
        <LandOwnerContractReportingContainer />
      </FunnelCarouselItem>
      <FunnelCarouselItem
        gridRows="repeat(3, max-content)"
        :value="Page.PROJECT_DEVELOPER"
      >
        <ProjectDeveloperReportingContainer />
      </FunnelCarouselItem>
      <FunnelCarouselItem
        gridRows="[title] max-content [table] max-content"
        :value="Page.PROJECT_DEVELOPER_CONTRACTS"
      >
        <ProjectDeveloperContractReportingContainer />
      </FunnelCarouselItem>
    </FunnelCarousel>
  </div>
</template>

<style scoped>
.v-tabs--vertical :deep(.v-tab__slider) {
  left: unset;
  right: 0;
}

.carousel {
  --total-width: calc(90vw - (1px * v-bind(LOST_WIDTH)));
  height: 90vh;

  font-size: calc(var(--total-width) * v-bind(TEXT_SIZE_RATIO));
}
</style>
