<script setup lang="ts">
import { computed } from "vue";

import ExtendActivityButton from "@/app/process/action/ExtendActivityButton.vue";
import { useActivityService } from "@/app/process/service/ActivityService";
import { useProcessUIStore } from "@/app/process/service/ProcessUIStore";

const props = defineProps<{
  activityId: string;
  isRoot?: boolean;
}>();

const activityService = useActivityService();
const processUiStore = useProcessUIStore();

const activity = computed(() => activityService.getActivity(props.activityId));
const outputs = computed(() => activityService.getOutputs(props.activityId));
const isActivityReleased = computed(() =>
  activityService.isReleased(props.activityId),
);
</script>

<template>
  <div v-if="!isActivityReleased" class="d-flex align-center ga-2 py-1">
    <VTooltip location="start" :text="$t('processes.listView.addActivity')">
      <template #activator="{ props: activator }">
        <ExtendActivityButton
          v-if="outputs.length > 0"
          v-bind="activator"
          :activityId="props.activityId"
        />
      </template>
    </VTooltip>
    <VTooltip location="start" :text="$t('action.delete')">
      <template #activator="{ props: activator }">
        <VBtn
          v-bind="activator"
          :title="$t('action.delete')"
          variant="plain"
          color="error"
          size="tiny"
          icon="mdi-delete"
          @click.stop.prevent="
            () =>
              activity ? processUiStore.openDeleteDialog([activity]) : undefined
          "
        />
      </template>
    </VTooltip>
  </div>
</template>
