<script setup lang="ts">
import { useGroupFunnels } from "./FunnelProvider";

defineProps<{
  title?: string;
}>();

const funnelCount = useGroupFunnels();
</script>
<template>
  <a v-if="title" class="title">{{ title }}</a>
  <slot />
</template>

<style scoped>
.title {
  grid-column: span v-bind(funnelCount);
  text-align: center;
  font-size: 1.5em; /* h2 font size */
}
</style>
