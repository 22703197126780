<script setup lang="ts">
import { computed } from "vue";

import { useI18n } from "@/app/base/utils/i18n";
import FieldByOutputType from "@/app/process/output/FieldByOutputType.vue";
import { useProcessService } from "@/app/process/service/ProcessService";
import { type ActivityOutputType } from "@/gql/types";

const { t } = useI18n();

const props = defineProps<{
  processId: string;
  targetStartActivityId: string;
  processActivityId: string;
  previousOutputId: string;
  previousType?: ActivityOutputType;
  currentType?: ActivityOutputType;
}>();

const processService = useProcessService();
const processOutput = computed(() =>
  processService.getProcessOutput(
    props.processActivityId,
    props.previousOutputId,
  ),
);
const migrationFields = computed(() =>
  processService
    .getAllMigrationFields(props.processId, props.targetStartActivityId)
    .get(processOutput.value?.id ?? "undefined"),
);
const migrationState = computed(() =>
  processOutput.value
    ? processService.getMigrationState(
        props.processId,
        props.targetStartActivityId,
        processOutput.value.id,
      )
    : false,
);
</script>

<template>
  <div
    v-if="
      props.previousType &&
      props.currentType &&
      processOutput &&
      processOutput.id
    "
    class="d-flex w-100 ga-3"
  >
    <div class="d-flex flex-column w-33 ga-2">
      <span class="text-caption pr-3">{{
        t("processes.upgrade.previousValue")
      }}</span>
      <FieldByOutputType
        :values="processOutput"
        :contextKey="processOutput.id"
        :processOutputId="processOutput.id"
        :outputType="props.previousType!"
        :readonly="true"
        :noLabel="true"
      />
    </div>
    <div class="d-flex flex-column w-66 ga-2">
      <span class="text-caption pr-3">{{
        t("processes.upgrade.newValue")
      }}</span>
      <div class="d-flex">
        <FieldByOutputType
          :values="migrationFields"
          :contextKey="previousOutputId"
          :outputType="props.currentType!"
          :processOutputId="previousOutputId"
          :readonly="migrationState"
          :noLabel="true"
          @change="
            (changedFields) => {
              processService.updateMigrationFields(
                props.processId,
                props.targetStartActivityId,
                { id: processOutput!.id, ...changedFields },
              );
            }
          "
          @delete="
            processService.clearMigrationFields(
              props.processId,
              props.targetStartActivityId,
              processOutput!.id,
            )
          "
        />
        <VBtn
          variant="plain"
          size="small"
          :icon="migrationState ? 'mdi-pencil' : 'mdi-check'"
          @click="
            () => {
              processService.updateMigrationState(
                props.processId,
                props.targetStartActivityId,
                processOutput!.id,
                !migrationState,
              );
            }
          "
        />
      </div>
    </div>
  </div>
</template>
