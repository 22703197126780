/* istanbul ignore file */
/* eslint-disable */

export enum SalesEvent {
  LANDOWNER_REGISTERED = 'LANDOWNER_REGISTERED',
  INITIAL_MEETING_SCHEDULED = 'INITIAL_MEETING_SCHEDULED',
  SHAPE_FILES_RECEIVED = 'SHAPE_FILES_RECEIVED',
  LOCATION_CHECK_PASSED = 'LOCATION_CHECK_PASSED',
  SERVICE_CONTRACT_IN_NEGOTIATION = 'SERVICE_CONTRACT_IN_NEGOTIATION',
  SERVICE_CONTRACT_REFUSED = 'SERVICE_CONTRACT_REFUSED',
  SERVICE_CONTRACT_SIGNED = 'SERVICE_CONTRACT_SIGNED',
  LANDOWNER_HAS_CANCELED = 'LANDOWNER_HAS_CANCELED',
  ABORTED_UNECONOMICAL = 'ABORTED_UNECONOMICAL',
  ABORTED_NATURAL_RESERVE = 'ABORTED_NATURAL_RESERVE',
  ABORTED_BIRD_PROTECTION = 'ABORTED_BIRD_PROTECTION',
  ABORTED_CIVIL_AVIATION = 'ABORTED_CIVIL_AVIATION',
  ABORTED_MILITARY = 'ABORTED_MILITARY',
  ABORTED_SINGLE_TURBINE = 'ABORTED_SINGLE_TURBINE',
  ABORTED_FEEDBACK = 'ABORTED_FEEDBACK',
  ABORTED_OTHER = 'ABORTED_OTHER',
}
