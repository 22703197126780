<script setup lang="ts">
import { ref } from "vue";

import AppButton from "@/app/base/button/AppButton.vue";
import { formStyles } from "@/app/base/form/formStyles";
import { useI18n } from "@/app/base/utils/i18n";
import { useModel } from "@/app/base/utils/useModel";
import KanbanControls from "@/app/common/kanban/KanbanControls.vue";
import { type Opportunity } from "@/services/backend/models/Opportunity";
import { type OpportunityView } from "@/services/backend/models/OpportunityView";
import { type SalesStage } from "@/services/backend/models/SalesStage";

import OpportunityCreator from "./OpportunityCreator.vue";
import OpportunityKanban from "./OpportunityKanban.vue";

const props = defineProps<{
  opportunities: OpportunityView[];
  search: string;
  onAdd: (value: Opportunity) => void | Promise<void>;
  userMail: string;
}>();

const emit = defineEmits<{
  (e: "move", opportunity: OpportunityView, newStage: SalesStage): void;
  (e: "open", opportunity: OpportunityView): void;
  (e: "update:search", value: string): void;
  (e: "update:statusFilter", value: string[]): void;
}>();

const searchModel = useModel(props, "search")(emit);
const adding = ref(false);

const { t } = useI18n();
</script>

<template>
  <KanbanControls>
    <VTextField
      v-model="searchModel"
      v-bind="formStyles"
      :label="t('opportunities.nameFilter')"
      appendInnerIcon="mdi-magnify"
    />
    <VSpacer />
    <VCardActions>
      <AppButton
        icon="mdi-plus"
        :label="t('ui.add')"
        highlight
        :disabled="adding"
        @click="adding = true"
      />
    </VCardActions>
  </KanbanControls>
  <OpportunityKanban
    :opportunities="opportunities"
    @open="(opportunity) => emit('open', opportunity)"
    @move="(opportunity, newStage) => emit('move', opportunity, newStage)"
  />
  <OpportunityCreator
    v-model:open="adding"
    :onAdd="onAdd"
    :userMail="userMail"
  />
</template>

<style scoped>
.kanban-controls > .v-text-field {
  max-width: 40em;
}
</style>
