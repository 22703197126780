<script setup lang="ts">
import { useRouter } from "vue-router";

import { isEmpty } from "@/app/base/utils/array";
import { useLinkStore } from "@/app/common/store/LinkStore";
import { CustomerType } from "@/services/backend/models/CustomerType";
import { type CustomerView } from "@/services/backend/models/CustomerView";

import CustomerWidget from "./CustomerWidget.vue";

const props = defineProps<{
  customers: CustomerView[];
  removeButtonLabel: string;
}>();

const emit =
  defineEmits<(e: "remove-customer", customer: CustomerView) => void>();

const router = useRouter();

const linkStore = useLinkStore();

const customerUrlName = (customer: CustomerView) => {
  switch (customer.customerType) {
    case CustomerType.LAND_OWNER:
      return "landOwnerSingle";
    case CustomerType.PROJECT_DEVELOPER:
      return "projectDeveloperSingle";
    case CustomerType.OTHER:
      return "otherCustomersSingle";
    default:
      return undefined;
  }
};

const openCustomer = async (customer: CustomerView) => {
  const urlName = customerUrlName(customer);

  if (urlName) {
    const { href } = router.resolve({
      name: urlName,
      params: { customerId: customer.id },
    });
    await router.push(href);
  }
};

const removeButtonDisabled = (customerId: number) => {
  // customer may not be removed when attachments are still linked or when he's a land owner and areas are still linked
  const customerType = props.customers.find(
    (c) => c.id === customerId,
  )?.customerType;
  return (
    customerType === CustomerType.LAND_OWNER && !isEmpty(linkStore.areaLinks)
  );
};
</script>

<template>
  <CustomerWidget
    :customers="customers"
    :removeButtonLabel="removeButtonLabel"
    :removeButtonDisabled="removeButtonDisabled"
    @open-customer="openCustomer"
    @remove-customer="emit('remove-customer', $event)"
  />
</template>

<style scoped></style>
