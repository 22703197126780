<script setup lang="ts">
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import { NumberFormat, useI18n } from "@/app/base/utils/i18n";
import { type FunnelStage } from "@/app/reporting/common/FunnelStage";
import ReportingFunnel from "@/app/reporting/common/ReportingFunnel.vue";
import ReportingFunnelGroup from "@/app/reporting/common/ReportingFunnelGroup.vue";
import ReportingCard from "@/app/reporting/windfarms/ReportingCard.vue";
import { type ApiError } from "@/services/backend/core/ApiError";
import { SalesStage } from "@/services/backend/models/SalesStage";
import { type SalesStageCount } from "@/services/backend/models/SalesStageCount";
import { ReportingControllerService } from "@/services/backend/services/ReportingControllerService";

const { t, tEnum, n } = useI18n();

const router = useRouter();

const badGateway = ref(false);

let areaStageCounts: SalesStageCount[];
let turbineStageCounts: SalesStageCount[];
let powerStageCounts: SalesStageCount[];

const label = (stages: SalesStage[]) => {
  if (stages.length === 1) {
    return tEnum("opportunities.stage", stages[0]);
  }
  const parts: string[] = [];
  stages.forEach((stage) => {
    parts.push(tEnum("opportunities.stage", stage), " / ");
  });
  parts.pop();
  return parts.join("");
};

function opportunityStage(
  stageCounts: SalesStageCount[],
  stages: SalesStage[],
  color: string,
  format: NumberFormat = NumberFormat.INTEGER,
): FunnelStage {
  const count = stageCounts
    .filter((stageCount) => stages.indexOf(stageCount.stage) > -1)
    .reduce((sum, stagesCount) => sum + stagesCount.count, 0);
  return {
    color,
    label: label(stages),
    value: n(count, format),
    onClick() {
      return void router.push(
        router.resolve({
          name: "areaStageList",
          params: { salesStages: stages },
        }).href,
      );
    },
  };
}

const stageCountFunnel = (
  stageCounts: SalesStageCount[],
  format?: NumberFormat,
) => [
  opportunityStage(
    stageCounts,
    [SalesStage.LOCATION_ANALYSIS],
    "funnel1",
    format,
  ),
  opportunityStage(
    stageCounts,
    [SalesStage.VSV_IN_NEGOTIATION],
    "funnel2",
    format,
  ),
  opportunityStage(stageCounts, [SalesStage.VSV_COMPLETED], "funnel3", format),
  opportunityStage(
    stageCounts,
    [SalesStage.VSV_DECLINED, SalesStage.ABORT],
    "error",
    format,
  ),
];

const areaFunnel = computed<FunnelStage[]>(() =>
  stageCountFunnel(areaStageCounts),
);
const turbineFunnel = computed<FunnelStage[]>(() =>
  stageCountFunnel(turbineStageCounts),
);
const powerFunnel = computed<FunnelStage[]>(() =>
  stageCountFunnel(powerStageCounts, NumberFormat.DECIMAL_1),
);

// Vue primitives (computed, ref etc.) must be called before await
try {
  [areaStageCounts, turbineStageCounts, powerStageCounts] = await Promise.all([
    ReportingControllerService.countOpportunityAreasByStage(),
    ReportingControllerService.sumOpportunityTurbinesByStage(),
    ReportingControllerService.sumOpportunityPowerByStage(),
  ]);
} catch (e) {
  const error = e as ApiError;
  if (error.status === 502) {
    badGateway.value = true;
  } else {
    throw e;
  }
}

const cardStages = [SalesStage.VSV_IN_NEGOTIATION, SalesStage.VSV_COMPLETED];

const onClickDummy = () => {
  return router.push(
    router.resolve({
      name: "areaStageList",
      params: { salesStages: cardStages },
    }).href,
  );
};

const cardNumber = (stageCounts: SalesStageCount[]) => {
  return stageCounts
    .filter((stageCount) => {
      return cardStages.indexOf(stageCount.stage) > -1;
    })
    .map((stageCount) => stageCount.count)
    .reduce((sum, count) => sum + count, 0);
};
</script>

<template>
  <ReportingFunnelGroup v-if="!badGateway" :title="t('reporting.windFarms')">
    <ReportingCard
      :number="cardNumber(areaStageCounts)"
      :gridColumn="+1"
      :title="t('reporting.vsvAndWon')"
      :subTitle="t('reporting.windFarms')"
      :openDetails="onClickDummy"
    />
    <ReportingFunnel
      :subtitle="`(${t('reporting.windFarms')})`"
      :stages="areaFunnel"
    />
    <ReportingCard
      :number="cardNumber(turbineStageCounts)"
      :gridColumn="+2"
      :title="t('reporting.vsvAndWon')"
      :subTitle="t('reporting.turbines')"
      :openDetails="onClickDummy"
    />
    <ReportingFunnel
      :subtitle="`(${t('reporting.turbines')})`"
      :stages="turbineFunnel"
    />
    <ReportingCard
      :number="cardNumber(powerStageCounts)"
      :gridColumn="+3"
      :title="t('reporting.vsvAndWon')"
      :subTitle="t('reporting.megawatts')"
      :openDetails="onClickDummy"
    />
    <ReportingFunnel
      :subtitle="`(${t('reporting.megawatts')})`"
      :stages="powerFunnel"
    />
  </ReportingFunnelGroup>
  <div v-else>
    {{ t("ui.infrawindError") }}
  </div>
</template>
