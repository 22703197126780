<script setup lang="ts">
import AppButton from "@/app/base/button/AppButton.vue";
import { useI18n } from "@/app/base/utils/i18n";

import { registerSidebarCard } from "./SidebarCountProvider";

defineProps<{
  openTooltip?: string;
}>();

const emit = defineEmits<(e: "open") => void>();

const { t } = useI18n();

registerSidebarCard();
</script>

<template>
  <VCard class="border1" elevation="0">
    <VCardText class="text-body-1">
      <div class="float-right">
        <slot name="top-buttons" />
      </div>
      <slot />
      <VDivider class="mr-2 my-1" />
      <AppButton
        size="small"
        class="mb-1 float-right open-button"
        icon="mdi-chevron-right"
        highlight
        :label="t('ui.open')"
        :tooltip="openTooltip"
        @click="emit('open')"
      />
    </VCardText>
  </VCard>
</template>

<style scoped>
.open-button :deep(.large-icon) {
  margin-right: 0;
}
</style>
