import { inject } from "vue";

import { DialogProvideKey, type DialogProvider } from "./DialogProvider";

export const useDialog = () => {
  return inject(
    DialogProvideKey,
    (): DialogProvider => {
      console.error("useDialog called outside of a DialogScope");
      return {
        confirm(dialog) {
          return Promise.resolve(
            window.confirm(
              [dialog.title, dialog.message].filter(Boolean).join("\n\n"),
            ),
          );
        },
      };
    },
    true,
  );
};
