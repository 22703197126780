<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import { searchFilter, useFilter } from "@/app/base/search/filter";
import { enrichAggregatedAreaInfo } from "@/app/common/area/area";
import { useOpportunityStore } from "@/app/opportunities/store/OpportunityStore";
import { UserAuthService } from "@/services/auth/UserAuthService";
import { type Opportunity } from "@/services/backend/models/Opportunity";
import { type OpportunityView } from "@/services/backend/models/OpportunityView";
import { type SalesStage } from "@/services/backend/models/SalesStage";
import { OpportunityControllerService } from "@/services/backend/services/OpportunityControllerService";

import OpportunityBoard from "./OpportunityBoard.vue";

const router = useRouter();
const opportunityStore = useOpportunityStore();

// needs to be 'reactive' because the array is changed when drag&dropping cards
// first load synchronously without external info because that's much faster
const opportunities = ref(
  await OpportunityControllerService.getOpportunities({}),
);
// then load asynchronously with external info
onMounted(async () => {
  const opportunitiesWithExternalInfos =
    await OpportunityControllerService.getOpportunities({
      withInfrawindInfo: true,
    });
  // enrich the existing data instead of replacing it because there may already be changes (stage, new entities)
  opportunities.value.forEach((o) => {
    const opportunityWithExternalInfos = opportunitiesWithExternalInfos.find(
      (oe) => oe.id === o.id,
    );
    if (opportunityWithExternalInfos) {
      enrichAggregatedAreaInfo(o, opportunityWithExternalInfos);
    }
  });
});

const openOpportunity = (opportunity: OpportunityView) => {
  const { href } = router.resolve({
    name: "opportunitySingle",
    params: { opportunityId: opportunity.id },
  });
  return router.push(href);
};

const moveOpportunity = async (
  opportunity: OpportunityView,
  stage: SalesStage,
) => {
  await OpportunityControllerService.updateOpportunityStage({
    opportunityId: opportunity.id,
    salesStage: stage,
  });
  opportunityStore.cache.delete(opportunity.id);

  const index = opportunities.value.findIndex(
    (opp) => opp.id === opportunity.id,
  );
  opportunities.value.splice(index, 1, { ...opportunity, stage });
};

async function addOpportunity(opportunity: Opportunity) {
  const { id } = await OpportunityControllerService.createOpportunity({
    requestBody: opportunity,
  });
  const { href } = router.resolve({
    name: "opportunitySingle",
    params: { opportunityId: id },
  });
  await router.push(href);
}

const search = ref("");

const filteredOpportunities = useFilter(
  opportunities,
  searchFilter(search, (opportunity) => [
    opportunity.name,
    `${opportunity.id}`,
  ]),
);
</script>

<template>
  <OpportunityBoard
    v-model:search="search"
    :opportunities="filteredOpportunities"
    :onAdd="addOpportunity"
    :userMail="UserAuthService.getUserMail()"
    @open="openOpportunity"
    @move="moveOpportunity"
  />
</template>
