/* istanbul ignore file */
/* eslint-disable */
import type { CompanyTypeCount } from '../models/CompanyTypeCount';
import type { LandOwnerAreaStatusCount } from '../models/LandOwnerAreaStatusCount';
import type { LandOwnerSalesStageByOpportunity } from '../models/LandOwnerSalesStageByOpportunity';
import type { ProbabilityCount } from '../models/ProbabilityCount';
import type { QualificationStageCount } from '../models/QualificationStageCount';
import type { SalesStageCount } from '../models/SalesStageCount';
import type { SourceCount } from '../models/SourceCount';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReportingControllerService {

  /**
   * @returns QualificationStageCount OK
   * @throws ApiError
   */
  public static countProjectDevelopersByStage(): CancelablePromise<Array<QualificationStageCount>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/pisys/v1/reporting/project-developers/stage',
    });
  }

  /**
   * @returns SourceCount OK
   * @throws ApiError
   */
  public static countProjectDevelopersBySource(): CancelablePromise<Array<SourceCount>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/pisys/v1/reporting/project-developers/source',
    });
  }

  /**
   * @returns ProbabilityCount OK
   * @throws ApiError
   */
  public static countProjectDevelopersByProbability(): CancelablePromise<Array<ProbabilityCount>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/pisys/v1/reporting/project-developers/probability',
    });
  }

  /**
   * @returns CompanyTypeCount OK
   * @throws ApiError
   */
  public static countProjectDevelopersByCompanyType(): CancelablePromise<Array<CompanyTypeCount>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/pisys/v1/reporting/project-developers/company-type',
    });
  }

  /**
   * @returns SalesStageCount OK
   * @throws ApiError
   */
  public static sumOpportunityTurbinesByStage(): CancelablePromise<Array<SalesStageCount>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/pisys/v1/reporting/opportunities/turbines-by-stage',
    });
  }

  /**
   * @returns SalesStageCount OK
   * @throws ApiError
   */
  public static countOpportunitiesByStage(): CancelablePromise<Array<SalesStageCount>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/pisys/v1/reporting/opportunities/stage',
    });
  }

  /**
   * @returns SalesStageCount OK
   * @throws ApiError
   */
  public static sumOpportunityPowerByStage(): CancelablePromise<Array<SalesStageCount>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/pisys/v1/reporting/opportunities/power-by-stage',
    });
  }

  /**
   * @returns SalesStageCount OK
   * @throws ApiError
   */
  public static countOpportunityAreasByStage(): CancelablePromise<Array<SalesStageCount>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/pisys/v1/reporting/opportunities/areas-by-stage',
    });
  }

  /**
   * @returns LandOwnerSalesStageByOpportunity OK
   * @throws ApiError
   */
  public static getLandOwnerSalesStagesByOpportunity(): CancelablePromise<Array<LandOwnerSalesStageByOpportunity>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/pisys/v1/reporting/land-owners/sales-stage-by-opportunity',
    });
  }

  /**
   * @returns LandOwnerAreaStatusCount OK
   * @throws ApiError
   */
  public static countLandOwnerAreasByStatus(): CancelablePromise<Array<LandOwnerAreaStatusCount>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/pisys/v1/reporting/land-owners/areas-by-status',
    });
  }

}
