<script setup lang="ts">
import { computed } from "vue";

import { useDefaultModel } from "@/app/base/utils/useModel";

const props = withDefaults(
  defineProps<{
    header?: string;
    modelValue?: boolean;
  }>(),
  {
    header: undefined,
    modelValue: undefined,
  },
);

const emits = defineEmits<(e: "update:modelValue", value: boolean) => void>();

const model = useDefaultModel(props, "modelValue", true)(emits);
const showFoldable = computed(() => props.modelValue !== undefined);
</script>

<template>
  <h5 class="text-pre-title">
    <VIcon
      v-if="showFoldable"
      color="caeli5"
      :icon="model ? '$collapse' : '$expand'"
      @click="model = !model"
    />
    <slot name="header">{{ header }}</slot>
    <div v-if="$slots.default" class="buttons">
      <slot />
    </div>
  </h5>
</template>

<style scoped>
.text-pre-title {
  padding: 8px 0 8px 0;
  min-height: 36px;
  box-sizing: content-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
}

.buttons {
  margin-left: auto;
}
</style>
