<script setup lang="ts">
import { ref, toRef, watch } from "vue";

import AppButton from "@/app/base/button/AppButton.vue";
import CheckboxGroup from "@/app/base/form/CheckboxGroup.vue";
import CheckboxGroupRow from "@/app/base/form/CheckboxGroupRow.vue";
import { formStyles } from "@/app/base/form/formStyles";
import DetailPane from "@/app/base/layout/DetailPane.vue";
import LoadingIndicator from "@/app/base/loading/LoadingIndicator.vue";
import { searchFilter, useFilter } from "@/app/base/search/filter";
import { useI18n } from "@/app/base/utils/i18n";
import { useSet, useSingleSet } from "@/app/base/utils/SetView";
import { useDefaultModel } from "@/app/base/utils/useModel";
import { formatArea } from "@/app/common/area/area";
import { type Area } from "@/services/backend/models/Area";

const props = withDefaults(
  defineProps<{
    open: boolean;
    onLink: (value: string[]) => Promise<void>;
    areas: Area[];
    single?: boolean;
  }>(),
  {
    single: false,
  },
);

const emit = defineEmits<(e: "update:open", value: boolean) => void>();

const openModel = useDefaultModel(props, "open", true)(emit);

const { t } = useI18n();

const getSet = () => {
  if (props.single) {
    return useSingleSet<string>();
  }
  return useSet<string>();
};

const [selected, getSelectedModel, selectionEmpty, clearSelection] = getSet();

const search = ref("");

const filteredAreas = useFilter(
  toRef(props, "areas"),
  searchFilter(search, (area) => [area.name]),
);

function resetView() {
  if (props.open) {
    search.value = "";
    clearSelection();
  }
}

// Reset the view when the panel is reopened and when the available areas change (e.g. because of removing the land owner)
watch(
  () => props.open,
  () => {
    resetView();
  },
);
watch(
  () => props.areas,
  () => {
    resetView();
  },
);
</script>

<template>
  <DetailPane
    v-model="openModel"
    location="right"
    transient
    fixed
    :header="t('entities.linkAreas')"
    closeButton
  >
    <VTextField
      v-bind="formStyles"
      v-model="search"
      class="flex-grow-0"
      :label="t('table.search')"
    />
    <CheckboxGroup v-if="areas" :minRows="5">
      <CheckboxGroupRow
        v-for="area of filteredAreas"
        :key="area.id"
        v-model="getSelectedModel(area.id).value"
        :single="single"
      >
        <span>{{ area.name }}</span>
        <span v-if="area.areaHaDevelopable">
          {{ formatArea(area.areaHaDevelopable) }}
        </span>
      </CheckboxGroupRow>
    </CheckboxGroup>
    <LoadingIndicator v-else :items="t('entities.area', 2)" />
    <AppButton
      class="align-self-end"
      :label="t('ui.link')"
      :disabled="selectionEmpty"
      highlight
      solid
      corners
      heavy
      @click="onLink(selected)"
      @success="openModel = false"
    />
  </DetailPane>
</template>

<style scoped>
.v-list-item {
  cursor: pointer;
}
.checkbox-group {
  flex: 0 max-content;
  min-height: 1em;
  --v-input-control-height: 1em;
}
</style>
