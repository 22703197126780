import { type LandOwnerSalesStageByOpportunity } from "@/services/backend/models/LandOwnerSalesStageByOpportunity";
import { SalesStage } from "@/services/backend/models/SalesStage";

export type LandOwnerSalesStageByOpportunityWithContractStatus =
  LandOwnerSalesStageByOpportunity & {
    contractStatus: ContractReportingStatus;
  };

export enum ContractReportingStatus {
  VSV_SENT = "VSV_SENT",
  VSV_COMPLETED = "VSV_COMPLETED",
  VSV_REJECTED = "VSV_REJECTED",
  EMPTY = "EMPTY",
}

export function salesStageToContractReportingStatus(
  salesStage: SalesStage,
): ContractReportingStatus {
  switch (salesStage) {
    case SalesStage.VSV_IN_NEGOTIATION:
      return ContractReportingStatus.VSV_SENT;
    case SalesStage.VSV_COMPLETED:
      return ContractReportingStatus.VSV_COMPLETED;
    case SalesStage.ABORT:
    case SalesStage.VSV_DECLINED:
      return ContractReportingStatus.VSV_REJECTED;
    default:
      return ContractReportingStatus.EMPTY;
  }
}
