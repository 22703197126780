import {
  type LocationQuery,
  type LocationQueryRaw,
  type Router,
} from "vue-router";

export interface FilterParameters {
  searchText: string;
  searchTerms: (string | { left: string; right: string })[];
  tagFilters: string[];
  valueFieldFilters: { left: string; right: string }[];
  visibleColumns?: string[];
}

export class DataTableFilterParam {
  contextKey: string;
  router: Router;

  constructor(contextKey: string, router: Router) {
    this.contextKey = contextKey;
    this.router = router;
  }

  static fromUrl(key: string, router: Router) {
    return new DataTableFilterParam(key, router).parseFromUrl();
  }

  // NICE: Refactor this so that FilterParameters and DataTableFilterParam are not separate and all the logic is contained inside the FilterParameters object. This will also reduce the complexity of this merge function.
  static merge(
    oldFilters: FilterParameters | undefined,
    newFilters: Partial<FilterParameters>,
  ): FilterParameters {
    const searchTerms =
      newFilters.searchText !== undefined
        ? this.parseSearchTerm(newFilters.searchText)
        : oldFilters?.searchTerms ?? [];

    return {
      searchText: newFilters.searchText ?? oldFilters?.searchText ?? "",
      tagFilters: newFilters.tagFilters ?? oldFilters?.tagFilters ?? [],
      valueFieldFilters:
        newFilters.valueFieldFilters ?? oldFilters?.valueFieldFilters ?? [],
      visibleColumns:
        newFilters.visibleColumns ?? oldFilters?.visibleColumns ?? [],
      searchTerms,
    };
  }

  static toQuery(
    contextKey: string,
    params: FilterParameters,
  ): LocationQueryRaw {
    const query: LocationQueryRaw = {};
    if (params.visibleColumns?.length) {
      query[`${contextKey}.columns`] = encodeURIComponent(
        params.visibleColumns.join(","),
      );
    }
    if (params.searchText?.length) {
      query[`${contextKey}.searchText`] = encodeURIComponent(params.searchText);
    }
    if (params.tagFilters?.length) {
      query[`${contextKey}.tags`] = encodeURIComponent(
        params.tagFilters.join(","),
      );
    }
    if (params.valueFieldFilters?.length) {
      query[`${contextKey}.filters`] = encodeURIComponent(
        params.valueFieldFilters
          .map((it) => `${it.left}=${it.right}`)
          .join(","),
      );
    }

    return query;
  }

  private parseFromUrl(): FilterParameters {
    const query = this.router.currentRoute.value.query;
    const searchText = this.parseSearchText(this.contextKey, query);
    return {
      visibleColumns: this.parseColumns(this.contextKey, query),
      searchText,
      searchTerms: DataTableFilterParam.parseSearchTerm(searchText),
      tagFilters: this.parseTagFilters(this.contextKey, query),
      valueFieldFilters: this.parseValueFilters(this.contextKey, query),
    };
  }

  private parseSearchText(key: string, query: LocationQuery) {
    const searchTextRaw = query[key + ".searchText"]?.toString();
    const searchText = searchTextRaw
      ? decodeURIComponent(searchTextRaw)
      : undefined;
    return searchText ?? "";
  }

  private parseTagFilters(key: string, query: LocationQuery) {
    const tagFilters = this.splitQueryParams(query[key + ".tags"]?.toString());
    return tagFilters ?? [];
  }

  private parseValueFilters(key: string, query: LocationQuery) {
    const valueFieldFilters = this.splitQueryParams(
      query[key + ".filters"]?.toString(),
    )?.map((raw) => ({
      left: raw.split("=")[0],
      right: raw.split("=")[1],
    }));
    return valueFieldFilters ?? [];
  }

  private parseColumns(key: string, query: LocationQuery) {
    return this.splitQueryParams(query[key + ".columns"]?.toString());
  }

  private static parseSearchTerm(
    text: string,
  ): (string | { left: string; right: string })[] {
    const terms: (string | { left: string; right: string })[] = [];
    if (this.getUnescapedQuoteIndices(text).length % 2 !== 0) {
      return [];
    }

    const parsingState: {
      insideQuotes: boolean;
      currentContent: string;
      complexTerm: { left: string; right: string } | null;
    } = {
      insideQuotes: false,
      currentContent: "",
      complexTerm: null,
    };
    for (const char of text) {
      if (parsingState.insideQuotes && char !== '"') {
        parsingState.currentContent += char;
      } else {
        this.parseCharOutsideQuotes(char, parsingState, terms);
      }
    }
    if (parsingState.complexTerm) {
      parsingState.complexTerm.right = parsingState.currentContent;
      terms.push(parsingState.complexTerm);
      parsingState.currentContent = "";
    }
    if (parsingState.currentContent.length) {
      terms.push(parsingState.currentContent);
    }
    return terms;
  }

  private static getUnescapedQuoteIndices(text: string) {
    const unescapedQuoteIndices: number[] = [];
    let previousChar: string | undefined = undefined;
    let index = 0;
    for (const currentChar of text) {
      if (currentChar === '"' && previousChar !== "\\") {
        unescapedQuoteIndices.push(index);
      }
      index++;
      previousChar = currentChar;
    }
    return unescapedQuoteIndices;
  }

  private static parseCharOutsideQuotes(
    char: string,
    parsingState: {
      insideQuotes: boolean;
      currentContent: string;
      complexTerm: { left: string; right: string } | null;
    },
    terms: (string | { left: string; right: string })[],
  ) {
    switch (char) {
      case '"':
        parsingState.insideQuotes = !parsingState.insideQuotes;
        break;
      case " ":
        if (parsingState.complexTerm) {
          parsingState.complexTerm.right = parsingState.currentContent;
          terms.push(parsingState.complexTerm);
          parsingState.complexTerm = null;
        }
        if (parsingState.currentContent.length) {
          terms.push(parsingState.currentContent);
          parsingState.currentContent = "";
        }
        break;
      case "=":
        if (parsingState.complexTerm) {
          console.error("Invalid search string. Double = makes no sense.");
        }
        parsingState.complexTerm = {
          left: parsingState.currentContent,
          right: "",
        };
        parsingState.currentContent = "";
        break;
      default:
        parsingState.currentContent += char;
    }
  }

  private splitQueryParams(rawParams?: string) {
    if (!rawParams) {
      return undefined;
    }
    return decodeURIComponent(rawParams).split(",") ?? [];
  }
}
