import { defineStore } from "pinia";
import { ref } from "vue";

import { type Url } from "@/services/backend/models/Url";
import { UrlControllerService } from "@/services/backend/services/UrlControllerService";

interface UrlFetchParams {
  projectId?: number;
  customerId?: number;
  opportunityId?: number;
}

export const useUrlStore = defineStore("url", () => {
  const activeProjectId = ref<number>();
  const activeCustomerId = ref<number>();
  const activeOpportunityId = ref<number>();
  const urls = ref<Url[]>([]);
  const loading = ref<boolean>(false);
  const isCreateMode = ref<boolean>(false);
  const editedUrlId = ref<number>();

  async function fetch({
    projectId,
    customerId,
    opportunityId,
  }: UrlFetchParams) {
    loading.value = true;
    activeProjectId.value = projectId;
    activeCustomerId.value = customerId;
    activeOpportunityId.value = opportunityId;
    urls.value = await UrlControllerService.getAll({
      projectId,
      customerId,
      opportunityId,
    });
    loading.value = false;
  }

  async function create(url: Url) {
    await UrlControllerService.create({
      requestBody: {
        ...url,
        projectId: activeProjectId.value,
        opportunityId: activeOpportunityId.value,
        customerId: activeCustomerId.value,
      },
    });
    await fetch({
      projectId: activeProjectId.value,
      customerId: activeCustomerId.value,
      opportunityId: activeOpportunityId.value,
    });
    isCreateMode.value = false;
    editedUrlId.value = undefined;
  }

  async function update(url: Url) {
    if (!url.id) {
      return;
    }
    await UrlControllerService.update({
      urlId: url.id,
      requestBody: {
        ...url,
        projectId: activeProjectId.value,
        opportunityId: activeOpportunityId.value,
        customerId: activeCustomerId.value,
      },
    });
    await fetch({
      projectId: activeProjectId.value,
      customerId: activeCustomerId.value,
      opportunityId: activeOpportunityId.value,
    });
    isCreateMode.value = false;
    editedUrlId.value = undefined;
  }

  async function remove(urlId: number) {
    await UrlControllerService.delete({ urlId });
    await fetch({
      projectId: activeProjectId.value,
      customerId: activeCustomerId.value,
      opportunityId: activeOpportunityId.value,
    });
  }

  return {
    urls,
    loading,
    isCreateMode,
    editedUrlId,
    fetch,
    create,
    update,
    remove,
  };
});
