export enum Tabs {
  AREAS = "areas",
  ATTACHMENTS = "attachments",
  EVENTS = "events",
  NOTES = "notes",
  TASKS = "tasks",
  URLS = "urls",
}

export const stringToTab = (tab: string) =>
  Object.values<string>(Tabs).includes(tab) ? (tab as Tabs) : undefined;
