/* istanbul ignore file */
/* eslint-disable */

export enum AfterSalesStage {
  GENERAL_RESEARCH_AND_PLANNING_LAW = 'GENERAL_RESEARCH_AND_PLANNING_LAW',
  INSPECTION_INCL_PREPARATION = 'INSPECTION_INCL_PREPARATION',
  FINAL_PLANNING = 'FINAL_PLANNING',
  AUCTION_PREPARATION = 'AUCTION_PREPARATION',
  AUCTION = 'AUCTION',
  MONITORING = 'MONITORING',
}
