<script setup lang="ts">
import { onBeforeMount } from "vue";

import { useI18n } from "@/app/base/utils/i18n";

defineProps<{
  title?: string;
  cancelMessage?: string;
  confirmMessage?: string;
  destructive?: boolean;
  noCancel?: boolean;
  noConfirm?: boolean;
}>();

const emit = defineEmits<{
  (e: "confirm"): void;
  (e: "cancel"): void;
}>();

// Remove existing focus, so autofocus on the confirm button works
onBeforeMount(() => {
  const activeElement: Element | null = document.activeElement;
  (activeElement as HTMLElement | null)?.blur?.();
});

const { t } = useI18n();
</script>

<template>
  <!-- retainFocus is important to prevent a recursion https://github.com/vuetifyjs/vuetify/issues/8459 -->
  <VDialog
    maxWidth="800px"
    :modelValue="true"
    :retainFocus="false"
    @update:modelValue="emit('cancel')"
  >
    <VCard>
      <VCardTitle v-if="title">{{ title }}</VCardTitle>
      <VDivider v-if="title" />
      <VCardText>
        <slot />
      </VCardText>
      <VCardActions>
        <VSpacer />
        <VBtn v-if="!noCancel" @click="emit('cancel')">
          {{ cancelMessage ?? t("ui.cancel") }}
        </VBtn>
        <VBtn
          v-if="!noConfirm"
          autofocus
          :color="destructive ? 'error' : 'caeli5'"
          data-testid="app-dialog-confirm"
          @click="emit('confirm')"
        >
          {{ confirmMessage ?? t("ui.confirm") }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>
