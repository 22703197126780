<script setup lang="ts">
import { onMounted, ref, watch } from "vue";

import { useValidators } from "@/app/base/form/validators";
import { useI18n } from "@/app/base/utils/i18n";
import { extractDomain } from "@/app/base/utils/string";

const props = defineProps<{
  initialValue?: string;
  name?: string;
  readonly?: boolean;
}>();
const emits = defineEmits<(event: "update", value: string) => void>();

const { webUrl, isWebUrl } = useValidators();

const { t } = useI18n();

onMounted(() => {
  if (!props.initialValue) {
    enterEditMode();
  } else {
    originalValueString.value = props.initialValue;
  }
});
watch(
  () => props.initialValue,
  (current) => {
    valueString.value = current ?? "";
    if (!current) {
      enterEditMode();
    }
  },
);

const valueString = ref(props.initialValue ?? "");
const isEditMode = ref(false);
const originalValueString = ref("");
const checkUrlAndBlur = () => {
  if (!isWebUrl(valueString.value)) {
    return;
  }
  emits("update", valueString.value);
  isEditMode.value = false;
};

function cancelEditMode() {
  if (originalValueString.value === "") {
    return;
  }
  isEditMode.value = false;
  valueString.value = originalValueString.value;
}
function enterEditMode() {
  isEditMode.value = true;
  originalValueString.value = valueString.value;
}
</script>

<template>
  <div class="d-flex ga-2 w-100">
    <!-- Read-Only View-->
    <div
      v-if="props.readonly || !isEditMode"
      class="d-flex justify-space-between w-100"
    >
      <VBtn
        v-if="!props.readonly"
        class="text-start"
        :href="valueString"
        target="_blank"
      >
        {{
          extractDomain(valueString, t("processes.singleView.outputTypes.URL"))
        }}
        <VIcon right class="ml-2" icon="mdi-open-in-new" />
      </VBtn>
      <span v-else>{{ valueString }}</span>
      <VBtn
        v-if="!props.readonly"
        color="primary"
        variant="text"
        size="small"
        icon="mdi-pencil"
        @click="enterEditMode"
      />
    </div>
    <!-- Edit Mode-->
    <div v-else class="d-flex ga-2 w-100">
      <VTextField
        v-model="valueString"
        class="w-100 rounded-lg"
        variant="outlined"
        density="compact"
        :rules="[webUrl]"
        hideDetails="auto"
      />
      <VBtn
        icon="mdi-content-save-outline"
        size="small"
        class="ml-n20"
        variant="plain"
        @click="checkUrlAndBlur"
      ></VBtn>
      <VBtn
        color="red"
        icon="mdi-pencil-off-outline"
        size="small"
        class="ml-n4"
        variant="plain"
        @click="cancelEditMode"
      ></VBtn>
    </div>
  </div>
</template>
