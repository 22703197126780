<script setup lang="ts">
import { computed, ref, toRef } from "vue";
import { useRouter } from "vue-router";

import { useI18n } from "@/app/base/utils/i18n";
import { formatArea, formatCapacity } from "@/app/common/area/area";
import DataTable from "@/app/common/table/DataTable.vue";
import DataTableContainer from "@/app/common/table/DataTableContainer.vue";
import { tableDef } from "@/app/common/table/DataTableRenderer";
import OpportunityCreator from "@/app/opportunities/board/OpportunityCreator.vue";
import { type Opportunity } from "@/services/backend/models/Opportunity";
import { type OpportunityView } from "@/services/backend/models/OpportunityView";
import { type SalesStage } from "@/services/backend/models/SalesStage";

const { d, t, tEnum, $t, locale } = useI18n();

const props = defineProps<{
  opportunities: OpportunityView[];
  salesStages: SalesStage[];
  onAdd: (value: Opportunity) => Promise<void>;
  userMail: string;
}>();

const router = useRouter();

const table = tableDef<OpportunityView>(
  (col) => [
    col
      .index("name")
      .label($t("opportunities.opportunity"))
      .searchable(true)
      .comparator((a, b) => a.localeCompare(b, locale.value))
      .grow(),
    col
      .index("stage")
      .label($t("ui.stage"))
      .stringifier((stage) => tEnum("opportunities.stage", stage))
      .searchable(true)
      .comparator((a, b) => a.localeCompare(b, locale.value)),
    col
      .index("areaHaDevelopableSum")
      .label($t("entities.area"))
      .stringifier((area) => (area ? formatArea(area) : ""))
      .searchable(true)
      .comparator((a, b) => a - b),
    col
      .index("areaCount")
      .label($t("reporting.areaCount"))
      .searchable(true)
      .comparator((a, b) => a - b),
    col
      .index("turbineCountSum")
      .label($t("opportunities.turbineCount"))
      .searchable(true)
      .comparator((a, b) => a - b),
    col
      .index("totalPowerMwSum")
      .label($t("dashboard.opportunity.cardLine1"))
      .stringifier((capacity) => (capacity ? formatCapacity(capacity) : ""))
      .searchable(true)
      .comparator((a, b) => a - b),
    col
      .index("creationDate")
      .label($t("table.date"))
      .stringifier((cell) => d(cell))
      .comparator((a, b) => Date.parse(a) - Date.parse(b)),
  ],
  "id",
).withData(toRef(props, "opportunities"), (row) => {
  return void router.push(
    router.resolve({
      name: "opportunitySingle",
      params: { opportunityId: row.id },
    }).href,
  );
});

const adding = ref(false);

const heading = computed(() => {
  const parts = [t("opportunities.opportunity", 2), " - "];
  props.salesStages.forEach((stage) => {
    parts.push(tEnum("opportunities.stage", stage), ", ");
  });
  parts.pop();
  return parts.join("");
});
</script>

<template>
  <DataTableContainer>
    <DataTable
      :heading="heading"
      :entriesText="t('opportunities.opportunity', 2)"
      :table="table"
      @add="adding = true"
    >
    </DataTable>
  </DataTableContainer>
  <OpportunityCreator
    v-model:open="adding"
    :salesStage="salesStages[0]"
    :onAdd="onAdd"
    :userMail="userMail"
  />
</template>
