export async function copyPrefixedIdToClipboard(prefix: string, id: number) {
  const paddedId = String(id).padStart(5, "0");
  if (paddedId.length === 5 && id > 0) {
    const idText = `[${prefix}${paddedId}]`;
    await navigator.clipboard.writeText(idText);
  } else {
    console.error(
      "ID is negative or too big, cannot be padded to 5 digits. Not copied.",
      paddedId,
    );
    await navigator.clipboard.writeText("INVALID ID");
  }
}
