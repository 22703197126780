<template>
  <div class="button-group">
    <slot />
  </div>
</template>

<style scoped>
.button-group {
  margin-left: auto;
  display: flex;
  flex-direction: row;
}
</style>
