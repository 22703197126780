import messages from "@intlify/unplugin-vue-i18n/messages";
import { computed, type ComputedRef } from "vue";
// eslint-disable-next-line no-restricted-imports -- This is where useI18n is proxied
import { createI18n, useI18n as useNativeI18n } from "vue-i18n";

import type enGB from "@/assets/i18n/en-GB.json";

// The values here correspond to the values defined in @/i18n.ts
export enum NumberFormat {
  INTEGER = "integer",
  DECIMAL_1 = "decimal1",
  DECIMAL_2 = "decimal2",
}

export enum DateFormat {
  SHORT = "short",
  MEDIUM = "medium",
  LONG = "long",
}

const numberFormats = {
  currency: {
    style: "currency" as "currency", // Explicitly specify "currency" and cast to avoid TS error
    currency: "USD",
  },
  integer: {
    style: "decimal" as "decimal", // Explicitly specify "currency" and cast to avoid TS error
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
  decimal1: {
    style: "decimal" as "decimal", // Explicitly specify "currency" and cast to avoid TS error
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  },
  decimal2: {
    style: "decimal" as "decimal", // Explicitly specify "currency" and cast to avoid TS error
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
};

export type TranslationPath = KeysOfType<typeof enGB, string>;
export type EnumTranslationPath<Enum extends string> = KeysOfType<
  typeof enGB,
  Record<Enum, string>
>;

type KeysOfType<T, A, Prefix extends string = ""> = {
  [K in keyof T & string]:
    | (T[K] extends A ? `${Prefix}${K}` : never)
    | (T[K] extends Record<string, unknown>
        ? KeysOfType<T[K], A, `${Prefix}${K}.`>
        : never);
}[keyof T & string];

export function useI18n() {
  const nativeI18n = useNativeI18n();

  const t: typeof nativeI18n.t &
    ((key: TranslationPath, ...args: unknown[]) => string) = nativeI18n.t;

  const n = (count: number, format?: NumberFormat) =>
    format ? nativeI18n.n(count, format) : nativeI18n.n(count);

  const d = (date: string | Date, format?: DateFormat) =>
    format ? nativeI18n.d(date, format) : nativeI18n.d(date, DateFormat.SHORT);

  return {
    ...nativeI18n,

    /**
     * Create a reference to a translation, for use outside reactive contexts
     */
    // eslint-disable-next-line @intlify/vue-i18n/no-dynamic-keys
    $t: (key: TranslationPath) => computed(() => nativeI18n.t(key)),

    /**
     * Create a reference to a localised date, for use outside reactive contexts
     */
    $d: (date: Date, format?: DateFormat) =>
      format !== undefined
        ? computed(() => nativeI18n.d(date, format))
        : computed(() => nativeI18n.d(date)),

    /**
     * Create a reference to a localised number, for use outside reactive
     * contexts
     */
    $n: (count: number, format?: NumberFormat) =>
      computed(() => n(count, format)),

    n,

    t,

    d,

    tEnum: <Enum extends string>(
      path: EnumTranslationPath<Enum>,
      key: Enum,
      named?: Record<string, unknown>,
    ) =>
      // eslint-disable-next-line no-restricted-syntax,@intlify/vue-i18n/no-dynamic-keys -- this is safe because of the EnumTranslationPath type
      t(`${path}.${key}` as TranslationPath, named),
  };
}

/**
 * Return a ref of an array of all localised values of an enum
 *
 * If this returns the following error, the referenced key does not fully match
 * the enum. This check is performed against the en-gb file:
 *
 * > Argument of type 'string' is not assignable to parameter of type 'never'.
 */
export function useEnumOptions<Enum extends string>(
  path: EnumTranslationPath<Enum>,
  enumClass: Record<string, Enum>,
): ComputedRef<{ value: Enum; title: string }[]> {
  const { t } = useI18n();
  return computed(() =>
    Object.values(enumClass).map((enumValue) => ({
      value: enumValue,
      // eslint-disable-next-line no-restricted-syntax,@intlify/vue-i18n/no-dynamic-keys -- this is safe because of the EnumTranslationPath type
      title: t(`${path}.${enumValue}`),
    })),
  );
}

/**
 * Returns the same as useEnumOptions but alphabetically sorted by the translated value.
 */
export function useSortedEnumOptions<Enum extends string>(
  path: EnumTranslationPath<Enum>,
  enumClass: Record<string, Enum>,
): ComputedRef<{ value: Enum; title: string }[]> {
  return computed(() => {
    const options = useEnumOptions(path, enumClass).value;
    options.sort((a, b) => a.title.localeCompare(b.title));
    return options;
  });
}

/** Return a ref of an array of all localised values of an enum + the empty value to unset the attribute */
export function useEnumOptionsWithEmptyOption<Enum extends string>(
  path: EnumTranslationPath<Enum>,
  enumClass: Record<string, Enum>,
): ComputedRef<{ value: Enum | undefined; title: string }[]> {
  return computed(() => [
    { value: undefined, title: "" },
    ...useEnumOptions(path, enumClass).value,
  ]);
}

export const create = (locale = "de-DE") =>
  createI18n({
    legacy: false,
    locale,
    fallbackLocale: "en-GB",
    messages,
    numberFormats: {
      "en-GB": numberFormats,
      "de-DE": numberFormats,
    },
    datetimeFormats: {
      "en-GB": {
        short: {
          year: "numeric",
          month: "short",
          day: "numeric",
        },
        medium: {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        },
        long: {
          year: "numeric",
          month: "short",
          day: "numeric",
          weekday: "short",
          hour: "numeric",
          minute: "numeric",
        },
      },
      "de-DE": {
        short: {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        },
        medium: {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
        },
        long: {
          year: "numeric",
          month: "short",
          day: "numeric",
          weekday: "short",
          hour: "numeric",
          minute: "numeric",
        },
      },
    },
  });

export const i18n = create("de-DE");
