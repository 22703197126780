<script setup lang="ts">
import ProjectDeveloperContractReportingList from "@/app/reporting/projectDeveloperContract/ProjectDeveloperContractReportingList.vue";
import { CustomerType } from "@/services/backend/models/CustomerType";
import { CustomerControllerService } from "@/services/backend/services/CustomerControllerService";

const projectDevelopers = await CustomerControllerService.getCustomers({
  customerType: CustomerType.PROJECT_DEVELOPER,
});
</script>

<template>
  <ProjectDeveloperContractReportingList
    :projectDevelopers="projectDevelopers"
  />
</template>
