import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";
import { reactive, ref } from "vue";

import { type FileEto } from "@/gql/types";
import { FileControllerService } from "@/services/backend/services/FileControllerService";

export const useFileService = defineStore("files", () => {
  const fileLookup = reactive(new Map<string, File>());
  const isLoading = ref<boolean>(false);

  async function fetch(id?: string, fileId?: string | null) {
    if (!id || !fileId) {
      return;
    }

    try {
      add(id, await FileControllerService.getFileMetadata(fileId));
    } catch {
      return;
    }
  }

  function add(id: string | undefined, fileEto: FileEto): void {
    if (!id) {
      return;
    }
    const file = new File([], fileEto.name ?? "file", {
      type: fileEto.contentType ?? "application/octet-stream",
    });

    fileLookup.set(id, file);
  }

  function getById(id: string): File | undefined {
    return fileLookup.get(id);
  }

  async function upload(file: File | undefined): Promise<FileEto | undefined> {
    if (!file) {
      return undefined;
    }

    try {
      isLoading.value = true;
      const fileId: string = uuidv4();

      if (file.size === 0) {
        return undefined;
      }

      const fileEto = await FileControllerService.upload(fileId, file);
      console.debug("File upload successful", fileEto);
      return fileEto;
    } catch (e) {
      console.error("File upload failed", file, e);
      return undefined;
    } finally {
      isLoading.value = false;
    }
  }

  async function download(id: string): Promise<void> {
    if (id.length === 0) {
      return;
    }

    try {
      isLoading.value = true;

      const metadata = await FileControllerService.getFileMetadata(id);
      const file = await FileControllerService.download(id);

      if (!metadata.contentType || !metadata.name) {
        return;
      }

      const a = document.createElement("a");
      a.href = URL.createObjectURL(
        new Blob([file], {
          type: metadata.contentType,
        }),
      );
      a.download = metadata.name;
      a.click();
    } catch (e) {
      return;
    } finally {
      isLoading.value = false;
    }
  }

  function removeById(id: string): void {
    try {
      isLoading.value = true;
      fileLookup.delete(id);
    } catch (e) {
      return;
    } finally {
      isLoading.value = false;
    }
  }

  return {
    isLoading,
    fetch,
    upload,
    download,
    removeById,
    getById,
    add,
  };
});
