<script setup lang="ts">
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";

import AppButton from "@/app/base/button/AppButton.vue";
import LoadingIndicator from "@/app/base/loading/LoadingIndicator.vue";
import { useI18n } from "@/app/base/utils/i18n";
import { AttachmentEditorMode } from "@/app/common/attachments/AttachmentEditorMode";
import EventEditor from "@/app/common/attachments/event/EventEditor.vue";
import EventViewer from "@/app/common/attachments/event/EventViewer.vue";
import { useEventStore } from "@/app/common/attachments/store/EventStore";
import { type Event } from "@/services/backend/models/Event";
import { type EventType } from "@/services/backend/models/EventType";

const { t } = useI18n();
const eventStore = useEventStore();
const showCreateAttachmentEditor = ref<boolean>(false);

const props = defineProps<{
  shown: boolean;
  projectId?: number;
  customerId?: number;
  opportunityId?: number;
  eventType: EventType;
}>();

onMounted(async () => await eventStore.fetch({ ...props }));
const { unusedEvents, events, loading } = storeToRefs(eventStore);

const editedEventId = ref<number>();

async function saveEvent(event: Event) {
  await eventStore.create({
    ...event,
    projectId: props.projectId,
    customerId: props.customerId,
    opportunityId: props.opportunityId,
  });
  showCreateAttachmentEditor.value = false;
  editedEventId.value = undefined;
}
</script>

<template>
  <LoadingIndicator v-if="loading" />
  <div v-else class="attachments-flex-container">
    <VToolbar class="bg-transparent separator-bottom mb-0">
      <AppButton
        icon="mdi-calendar-star"
        highlight
        heavy
        :disabled="unusedEvents.length === 0"
        :label="t('attachment.addEvent')"
        @click="showCreateAttachmentEditor = true"
      />
    </VToolbar>
    <VContainer class="flex">
      <EventEditor
        v-if="showCreateAttachmentEditor"
        :mode="AttachmentEditorMode.CREATE"
        @cancel="showCreateAttachmentEditor = false"
        @save="saveEvent"
      />
      <template v-for="event in events" :key="event.id">
        <EventEditor
          v-if="editedEventId == event.id"
          :mode="AttachmentEditorMode.EDIT"
          :event="event"
          @cancel="editedEventId = undefined"
          @save="saveEvent"
        />
        <EventViewer
          v-else
          :shown="shown"
          :event="event"
          :editBlocked="!editedEventId"
          @edit="editedEventId = event.id"
        />
      </template>
    </VContainer>
  </div>
</template>

<style scoped>
.attachments-flex-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}

.flex {
  gap: 1em;
  display: flex;
  flex-direction: column;
}
</style>
