<script setup lang="ts">
import { computed, ref, unref } from "vue";

import { type MaybeRef } from "@/app/base/utils/MaybeRef";
import KanbanColumnContent from "@/app/common/kanban/KanbanColumnContent.vue";
import KanbanColumnHeading from "@/app/common/kanban/KanbanColumnHeading.vue";

const props = defineProps<{
  items: object[];
  idKey: string;
  columnKey: string;
  columns: {
    value: string;
    title: MaybeRef<string>;
    onClick?: () => void;
  }[];
}>();

const emit = defineEmits<(e: "move", id: number, newStage: string) => void>();

const getColumn = (item: object) =>
  (item as Record<typeof props.columnKey, string>)[props.columnKey];

const itemsPerColumn = computed(() =>
  Object.fromEntries(
    props.columns.map(({ value: stage }) => [
      stage,
      props.items.filter((item) => getColumn(item) === stage),
    ]),
  ),
);

const chosenId = ref<number | null>(null);

const columnTitle = (stage: string, title: string) =>
  `${title} (${itemsPerColumn.value[stage].length})`;
</script>

<template>
  <div class="kanban-board">
    <KanbanColumnHeading
      v-for="{ value: stage, title, onClick } of columns"
      :key="stage"
      :stageLabel="columnTitle(stage, unref(title))"
      @clickLabel="onClick"
    >
    </KanbanColumnHeading>
    <KanbanColumnContent
      v-for="{ value: stage, title } of columns"
      :key="stage"
      :items="itemsPerColumn[stage]"
      :idKey="idKey"
      :stage="stage"
      :stageLabel="unref(title)"
      :chosenId="chosenId"
      @drag="chosenId = $event"
      @drop="chosenId && emit('move', chosenId, stage)"
      @abort="chosenId = null"
    >
      <template #item="{ item }">
        <slot name="item" :item="item" />
      </template>
    </KanbanColumnContent>
  </div>
</template>

<style scoped>
.kanban-board {
  height: 100vh;
  display: grid;
  /*overwrite grid-template-columns with the correct numbers of columns for every board*/
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto 1fr;
  column-gap: 2em;
  row-gap: 1em;
  padding: 0 2em 2em;
  overflow-x: scroll;
}
</style>
