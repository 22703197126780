<script setup lang="ts">
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  type ChartData,
  type ChartOptions,
  Legend,
  LinearScale,
  Title,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { computed } from "vue";
import { Bar } from "vue-chartjs";

import { type BarChartPlugin } from "@/app/reporting/common/ChartJsPlugins";

const props = withDefaults(
  defineProps<{
    subtitle?: string;
    data: ChartData;
    onBarClick?: (datasetIndex: number, index: number) => void;
    noDataText?: string;
    legend?: boolean;
    stacked?: boolean;
    showZeroValues?: boolean;
    plugins?: BarChartPlugin[];
    labelWidth?: number;
  }>(),
  {
    noDataText: "",
    subtitle: undefined,
    onBarClick: () => {
      /** no-op */
    },
    legend: false,
    stacked: false,
    showZeroValues: true,
    plugins: undefined,
    labelWidth: undefined,
  },
);

const CAELI_2 = "#024452";

const LABEL_SIZE = 13;

const chartOptions: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  indexAxis: "y",
  plugins: {
    legend: {
      display: props.legend,
    },
    datalabels: {
      color: CAELI_2,
      anchor: "start",
      clamp: true,
      align: "right",
      font: {
        size: LABEL_SIZE,
      },
      formatter: (value: number) => {
        return props.showZeroValues ? value : value || null;
      },
    },
  },
  scales: {
    x: {
      stacked: props.stacked,
    },
    y: {
      stacked: props.stacked,
      afterSetDimensions: (scale: { maxWidth: number }) => {
        // we use maxWidth together with padded labels for fixed with labels, as there is no fixed width option
        if (props.labelWidth) {
          scale.maxWidth = props.labelWidth;
        }
      },
      ticks: {
        crossAlign: "near",
        font: {
          size: LABEL_SIZE,
        },
        color: CAELI_2,
        stepSize: 20,
      },
    },
  },
  onClick(
    event: { type: string },
    elements: { datasetIndex: number; index: number }[],
  ) {
    if (props.onBarClick && event.type === "click" && elements.length > 0) {
      props.onBarClick(elements[0].datasetIndex, elements[0].index);
    }
  },
};

ChartJS.register(
  Title,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ChartDataLabels,
);

const labelPadding = computed(() => (props.labelWidth ? props.labelWidth : 0));
</script>

<template>
  <div>
    <header class="mt-2">
      <a v-if="subtitle" class="subtitle">{{ subtitle }}</a>
    </header>
    <Bar
      v-if="data.labels && data.labels.length > 0"
      id="my-chart-id"
      :options="chartOptions as any"
      :data="data as any"
      :plugins="plugins"
    />
    <div v-else class="no-data pt-4">{{ props.noDataText }}</div>
  </div>
</template>

<style scoped>
.subtitle {
  padding-left: calc(v-bind(labelPadding) * 1px);
  font-size: 1.17em; /*h3 font size */
}

.no-data {
  padding-left: calc(v-bind(labelPadding) * 1px);
}
</style>
