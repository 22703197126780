<script setup lang="ts">
import { computed, onMounted } from "vue";

import { useSave } from "@/app/base/utils/useSave";

const props = defineProps<{
  size?: "x-small" | "small" | "default" | "large" | "x-large";
  icon?: string;
  iconRotation?: number;
  label?: string;
  tooltip?: string;
  corners?: boolean;
  highlight?: boolean;
  heavy?: boolean;
  border?: boolean;
  disabled?: boolean;
  tooltipLocation?: "top" | "bottom" | "left" | "right" | "start" | "end";
  solid?: boolean;
  href?: string;
  to?: string;

  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  onClick?: () => Promise<unknown> | unknown;
  onSuccess?: () => void;
  onFailure?: () => void;
}>();

const emits = defineEmits<(e: "clickWithEvent", event: Event) => void>();

onMounted(() => {
  if (!props.icon && !props.label) {
    console.warn(
      "AppButton did not receive an icon or text, an empty button will be displayed. See the element $('.app-button-blank-warning')",
    );
  }
});

const [save, saving] = useSave(
  () => props.onClick?.(),
  props.onSuccess,
  props.onFailure,
);

const saveWrapper = (event: Event): void => {
  save();
  emits("clickWithEvent", event);
};

const variant = computed(() => {
  if (props.border) {
    return "outlined";
  } else if (!props.solid) {
    return "text";
  } else {
    return undefined;
  }
});
</script>

<template>
  <VBtn
    :size="size ?? 'default'"
    :aria-label="label || tooltip"
    :rounded="!corners"
    flat
    :variant="variant"
    :color="highlight && !icon ? 'caeli5' : undefined"
    class="app-button"
    :class="{
      'has-icon': icon,
      'text-pre-title': heavy,
      'app-button-blank-warning': !props.icon && !props.label,
    }"
    :disabled="disabled"
    :loading="saving"
    :href="href"
    :to="to"
    target="_blank"
    rel="noopener noreferrer"
    @click="(event: Event) => saveWrapper(event)"
  >
    <!--  slot for custom icon as vue component-->
    <slot name="icon"></slot>
    <VIcon
      v-if="icon"
      :color="highlight ? 'caeli5' : undefined"
      :icon="icon"
      class="large-icon"
      :class="{
        'margin-right-0-25': !!props.label,
        rotate: iconRotation,
      }"
    />
    <slot>{{ label }}</slot>
    <VTooltip
      v-if="$slots.tooltip || tooltip"
      activator="parent"
      :location="tooltipLocation"
    >
      <slot name="tooltip">{{ tooltip }}</slot>
    </VTooltip>
  </VBtn>
</template>

<style scoped>
.app-button {
  min-width: calc(var(--v-btn-height) + 0px);
  border-color: currentColor;
  font-size: 0.75rem;
}

.margin-right-0-25 {
  margin-right: 0.25em;
}

.app-button :deep(.v-btn__content) {
  white-space: initial;
}

.app-button.has-icon :deep(.v-btn__content) {
  text-align: start;
}

.large-icon {
  font-size: 2em;
}

.large-icon.rotate {
  transform: rotate(calc(v-bind(iconRotation) * 1deg));
}
</style>
