/* istanbul ignore file */
/* eslint-disable */

export enum NoteContactType {
  EMAIL = 'EMAIL',
  PERSONAL_CONTACT = 'PERSONAL_CONTACT',
  FAIR = 'FAIR',
  TELEPHONE = 'TELEPHONE',
  VIDEO_CONFERENCE = 'VIDEO_CONFERENCE',
}
