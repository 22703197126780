<script setup lang="ts">
import { computed, ref } from "vue";

import AppButton from "@/app/base/button/AppButton.vue";
import { useI18n } from "@/app/base/utils/i18n";

import { useSidebarCardCount } from "./SidebarCountProvider";

defineProps<{
  emptyMessage?: string;
}>();

const { t } = useI18n();
const count = useSidebarCardCount();

const foldedSize = 3;

const expandable = computed(() => count.value > foldedSize);
const expanded = ref(false);
</script>

<template>
  <div class="sidebar-card-container" :class="{ expanded }">
    <slot />
    <AppButton
      v-if="expandable"
      :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
      color="highlight"
      size="x-small"
      highlight
      :label="expanded ? t('ui.showFewer') : t('ui.showAll')"
      @click="expanded = !expanded"
    />
    <p v-if="count === 0 && emptyMessage" class="text-caption">
      {{ emptyMessage }}
    </p>
  </div>
</template>

<style scoped>
.sidebar-card-container {
  display: flex;
  flex-direction: column;
  padding-top: 1em;
  gap: 1em;
}

.app-button {
  align-self: flex-end;
}

.sidebar-card-container:not(.expanded)
  > :slotted(.sidebar-card:nth-child(n + 4)) {
  display: none;
}
</style>
