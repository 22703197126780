<script setup lang="ts">
import LoadingIndicator from "@/app/base/loading/LoadingIndicator.vue";
import { useI18n } from "@/app/base/utils/i18n";

import ProjectBoardContainer from "./ProjectBoardContainer.vue";

const { t } = useI18n();
</script>

<template>
  <Suspense>
    <ProjectBoardContainer />

    <template #fallback>
      <LoadingIndicator :items="t('projects.project', 2)" />
    </template>
  </Suspense>
</template>
