<script lang="ts" setup>
import { useI18n } from "@/app/base/utils/i18n";
import { getFieldValue } from "@/app/process/field/FieldHelper";
import ReadonlyField from "@/app/process/field/ReadonlyField.vue";
import { type FieldKeyDto, FieldType, type FieldValueDto } from "@/gql/types";

const { t } = useI18n();

const props = defineProps<{
  fieldKey: FieldKeyDto;
  fieldValue: FieldValueDto;
  label?: string;
  clearable?: boolean;
  readonly?: boolean;
}>();

const emit = defineEmits<(e: "update", value: FieldValueDto) => void>();

function emitUpdate(value: boolean | null) {
  emit("update", {
    ...props.fieldValue,
    value: {
      ...props.fieldValue.value,
      valueBoolean: value,
    },
  });
}
</script>

<template>
  <ReadonlyField
    v-if="props.readonly"
    :label="props.label"
    :value="
      getFieldValue(
        props.fieldKey.type ?? FieldType.String,
        props.fieldValue,
        t('boolean.yes'),
        t('boolean.no'),
      )
    "
  />
  <VCheckbox
    v-else
    :modelValue="fieldValue?.value?.valueBoolean"
    :label="props.fieldKey.name ?? ''"
    hideDetails="auto"
    data-testid="boolean-field-checkbox"
    @update:model-value="emitUpdate"
  />
</template>
