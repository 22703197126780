<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useDisplay } from "vuetify";

import DetailPane from "./DetailPane.vue";
import {
  collapsibleRightSidebarMinWidth,
  defaultSidebarWidth,
} from "./sidebarWidth";

const { smAndDown } = useDisplay();

const props = withDefaults(
  defineProps<{
    hasLeftSide?: boolean;
    hasRightSide?: boolean;
    sidebarWidthLeft?: number;
    sidebarWidthRight?: number;
    collapsibleRightSideMinWidth?: number;
    enableRightSideCollapsible?: boolean;
  }>(),
  {
    hasLeftSide: true,
    hasRightSide: true,
    sidebarWidthLeft: defaultSidebarWidth,
    sidebarWidthRight: defaultSidebarWidth,
    collapsibleRightSideMinWidth: collapsibleRightSidebarMinWidth,
    enableRightSideCollapsible: true,
  },
);

const collapseRightSide = computed(() => {
  if (
    !props.enableRightSideCollapsible ||
    !props.collapsibleRightSideMinWidth
  ) {
    return false;
  }
  return windowWidth.value < props.collapsibleRightSideMinWidth;
});

const windowWidth = ref(window.innerWidth);
const onWidthChange = () => {
  windowWidth.value = window.innerWidth;
};
onMounted(() => window.addEventListener("resize", onWidthChange));
onUnmounted(() => window.removeEventListener("resize", onWidthChange));
</script>

<template>
  <DetailPane
    v-if="hasLeftSide"
    location="left"
    hideOverlay
    permanent
    :width="sidebarWidthLeft"
  >
    <slot name="left" />
  </DetailPane>

  <slot />

  <DetailPane
    v-if="hasRightSide"
    class="right"
    location="right"
    hideOverlay
    expandOnHover
    :width="sidebarWidthRight"
    :rail="smAndDown || collapseRightSide"
    permanent
  >
    <slot name="right" />
  </DetailPane>
</template>

<style>
/* This is not scoped because scoping does not work on v-navigation-drawer */
.detail-pane.right.v-navigation-drawer--rail {
  overflow-x: hidden;
}

.detail-pane.right > .v-navigation-drawer__content {
  width: calc((v-bind(defaultSidebarWidth) - 1) * 1px);
  max-width: unset;
}
</style>
