<script lang="ts" setup>
import { useRouter } from "vue-router";

import FloatingActionButton from "@/app/base/button/FloatingActionButton.vue";
import { EditorMode } from "@/app/process/graphviewer/EditorMode";

const props = defineProps<{
  primary?: boolean;
  first?: boolean;
  color?: string;
  rootActivityId: string;
  processId?: string;
  editorMode: EditorMode;
}>();

const router = useRouter();

async function navigateToGraphView() {
  if (props.editorMode === EditorMode.ACTIVITY) {
    await router.push({
      name: "activityGraph",
      params: {
        rootActivityId: props.rootActivityId,
      },
    });
    return;
  }

  await router.push({
    name: "processGraph",
    params: {
      rootActivityId: props.rootActivityId,
      processId: props.processId ?? "undefined",
    },
  });
}
</script>

<template>
  <VTooltip location="start" :text="$t('processes.showGraphButton.title')">
    <template #activator="{ props: activator }">
      <FloatingActionButton
        v-bind="activator"
        icon="mdi-graph-outline"
        primary
        @click="navigateToGraphView"
      />
    </template>
  </VTooltip>
</template>
