<script setup lang="ts">
import { computed, nextTick, ref, toRef, watch } from "vue";

import AppButton from "@/app/base/button/AppButton.vue";
import { useDialog } from "@/app/base/dialog/useDialog";
import { useFontsReady } from "@/app/base/utils/fonts";
import { DateFormat, useI18n } from "@/app/base/utils/i18n";
import { useNoteStore } from "@/app/common/attachments/store/NoteStore";
import { type Note } from "@/services/backend/models/Note";

const { t, d, tEnum } = useI18n();

const { confirm } = useDialog();

const noteStore = useNoteStore();

const props = defineProps<{
  shown: boolean;
  note: Note;
  editBlocked?: boolean;
}>();

const shown = toRef(props, "shown");
const dateLabel = computed(() => {
  if (props.note.lastModificationDate) {
    return t("attachment.lastEdit");
  } else {
    return t("attachment.createdAt");
  }
});

const date = computed(() => {
  return props.note.lastModificationDate ?? props.note.creationDate;
});

const deleteAttachment = async () => {
  if (
    await confirm({
      title: t("attachment.deleteWarningTitle"),
      message: t("ui.deleteWarningMessage"),
      destructive: true,
      confirmMessage: t("ui.delete"),
    })
  ) {
    await noteStore.remove(props.note.id);
  }
};

// The first paragraph, if multiple paragraphs (marked by a double line break)
// are present. This is used to force the “expand” button to be shown.
const firstParagraph = computed(() => {
  if (!props.note.content) {
    return undefined;
  }
  const match = /\n\s*?\n/.exec(props.note.content);
  if (match?.index) {
    return props.note.content.slice(0, match.index) + "…";
  }
  return undefined;
});

const contentElement = ref<HTMLElement | undefined>();
const expanded = ref<boolean>(false);
const expandable = ref<boolean>(true);

// When any fonts have been loaded, recalculate whether the body is expandable
const fontsReady = useFontsReady();
watch(
  () => [
    shown.value,
    contentElement.value,
    expanded.value,
    props.note.content,
    fontsReady.value,
  ],
  async () => {
    const noteEl = contentElement.value;
    if (!noteEl || expanded.value) {
      return;
    }
    await nextTick();
    expandable.value =
      !!firstParagraph.value || noteEl.offsetHeight < noteEl.scrollHeight;
  },
  { immediate: true },
);

const assignedCustomer = noteStore.assignedCustomer(props.note.id);
const assignedContact = noteStore.assignedContact(props.note.id);
const assignedOpportunity = noteStore.assignedOpportunity(props.note.id);
const assignedProject = noteStore.assignedProject(props.note.id);

const contactName = computed(() => {
  if (!assignedContact) {
    return "";
  }
  return `${
    (assignedContact.title
      ? tEnum("contactEntity.title", assignedContact.title) + " "
      : "") + assignedContact.firstName
  } ${assignedContact.lastName}`;
});

function toggleExpand() {
  if (!expandable.value) {
    return;
  }
  expanded.value = !expanded.value;
}
</script>

<template>
  <div>
    <VCard
      class="container border1"
      :elevation="0"
      v-on="expandable ? { click: toggleExpand } : {}"
    >
      <VTooltip openDelay="500" activator="parent" location="bottom">
        <p v-if="date">{{ dateLabel }}: {{ d(date, DateFormat.MEDIUM) }}</p>
        <p v-if="note.creator">
          {{ t("attachment.editor") }}: {{ note.creator }}
        </p>
        <p v-if="assignedOpportunity">
          {{ t("opportunities.opportunity") }}: {{ assignedOpportunity.name }}
        </p>
        <p v-if="assignedProject">
          {{ t("projects.project") }}: {{ assignedProject.name }}
        </p>
        <p v-if="assignedCustomer">
          {{ t("customers.customer") }}: {{ assignedCustomer.name }}
        </p>
        <p v-if="assignedContact">
          {{ t("contacts.contact") }}: {{ contactName }}
        </p>
      </VTooltip>

      <div class="note-container">
        <div class="note-header gradient1">
          <VIcon icon="mdi-note-outline" />
          <div class="text-attachment-title">
            {{ note.creator ?? t("attachment.note") }}
          </div>
          <VSpacer />
          <AppButton
            icon="mdi-pencil"
            :label="t('ui.edit')"
            :disabled="editBlocked"
            @clickWithEvent.stop="noteStore.createOrUpdatePending(props.note)"
          />
          <AppButton
            icon="mdi-delete-outline"
            :label="t('ui.delete')"
            @clickWithEvent.stop="deleteAttachment"
          />
        </div>

        <VDivider />

        <div
          v-if="note.content"
          ref="contentElement"
          :class="
            !expanded && expandable ? 'note-content-collapsed' : 'note-content'
          "
        >
          {{ note.content }}
        </div>
      </div>
    </VCard>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 100%;
}

.v-icon {
  margin-right: 0.5em;
}

.note-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.note-header {
  padding: 0.5em;
  display: flex;
  flex: 1;
  align-items: center;
}

.note-content {
  padding: 0.5rem;
  white-space: pre-line;
}

.note-content-collapsed {
  -webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
  max-height: 3rem;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
