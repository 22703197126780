<script setup lang="ts">
import { useDefaultModel } from "@/app/base/utils/useModel";

import AppCheckbox from "./AppCheckbox.vue";
import { registerRow } from "./RowProvider";

const props = withDefaults(
  defineProps<{
    modelValue?: boolean;
    placeholder?: boolean;

    /** When single is set, the control will look like a radio button */
    single?: boolean;
  }>(),
  {
    modelValue: undefined,
    placeholder: false,
    single: false,
  },
);

const emit = defineEmits<(e: "update:modelValue", value: boolean) => void>();

const model = useDefaultModel(props, "modelValue", false)(emit);

if (!props.placeholder) {
  registerRow();
}
</script>
<template>
  <label class="checkbox-group-row" @click.stop>
    <AppCheckbox
      v-model="model"
      :single="single"
      density="compact"
      :class="{ hidden: placeholder }"
    />
    <slot />
  </label>
</template>

<style scoped>
.checkbox-group-row {
  display: contents;
}

.hidden {
  visibility: hidden;
}

.app-checkbox {
  grid-column: checkbox;
}
</style>
