<script setup lang="ts">
import { computed, ref } from "vue";

const props = defineProps<{
  items: object[];
  idKey: string;
  chosenId: number | null;
  stage: string;
  stageLabel: string;
}>();

const emit = defineEmits<{
  (e: "drag", id: number): void;
  (e: "abort"): void;
  (e: "drop"): void;
}>();

let loggedGetId = false;

const getId = (item: object) => {
  if (!loggedGetId && import.meta.env.VITE_DEV && !(props.idKey in item)) {
    loggedGetId = true;
    console.warn(
      `KanbanBoard called with idKey=${JSON.stringify(props.idKey)},`,
      "missing from",
      item,
    );
  }
  return (item as Record<typeof props.idKey, number>)[props.idKey];
};

const isDragTarget = ref(false);
const dragJustStarted = ref(false);

const dragging = computed(() => props.chosenId !== null);

const setDrag = (e: DragEvent) => {
  if (e.dataTransfer) {
    e.dataTransfer.effectAllowed = "move";
  }
  dragJustStarted.value = true;
  window.setTimeout(() => {
    dragJustStarted.value = false;
  }, 0);
};

const resetDrag = () => {
  dragJustStarted.value = false;
  isDragTarget.value = false;
};
</script>

<template>
  <div
    :class="{
      'drag-target': isDragTarget,
      dragging: dragging && !dragJustStarted,
    }"
  >
    <div
      class="kanban-column gradient1 border1"
      @dragenter.prevent="dragging && (isDragTarget = true)"
      @dragleave.self="dragging && (isDragTarget = false)"
      @dragover.prevent
      @drop="dragging && (resetDrag(), emit('drop'))"
    >
      <VCard
        v-for="item in items"
        :key="getId(item)"
        :draggable="true"
        class="border1 mb-4"
        :class="{
          'drag-source': chosenId === getId(item),
        }"
        elevation="0"
        @dragstart="setDrag($event), emit('drag', getId(item))"
        @dragend="dragging && (resetDrag(), emit('abort'))"
      >
        <slot name="item" :item="item as any" />
      </VCard>
    </div>
  </div>
</template>

<style scoped>
.kanban-column > :last-child {
  margin-bottom: 0 !important;
}
.kanban-column {
  border: 1px solid black;
  min-width: 15em;
  padding: 1em;
  height: 100%;
}

.drag-target > h5 {
  color: rgb(var(--v-theme-caeli5));
}

.drag-target > .kanban-column {
  border-color: rgb(var(--v-theme-caeli5));
  outline: 1px solid rgb(var(--v-theme-caeli5));
}

.v-card {
  cursor: pointer;
}

.v-card :slotted(.v-card-text) {
  font-size: 0.6875rem;
}

.dragging .v-card {
  /* This prevents unwanted dragenter and dragleave events on children */
  pointer-events: none;
}

.v-card.drag-source {
  opacity: 0.5;
}

a.link {
  cursor: pointer;
  text-decoration: underline;
}
</style>
