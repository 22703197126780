<script setup lang="ts">
import { useI18n } from "@/app/base/utils/i18n";
import { ProcessActivityState } from "@/gql/types";

const { tEnum } = useI18n();

const props = defineProps<{
  showAllStates?: boolean;
}>();

const model = defineModel<ProcessActivityState[]>({
  required: true,
});

const items = Object.values(ProcessActivityState).filter((value) => {
  if (props.showAllStates) {
    return true;
  }
  return value !== ProcessActivityState.Done;
});
</script>

<template>
  <VChipGroup
    v-model="model"
    multiple
    filter
    class="text-caeli8 mb-n1"
    selectedClass="text-caeli5"
  >
    <VChip
      v-for="state in items"
      :key="state"
      variant="tonal"
      density="compact"
      size="small"
      :value="state"
    >
      <p>{{ tEnum("processes.activityState", state) }}</p>
      <VTooltip
        activator="parent"
        openDelay="750"
        maxWidth="300"
        :text="tEnum('processes.activityStateTooltips', state)"
        location="bottom"
      />
    </VChip>
  </VChipGroup>
</template>
