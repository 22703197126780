<script setup lang="ts">
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import DetailPanes from "@/app/base/layout/DetailPanes.vue";
import { useI18n } from "@/app/base/utils/i18n";
import { AssignmentType } from "@/app/common/attachments/AssignmentType";
import DocumentationPanel from "@/app/common/attachments/DocumentationPanel.vue";
import EventPanel from "@/app/common/attachments/event/EventPanel.vue";
import UrlPanel from "@/app/common/attachments/url/UrlPanel.vue";
import { stringToTab, Tabs } from "@/app/common/tabs/SinglePageTabs";
import OpportunityAreasListContainer from "@/app/opportunities/single/OpportunityAreasListContainer.vue";
import { EventType } from "@/services/backend/models/EventType";

import OpportunityDetailContainer from "./OpportunityDetailContainer.vue";

defineProps<{ opportunityId: string }>();
const { t } = useI18n();

const router = useRouter();

const tabs = [
  { id: Tabs.ATTACHMENTS, name: t("attachment.attachment", 2) },
  { id: Tabs.EVENTS, name: t("attachment.event", 2) },
  { id: Tabs.AREAS, name: t("entities.area", 2) },
  { id: Tabs.URLS, name: t("attachment.url", 2) },
];
const activeTab = computed<Tabs | undefined>({
  get: () =>
    stringToTab(router.currentRoute.value.hash.replace(/^#/, "")) ??
    Tabs.ATTACHMENTS,
  set: (newRoute: Tabs | undefined) =>
    newRoute && void router.replace({ hash: "#" + newRoute }),
});
const showRightSidebar = ref(true);

const toggleRightSideBar = (show: boolean) => {
  showRightSidebar.value = show;
};
</script>

<template>
  <DetailPanes :hasRightSide="showRightSidebar">
    <template #left>
      <OpportunityDetailContainer :opportunityId="+opportunityId" />
    </template>
    <VTabs :modelValue="activeTab">
      <VTab
        v-for="(tab, i) in tabs"
        :key="i"
        :value="tab.id"
        @click="activeTab = tab.id"
      >
        {{ tab.name }}
      </VTab>
    </VTabs>

    <DocumentationPanel
      v-show="activeTab === Tabs.ATTACHMENTS"
      :shown="activeTab === Tabs.ATTACHMENTS"
      :mailAssignmentTypes="[AssignmentType.CUSTOMER, AssignmentType.CONTACT]"
      :noteAssignmentTypes="[AssignmentType.CUSTOMER, AssignmentType.CONTACT]"
      :opportunityId="+opportunityId"
    />

    <EventPanel
      v-show="activeTab === Tabs.EVENTS"
      :shown="activeTab === Tabs.EVENTS"
      :opportunityId="+opportunityId"
      :eventType="EventType.SALES_EVENT"
    />

    <OpportunityAreasListContainer
      v-show="activeTab === Tabs.AREAS"
      :opportunityId="+opportunityId"
      :onShowSidebar="toggleRightSideBar"
    />

    <UrlPanel
      v-show="activeTab === Tabs.URLS"
      :opportunityId="+opportunityId"
    />

    <template #right>
      <RouterView name="right" />
    </template>
  </DetailPanes>
</template>
