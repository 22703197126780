/* istanbul ignore file */
/* eslint-disable */
import type { Area } from "../models/Area";
import type { AreaStatus } from "../models/AreaStatus";
import type { Opportunity } from "../models/Opportunity";
import type { OpportunityView } from "../models/OpportunityView";
import type { SalesStage } from "../models/SalesStage";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class OpportunityControllerService {
  /**
   * @returns Opportunity OK
   * @throws ApiError
   */
  public static getOpportunity({
    opportunityId,
  }: {
    opportunityId: number;
  }): CancelablePromise<Opportunity> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/pisys/v1/opportunities/{opportunityId}",
      path: {
        opportunityId: opportunityId,
      },
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static updateOpportunity({
    opportunityId,
    requestBody,
  }: {
    opportunityId: number;
    requestBody: Opportunity;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/api/pisys/v1/opportunities/{opportunityId}",
      path: {
        opportunityId: opportunityId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static deleteOpportunity({
    opportunityId,
  }: {
    opportunityId: number;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/api/pisys/v1/opportunities/{opportunityId}",
      path: {
        opportunityId: opportunityId,
      },
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static updateOpportunityStage({
    opportunityId,
    salesStage,
  }: {
    opportunityId: number;
    salesStage: SalesStage;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/api/pisys/v1/opportunities/{opportunityId}/stage/{salesStage}",
      path: {
        opportunityId: opportunityId,
        salesStage: salesStage,
      },
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static updateAreaStatus({
    areaId,
    areaStatus,
  }: {
    areaId: string;
    areaStatus?: AreaStatus;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/api/pisys/v1/opportunities/area-status/{areaId}",
      path: {
        areaId: areaId,
      },
      query: {
        areaStatus: areaStatus,
      },
    });
  }

  /**
   * @returns OpportunityView OK
   * @throws ApiError
   */
  public static getOpportunities({
    customerId,
    notLinkedToCustomer,
    salesStages,
    withInfrawindInfo,
  }: {
    customerId?: number;
    notLinkedToCustomer?: boolean;
    salesStages?: Array<SalesStage>;
    withInfrawindInfo?: boolean;
  }): CancelablePromise<Array<OpportunityView>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/pisys/v1/opportunities",
      query: {
        customerId: customerId,
        notLinkedToCustomer: notLinkedToCustomer,
        salesStages: salesStages,
        withInfrawindInfo: withInfrawindInfo,
      },
    });
  }

  /**
   * @returns Opportunity OK
   * @throws ApiError
   */
  public static createOpportunity({
    requestBody,
  }: {
    requestBody: Opportunity;
  }): CancelablePromise<Opportunity> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/pisys/v1/opportunities",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static linkOpportunityWithCustomers({
    opportunityId,
    customerIds,
  }: {
    opportunityId: number;
    customerIds: Array<number>;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/pisys/v1/opportunities/{opportunityId}/customer-links",
      path: {
        opportunityId: opportunityId,
      },
      query: {
        customerIds: customerIds,
      },
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static addArealinks({
    opportunityId,
    requestBody,
  }: {
    opportunityId: number;
    requestBody: Array<string>;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/pisys/v1/opportunities/{opportunityId}/area-links",
      path: {
        opportunityId: opportunityId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static deleteAreaLinks1({
    opportunityId,
    requestBody,
  }: {
    opportunityId: number;
    requestBody: Array<string>;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/api/pisys/v1/opportunities/{opportunityId}/area-links",
      path: {
        opportunityId: opportunityId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @returns Area OK
   * @throws ApiError
   */
  public static getLinkableAreas1({
    opportunityId,
  }: {
    opportunityId: number;
  }): CancelablePromise<Array<Area>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/pisys/v1/opportunities/{opportunityId}/linkable-areas",
      path: {
        opportunityId: opportunityId,
      },
    });
  }

  /**
   * @returns Opportunity OK
   * @throws ApiError
   */
  public static getOpportunityAreas({
    salesStages,
    landOwnerId,
  }: {
    salesStages?: Array<SalesStage>;
    landOwnerId?: number;
  }): CancelablePromise<Array<Opportunity>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/pisys/v1/opportunities/areas",
      query: {
        salesStages: salesStages,
        landOwnerId: landOwnerId,
      },
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static unlinkOpportunityFromCustomer({
    opportunityId,
    customerId,
  }: {
    opportunityId: number;
    customerId: number;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/api/pisys/v1/opportunities/{opportunityId}/customer-links/{customerId}",
      path: {
        opportunityId: opportunityId,
        customerId: customerId,
      },
    });
  }
}
