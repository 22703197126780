import { QualificationEvent } from "@/services/backend/models/QualificationEvent";
import { QualificationStage } from "@/services/backend/models/QualificationStage";

// based on "20221004_Phasen u. Ereignisse Geschäftspartner.xlsx"

const nonAbortQualificationEvents: QualificationEvent[] = [
  QualificationEvent.INITIAL_MEETING_PASSED,
  QualificationEvent.BROKERAGE_CONTRACT_SENT,
  QualificationEvent.SELF_DISCLOSURE_SENT,
  QualificationEvent.SELF_DISCLOSURE_RECEIVED,
  QualificationEvent.PROFILE_PUBLICATION_CONFIRMED,
  QualificationEvent.BROKERAGE_CONTRACT_SIGNED,
  QualificationEvent.PROFILE_SET_UP,
  QualificationEvent.USER_ACCOUNT_SET_UP,
  QualificationEvent.TR_DATA_REQUESTED,
  QualificationEvent.TR_DATA_AVAILABLE,
  QualificationEvent.CREDIT_CHECK_PASSED,
  QualificationEvent.AUCTION_PARTICIPATION_POSSIBLE,
];
export const qualificationEventsPerStage: Readonly<
  Record<QualificationStage, readonly QualificationEvent[]>
> = {
  [QualificationStage.CONTACT_INITIATION_INITIAL_MEETING]:
    nonAbortQualificationEvents,
  [QualificationStage.ONBOARDING]: nonAbortQualificationEvents,

  [QualificationStage.AUCTION_ELIGIBILITY]: nonAbortQualificationEvents,
  [QualificationStage.ABORT_PD]: [
    QualificationEvent.PROJECT_DEVELOPER_HAS_CANCELED_NO_RESOURCES,
    QualificationEvent.PROJECT_DEVELOPER_HAS_CANCELED_NO_INTEREST,
  ],
  [QualificationStage.ABORT_CAELI]: nonAbortQualificationEvents,
};
