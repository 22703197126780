<script setup lang="ts">
import { useRouter } from "vue-router";

import AppButton from "@/app/base/button/AppButton.vue";
import { useI18n } from "@/app/base/utils/i18n";
import { type Area } from "@/services/backend/models/Area";

const { t } = useI18n();

const router = useRouter();

const props = defineProps<{
  area: Area;
  onUnlinkArea: (area: Area) => Promise<void> | void;
}>();

const openProject = async () => {
  const { href } = router.resolve({
    name: "projectSingle",
    params: { projectId: props.area.linkedPisysObjectId },
  });
  await router.push(href);
};
</script>
<template>
  <form class="assignment-form" @submit.prevent>
    <div class="grid">
      <AppButton
        :disabled="area.linkedPisysObjectId !== undefined"
        icon="mdi-link-variant-remove"
        highlight
        :tooltip="t('area.unlink')"
        tooltipLocation="bottom"
        @click="onUnlinkArea(area)"
      />
      <AppButton
        icon="mdi-wind-turbine"
        :tooltip="t('area.openLinkedProject')"
        tooltipLocation="bottom"
        :disabled="area.linkedPisysObjectId === undefined"
        highlight
        @click="openProject"
      />
    </div>
  </form>
</template>

<style scoped>
form {
  display: flex;
  align-items: center;
}

.grid {
  display: grid;
  grid-template-rows: auto-flow;
  grid-template-columns: 1fr;
  align-items: center;
  gap: 1ex 1ex;
  padding-top: 1ex;
  padding-bottom: 1ex;
  flex: 1;
}
</style>
