<script setup lang="ts">
import { useI18n } from "@/app/base/utils/i18n";

defineProps<{
  gridColumn: number;
  title: string;
  subTitle: string;
  number: number;
  openDetails: () => void;
}>();

const { t } = useI18n();
</script>

<template>
  <div class="container py-4">
    <div class="card gradient1">
      <div class="pt-2 h3">{{ title }}</div>
      <div class="pt-2 number">{{ number }}</div>
      <div class="">{{ subTitle }}</div>
      <div class="h3 details py-2 text-caeli5" @click="openDetails">
        <VIcon size="x-small" icon="mdi-chevron-right" />
        {{ t("reporting.details") }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.card {
  text-align: center;
  /*height: 10em;*/
  width: 15em;
  border: 1px solid rgb(var(--v-theme-caeli8));
  border-radius: 4px;
}
.number {
  font-size: 2em;
}
.h3 {
  font-size: 1.17em; /*h3 font size */
}
.details {
  cursor: pointer;
  transform: translateX(-0.5em);
}

.container {
  display: flex;
  justify-content: center;
  grid-row: card;
  grid-column: v-bind(gridColumn);
  height: max-content;
  width: 100%;
}
</style>
