import { type Ref, ref, watchEffect } from "vue";

import { type ApiError } from "@/services/backend/core/ApiError";
import { CancelablePromise } from "@/services/backend/core/CancelablePromise";

export class BackendService {
  /**
   * Reactively fetches data from the backend service. Should be used in container components for service calls
   * if the rendering does not wait for the response.
   *
   * Automatically cancels the request if the using component is unmounted while the request is running.
   * If an error occurs, rethrows it to be captured by central error handling.
   *
   * @param serviceCall callback function that actually calls the backend service
   * @param errorHandler error handler returning true if the error is handled and false if it should be rethrown
   * @param errorDefaultValue optional default value to return if the error handler returned true
   * @param successCallback optional callback executed after successful service call
   * @return reactive reference containing undefined while loading, then the fetched data
   */
  public static fetchReactively<T>(
    serviceCall: () => CancelablePromise<T>,
    errorHandler?: (e: ApiError) => boolean,
    errorDefaultValue?: T,
    successCallback?: () => void,
  ): Ref<T | undefined> {
    const data = ref<T>();
    return BackendService.setReactively(
      data,
      serviceCall,
      errorHandler,
      errorDefaultValue,
      successCallback,
    );
  }

  /**
   * Reactively fetches data from the backend service and sets it in the given data reference.
   * Should be used in container components for service calls if the rendering does not wait for the response
   * and if the result should be stored in the given Ref, e.g. a Store Ref.
   *
   * Automatically cancels the request if the using component is unmounted while the request is running.
   * If an error occurs, rethrows it to be captured by central error handling.
   *
   * @param data data reference that will be set
   * @param serviceCall callback function that actually calls the backend service
   * @param errorHandler error handler returning true if the error is handled and false if it should be rethrown
   * @param errorDefaultValue optional default value to return if the error handler returned true
   * @param successCallback optional callback executed after successful service call
   * @return reactive reference containing undefined while loading, then the fetched data
   */
  public static setReactively<T>(
    data: Ref<T | undefined>,
    serviceCall: () => CancelablePromise<T>,
    errorHandler?: (e: ApiError) => boolean,
    errorDefaultValue?: T,
    successCallback?: () => void,
  ): Ref<T | undefined> {
    watchEffect(async (onCleanup) => {
      data.value = undefined;
      const cancelablePromise = serviceCall();
      try {
        onCleanup(() => cancelablePromise?.cancel());
        data.value = await cancelablePromise;
        if (successCallback) {
          successCallback();
        }
      } catch (e) {
        if (cancelablePromise.isCancelled) {
          return;
        }
        if (errorHandler?.(e as ApiError)) {
          return errorDefaultValue;
        }
        throw e;
      }
    });
    return data;
  }

  public static cancelPromise(promise: Promise<unknown>) {
    if (promise instanceof CancelablePromise) {
      promise.cancel();
    }
  }
}
