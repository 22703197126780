<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";

import AppButton from "@/app/base/button/AppButton.vue";
import ButtonGroup from "@/app/base/button/ButtonGroup.vue";
import { useDialog } from "@/app/base/dialog/useDialog";
import { formStyles } from "@/app/base/form/formStyles";
import { useI18n } from "@/app/base/utils/i18n";
import { AttachmentEditorMode } from "@/app/common/attachments/AttachmentEditorMode";
import { useEventStore } from "@/app/common/attachments/store/EventStore";
import { UserAuthService } from "@/services/auth/UserAuthService";
import { type AfterSalesEvent } from "@/services/backend/models/AfterSalesEvent";
import { type Event } from "@/services/backend/models/Event";
import { EventType } from "@/services/backend/models/EventType";
import { type QualificationEvent } from "@/services/backend/models/QualificationEvent";
import { type SalesEvent } from "@/services/backend/models/SalesEvent";

const { t } = useI18n();

interface Props {
  event?: Event;
  mode: AttachmentEditorMode;
}

const props = withDefaults(defineProps<Props>(), {
  event: () => {
    return {
      lastModificationDate: new Date().toISOString(),
      creator: UserAuthService.getUserFullName(),
    } as Event;
  },
  unusedEvents: () => [],
});

const emit = defineEmits<{
  (e: "save", event: Event): void;
  (e: "cancel"): void;
}>();

const eventStore = useEventStore();
const { unusedEvents, activeEventType } = storeToRefs(eventStore);
const { confirm } = useDialog();

const editedEvent = ref<Event>({ ...props.event });

const canSave = computed(() => {
  return (
    editedEvent.value.afterSalesEvent ??
    editedEvent.value.salesEvent ??
    editedEvent.value.qualificationEvent
  );
});

const hasBeenEdited = computed(
  () =>
    editedEvent.value.customDescription !== props.event?.customDescription &&
    editedEvent.value.customDescription,
);

const eventType = (event: Event | undefined): string => {
  return eventStore.getEventTypeLabel(
    event?.qualificationEvent?.toString() ??
      event?.salesEvent?.toString() ??
      event?.afterSalesEvent?.toString() ??
      "",
  );
};

const cancelEdit = async () => {
  const confirmed =
    !hasBeenEdited.value ||
    (await confirm({
      title: t("attachment.unsavedChangesTitle"),
      message: t("attachment.unsavedChangesMessage"),
      destructive: true,
      confirmMessage: t("ui.discard"),
    }));
  if (confirmed) {
    emit("cancel");
  }
};

function save() {
  if (!canSave.value) {
    return;
  }
  if (editedEvent.value.id === 0) {
    editedEvent.value.creationDate = new Date().toISOString();
  } else {
    editedEvent.value.lastModificationDate = new Date().toISOString();
  }
  emit("save", editedEvent.value);
}

const eventTypeModel = (
  value: {
    id: AfterSalesEvent | QualificationEvent | SalesEvent;
    label: string;
  } | null,
) => {
  if (value === null) {
    // Handle the null case here, for example just return directly.
    return;
  }
  editedEvent.value.type = activeEventType.value;
  switch (activeEventType.value) {
    case EventType.QUALIFICATION_EVENT:
      editedEvent.value.qualificationEvent = value.id as QualificationEvent;
      break;
    case EventType.SALES_EVENT:
      editedEvent.value.salesEvent = value.id as SalesEvent;
      break;
    case EventType.AFTER_SALES_EVENT:
      editedEvent.value.afterSalesEvent = value.id as AfterSalesEvent;
      break;
    default:
      return;
  }
};
</script>

<template>
  <div>
    <VCard class="gradient1 border1" elevation="0">
      <!--suppress TypeScriptValidateTypes -->
      <VForm @submit.prevent="save">
        <VCardText>
          <div class="d-flex flex-column" style="gap: 8px">
            <div class="d-flex flex-wrap" style="gap: 8px">
              <VTextField
                v-if="mode === AttachmentEditorMode.EDIT"
                :modelValue="eventType(event)"
                readonly
                v-bind="formStyles"
              />
              <template v-else>
                <VSelect
                  v-bind="formStyles"
                  :items="unusedEvents ?? []"
                  itemValue="id"
                  itemTitle="label"
                  :placeholder="t('attachment.eventType')"
                  :disabled="false"
                  returnObject
                  @update:modelValue="eventTypeModel"
                >
                </VSelect>
              </template>
            </div>
            <div>
              <VTextarea
                v-model="editedEvent.customDescription"
                v-bind="formStyles"
                :label="t('attachment.comment')"
                rows="5"
              ></VTextarea>
            </div>
          </div>
        </VCardText>
        <VCardActions>
          <ButtonGroup>
            <AppButton
              :label="t('ui.cancel')"
              border
              corners
              heavy
              @click="cancelEdit"
            />
            <AppButton
              :label="t('ui.save')"
              highlight
              solid
              corners
              heavy
              :disabled="!canSave"
              @click="save"
            />
          </ButtonGroup>
        </VCardActions>
      </VForm>
    </VCard>
  </div>
</template>

<style scoped>
.button-group {
  gap: 1em;
}
</style>
