import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";

import { SalesStage } from "@/services/backend/models/SalesStage";

export const useSalesStages = (stages: string[], source: string) => {
  const isSalesStage = (s: string): s is SalesStage =>
    Object.values<string>(SalesStage).includes(s);

  const invalidStage = ref<string | undefined>(undefined);
  const salesStages = computed(() =>
    stages
      .map((stage) => {
        if (isSalesStage(stage)) {
          return stage;
        } else {
          invalidStage.value = stage;
          return undefined;
        }
      })
      .filter((stage): stage is SalesStage => !!stage),
  );

  const router = useRouter();

  watch(
    invalidStage,
    async () => {
      if (invalidStage.value) {
        console.error(
          `${source} received unknown sales stage ${invalidStage.value}, redirecting to opportunity board`,
        );
        await router.push(router.resolve({ name: "opportunityBoard" }).href);
      }
    },
    { immediate: true },
  );

  return salesStages;
};
