<script lang="ts" setup>
import { v4 as uuidv4 } from "uuid";
import { computed } from "vue";
import { useRouter } from "vue-router";

import FloatingActionButton from "@/app/base/button/FloatingActionButton.vue";
import { useI18n } from "@/app/base/utils/i18n";
import FilteredDataTable from "@/app/process/list/FilteredDataTable.vue";
import { useFieldService } from "@/app/process/service/FieldService";
import { useMessageService } from "@/app/process/service/MessageService";
import { EntityType } from "@/gql/types";

const { t } = useI18n();
const router = useRouter();
const messageService = useMessageService();
const fieldService = useFieldService();

const isLoading = computed(() => messageService.isLoading());
const listRowItems = computed(() => messageService.getListRowItems());
const availableTags = computed(() =>
  fieldService.getTagFieldKeys(EntityType.Message),
);

async function createMessage() {
  const messageId = messageService.createOrUpdate({
    id: uuidv4(),
    title: "Neu",
  });
  await router.push({ name: "messageEdit", params: { messageId } });
}

const mandatoryColumns = [t("message.title")];
const initialColumns = [t("message.creationDate")];
</script>

<template>
  <VCard
    class="bg-grey-lighten-5 pa-10 pt-3 h-screen overflow-auto"
    variant="flat"
    :loading="isLoading"
  >
    <template #loader="{ isActive }">
      <VProgressLinear
        :active="isActive"
        color="caeli5"
        height="4"
        :indeterminate="true"
      />
    </template>
    <FilteredDataTable
      :searchByTextColumns="messageService.listDisplayColumns"
      :exposedColumns="messageService.listDisplayColumns"
      contextKey="messageListView"
      :rowItems="listRowItems"
      :availableTags="availableTags"
      :mandatoryColumns="mandatoryColumns"
      :initialColumns="initialColumns"
      :sortBy="[{ key: t('message.creationDate'), order: 'desc' }]"
    />
  </VCard>

  <VTooltip location="start" :text="$t('message.create')">
    <template #activator="{ props }">
      <FloatingActionButton
        v-bind="props"
        icon="mdi-plus"
        primary
        data-testid="create-message"
        @click="createMessage"
      />
    </template>
  </VTooltip>
</template>
