import { defineStore } from "pinia";

import { useStoreCache } from "@/app/common/store/useStoreCache";
import { type Project } from "@/services/backend/models/Project";
import { ProjectControllerService } from "@/services/backend/services/ProjectControllerService";

export const useProjectStore = defineStore("project", () => {
  const { fetch, update, modify, remove } = useStoreCache<Project>(
    "project",
    (projectId) => ProjectControllerService.getProject({ projectId }),
    (project) =>
      ProjectControllerService.updateProject({
        projectId: project.id,
        requestBody: project,
      }),
    (projectId) => ProjectControllerService.deleteProject({ projectId }),
  );

  return { fetch, update, modify, remove };
});
