<script setup lang="ts">
import { ref } from "vue";

import AppExpandable from "@/app/base/expandable/AppExpandable.vue";

import SidebarHeader from "./SidebarHeader.vue";

defineProps<{ header?: string }>();

const expanded = ref(true);
</script>

<template>
  <div class="sidebar-section">
    <SidebarHeader v-model="expanded" :header="header">
      <slot name="actions" />
    </SidebarHeader>

    <AppExpandable :open="expanded">
      <slot />
    </AppExpandable>
  </div>
</template>
