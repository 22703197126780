<template>
  <div class="kanban-controls">
    <slot />
  </div>
</template>

<style scoped>
.kanban-controls {
  display: flex;
  align-items: center;
  margin: 2em 2em 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid rgb(var(--v-theme-caeli8));
  font-size: 0.875rem;
}

.kanban-controls :slotted(.v-input),
.kanban-controls :slotted(.v-input :deep(.v-field)),
.kanban-controls
  :slotted(.v-input :deep(.v-label:not(.v-field-label--floating))) {
  font-size: inherit;
  --v-input-control-height: 2.25rem;
}
</style>
