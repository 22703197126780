import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import { useI18n } from "@/app/base/utils/i18n";
import { Action, usePromptService } from "@/app/notification/PromptService";
import { type CellContent, type RowItem } from "@/app/process/list/TableTypes";
import { useDocumentStore } from "@/app/process/service/persistence/document/DocumentStore";
import {
  type DocumentDto,
  type DocumentEto,
  type EntityType,
} from "@/gql/types";

export const useDocumentService = defineStore("DocumentService", () => {
  const documentStore = useDocumentStore();
  const promptService = usePromptService();
  const router = useRouter();
  const { t, tEnum } = useI18n();

  const translations = {
    name: t("document.name"),
    description: t("document.description"),
    entityType: t("document.entityType"),
  };

  const isDeleteDialogOpen = ref(false);

  function openDeleteDialog() {
    isDeleteDialogOpen.value = true;
  }

  function closeDeleteDialog() {
    isDeleteDialogOpen.value = false;
  }

  function deleteAndGoToList(id: string) {
    documentStore.deleteById(id).then(
      () => {
        promptService.success(id, Action.DELETE);
        void router.push({
          name: "documentList",
        });
      },
      (reason) => promptService.failure(id, Action.DELETE, reason),
    );
  }

  function getListRowItems(documents?: DocumentEto[]) {
    const results: RowItem[] = [];
    const allDocuments = documents ?? documentStore.getAll();
    for (const document of allDocuments) {
      const rowItem: RowItem = {
        key: document.id,
        cells: {
          ...createNameCell(document.name),
          ...createTypeCell(document.entityType),
        },
        tags: [],
        events: {
          click: () =>
            router.push({
              name: "documentView",
              params: { documentId: document.id },
            }),
        },
      };

      results.push(rowItem);
    }
    return results;
  }

  function createNameCell(name: string): Record<string, CellContent> {
    return {
      [translations.name]: {
        content: name,
        props: { class: "pointer" },
      },
    };
  }

  function createTypeCell(type: EntityType): Record<string, CellContent> {
    return {
      [translations.entityType]: {
        content: tEnum("entityTypes", type),
        props: { class: "pointer" },
      },
    };
  }

  const displayColumns = computed((): string[] => {
    return [translations.name, translations.entityType];
  });

  function createOrUpdate(documentDto: DocumentDto) {
    documentStore.createOrUpdate(documentDto).then(
      () =>
        promptService.success(
          documentDto.id,
          Action.SAVE,
          documentDto.name ?? documentDto.id,
        ),
      (reason) => promptService.failure(documentDto.id, Action.SAVE, reason),
    );
    return documentDto.id;
  }

  return {
    isDeleteDialogOpen,
    isLoading: (id?: string) =>
      id ? documentStore.isLoading(id) : documentStore.isLoadingAll,
    getById: (id: string) => documentStore.getById(id),
    getAll: () => documentStore.getAll(),
    getListRowItems,
    createOrUpdate,
    deleteAndGoToList,
    openDeleteDialog,
    closeDeleteDialog,
    displayColumns,
    markRefetch: (id: string) => documentStore.markRefetch(id),
  };
});
