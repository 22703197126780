<script setup lang="ts">
import dayjs from "dayjs";
import { computed } from "vue";

import TextDisplay from "@/app/base/form/TextDisplay.vue";
import { useI18n } from "@/app/base/utils/i18n";
import { extractDomain } from "@/app/base/utils/string";
import { usePersonService } from "@/app/process/service/PersonService";
import {
  type ActivityOutputDto,
  ActivityOutputType,
  type ProcessOutputDto,
} from "@/gql/types";

const props = defineProps<{
  activityOutput: ActivityOutputDto;
  processOutput: ProcessOutputDto | undefined;
  showName?: boolean;
}>();

const { t } = useI18n();

const personService = usePersonService();

const processOutputValueText = computed((): string => {
  if (!props.processOutput) {
    return "";
  }
  switch (props.activityOutput.type) {
    case ActivityOutputType.Boolean:
      return props.processOutput.value?.valueBoolean
        ? t("boolean.yes")
        : t("boolean.no");
    case ActivityOutputType.Number:
      return props.processOutput.value?.valueNumber?.toString() ?? "";
    case ActivityOutputType.Date:
      return props.processOutput.value?.valueDate
        ? dayjs(props.processOutput.value?.valueDate).format("DD.MM.YYYY")
        : "";
    case ActivityOutputType.DateTime:
      return props.processOutput.value?.valueDateTime
        ? dayjs(props.processOutput.value?.valueDateTime).format(
            "DD.MM.YYYY, hh:mm",
          )
        : "";
    case ActivityOutputType.Person:
      if (!props.processOutput.value?.valueEntityId) {
        return "";
      }
      return (
        personService.getById(props.processOutput.value?.valueEntityId)?.name ??
        ""
      );
    default:
      return props.processOutput.value?.valueString ?? "";
  }
});

const outputNameAndMaybeValue = computed((): string => {
  const outputName = props.activityOutput.name ?? "";
  const valueText = processOutputValueText.value;
  return valueText ? `${outputName} = ${valueText}` : outputName;
});
</script>

<template>
  <div v-if="props.activityOutput.type === ActivityOutputType.String">
    <span v-if="props.showName">{{ props.activityOutput.name ?? "" }}</span>
    <TextDisplay
      :value="props.processOutput?.value?.valueString ?? ''"
      markdown
    />
  </div>
  <div v-else-if="props.activityOutput.type === ActivityOutputType.Url">
    <span v-if="!props.processOutput?.value?.valueString">{{
      props.showName ? props.activityOutput.name : ""
    }}</span>
    <div v-else class="d-flex align-center ga-1">
      <a
        class="toastui-editor-contents url"
        :href="props.processOutput?.value?.valueString"
        target="_blank"
        >{{
          extractDomain(
            props.processOutput?.value?.valueString,
            props.activityOutput.name ??
              t("processes.singleView.outputTypes.URL"),
          )
        }}
      </a>
      <VIcon icon="mdi-open-in-new" size="tiny" />
    </div>
  </div>
  <span v-else>
    {{ props.showName ? outputNameAndMaybeValue : processOutputValueText }}
  </span>
</template>

<style scoped>
.url {
  color: rgb(var(--v-theme-caeli-link));
  text-wrap: nowrap;
}
</style>
