import { computed, type ComputedRef, type Ref, unref } from "vue";

import { useI18n } from "@/app/base/utils/i18n";
import { type MaybeRef } from "@/app/base/utils/MaybeRef";

type Filter<T> = (value: T) => boolean;

/** Split the search into non-empty words by whitespaces */
export function splitWords(search: string, locale?: string) {
  return search
    .toLocaleLowerCase(locale)
    .split(" ")
    .map((entry) => entry.trim())
    .filter(Boolean);
}

export function testSearch(
  searchWords: string[],
  properties: readonly (string | undefined)[],
  locale?: string,
): boolean {
  return searchWords.every((word) =>
    properties.some((property) =>
      property?.toLocaleLowerCase(locale).includes(word),
    ),
  );
}

export function searchFilter<T>(
  search: Ref<string | undefined>,
  getters: (value: T) => readonly (string | undefined)[],
): Filter<T> {
  const { locale } = useI18n();

  const searchWords = computed(() =>
    search.value ? splitWords(search.value, locale.value) : [],
  );

  return (value: T) =>
    testSearch(searchWords.value, getters(value), locale.value);
}

export function emptyOrIncludes<T>(filterList: T[], value: T) {
  return filterList.length === 0 || filterList.includes(value);
}

export function useFilter<T>(
  list: MaybeRef<readonly T[]>,
  ...filters: Filter<T>[]
): ComputedRef<T[]>;
export function useFilter<T>(
  list: MaybeRef<readonly T[] | undefined>,
  ...filters: Filter<T>[]
): ComputedRef<T[] | undefined>;
export function useFilter<T>(
  list: MaybeRef<readonly T[] | undefined>,
  ...filters: Filter<T>[]
): ComputedRef<T[] | undefined> {
  return computed(() =>
    unref(list)?.filter((value) => filters.every((filter) => filter(value))),
  );
}
