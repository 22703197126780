<script setup lang="ts">
import LoadingIndicator from "@/app/base/loading/LoadingIndicator.vue";
import { useI18n } from "@/app/base/utils/i18n";
import { useSalesStages } from "@/app/opportunities/list/useSalesStages";

import OpportunityAreaListContainer from "./OpportunityAreaListContainer.vue";

const { t } = useI18n();

const props = defineProps<{
  salesStages?: string[];
  landOwnerId?: string;
}>();

const salesStages = props.salesStages
  ? useSalesStages(props.salesStages, "OpportunityAreaListPage")
  : undefined;
</script>

<template>
  <Suspense>
    <OpportunityAreaListContainer
      :salesStages="salesStages"
      :landOwnerId="landOwnerId ? +landOwnerId : undefined"
    />

    <template #fallback>
      <LoadingIndicator :items="t('entities.area', 2)" />
    </template>
  </Suspense>
</template>
