<script setup lang="ts">
import AppDialog from "@/app/base/dialog/AppDialog.vue";
import { useI18n } from "@/app/base/utils/i18n";

defineProps<{ error: Error }>();

const emit = defineEmits<(e: "dismiss") => void>();

const { t } = useI18n();
</script>

<template>
  <AppDialog
    noCancel
    :title="t('error.title')"
    :confirmMessage="t('error.dismiss')"
    @confirm="emit('dismiss')"
  >
    <div>{{ t("error.intro") }}</div>

    <pre class="error text-monospace">{{ error }}</pre>
  </AppDialog>
</template>

<style scoped>
pre.error {
  white-space: pre-line;
}
</style>
