<script setup lang="ts">
import { useRouter } from "vue-router";

import LoadingIndicator from "@/app/base/loading/LoadingIndicator.vue";
import { useI18n } from "@/app/base/utils/i18n";
import { useProjectStore } from "@/app/projects/store/ProjectStore";
import { UserAuthService } from "@/services/auth/UserAuthService";
import { type Project } from "@/services/backend/models/Project";

import ProjectDetail from "./ProjectDetail.vue";

const props = defineProps<{ projectId: number }>();

const router = useRouter();

const { t } = useI18n();

const projectStore = useProjectStore();

const project = projectStore.fetch(props.projectId);

const onRemove = async (projectId: number) => {
  await projectStore.remove(projectId);

  const { href } = router.resolve({
    name: "projectBoard",
  });
  await router.push(href);
};

const onSave = async (updatedProject: Project) => {
  updatedProject.lastModificationDate = new Date().toISOString();
  await projectStore.update(updatedProject);
};
</script>

<template>
  <ProjectDetail
    v-if="project"
    :creating="false"
    :project="project"
    :onSave="onSave"
    :onRemove="onRemove"
    :userMail="UserAuthService.getUserMail()"
  />
  <LoadingIndicator v-else :items="t('projects.project')" />
</template>
