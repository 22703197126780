/* istanbul ignore file */
/* eslint-disable */
import type { Event } from '../models/Event';
import type { EventType } from '../models/EventType';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EventControllerService {

  /**
   * @returns Event OK
   * @throws ApiError
   */
  public static getEvent({
eventId,
}: {
eventId: number,
}): CancelablePromise<Event> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/pisys/v1/attachments/events/{eventId}',
      path: {
        'eventId': eventId,
      },
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static updateEvent({
eventId,
requestBody,
}: {
eventId: number,
requestBody: Event,
}): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/pisys/v1/attachments/events/{eventId}',
      path: {
        'eventId': eventId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static deleteEvent({
eventId,
}: {
eventId: number,
}): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/pisys/v1/attachments/events/{eventId}',
      path: {
        'eventId': eventId,
      },
    });
  }

  /**
   * @returns Event OK
   * @throws ApiError
   */
  public static getEvents({
projectId,
customerId,
opportunityId,
type,
}: {
projectId?: number,
customerId?: number,
opportunityId?: number,
type?: EventType,
}): CancelablePromise<Array<Event>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/pisys/v1/attachments/events',
      query: {
        'projectId': projectId,
        'customerId': customerId,
        'opportunityId': opportunityId,
        'type': type,
      },
    });
  }

  /**
   * @returns Event OK
   * @throws ApiError
   */
  public static createEvent({
requestBody,
}: {
requestBody: Event,
}): CancelablePromise<Event> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/pisys/v1/attachments/events',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns Event OK
   * @throws ApiError
   */
  public static getUnlinkedEvents(): CancelablePromise<Array<Event>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/pisys/v1/attachments/events/unlinked',
    });
  }

}
