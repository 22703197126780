<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import { enrichAggregatedAreaInfo } from "@/app/common/area/area";
import { UserAuthService } from "@/services/auth/UserAuthService";
import { type AfterSalesStage } from "@/services/backend/models/AfterSalesStage";
import { type Project } from "@/services/backend/models/Project";
import { type ProjectView } from "@/services/backend/models/ProjectView";
import { ProjectControllerService } from "@/services/backend/services/ProjectControllerService";

import ProjectBoard from "./ProjectBoard.vue";

const router = useRouter();
const projects = ref(await ProjectControllerService.getProjects({}));
const externalDataLoaded = ref(false);

onMounted(async () => {
  const projectsWithExternalInfos = await ProjectControllerService.getProjects({
    withInfrawindInfo: true,
  });
  externalDataLoaded.value = true;
  // enrich the existing data instead of replacing it because there may already be changes (stage, new entities)
  projects.value.forEach((p) => {
    const projectWithExternalInfos = projectsWithExternalInfos.find(
      (pe) => pe.id === p.id,
    );
    if (projectWithExternalInfos) {
      enrichAggregatedAreaInfo(p, projectWithExternalInfos);
    }
  });
});

const openProject = (project: ProjectView) => {
  const { href } = router.resolve({
    name: "projectSingle",
    params: { projectId: project.id },
  });
  return router.push(href);
};

const moveProject = async (project: ProjectView, stage: AfterSalesStage) => {
  await ProjectControllerService.updateProjectStage({
    projectId: project.id,
    afterSalesStage: stage,
  });

  const index = projects.value.findIndex((p) => p.id === project.id);
  projects.value.splice(index, 1, { ...project, stage });
};

async function addProject(project: Project) {
  const { id } = await ProjectControllerService.createProject({
    requestBody: project,
  });
  const { href } = router.resolve({
    name: "projectSingle",
    params: { projectId: id },
  });
  await router.push(href);
}

const search = ref("");
const statusFilter = ref<string[]>([]);
</script>

<template>
  <ProjectBoard
    v-model:search="search"
    v-model:statusFilter="statusFilter"
    :projects="projects"
    :onAdd="addProject"
    :userMail="UserAuthService.getUserMail()"
    @open="openProject"
    @move="moveProject"
  />
</template>
