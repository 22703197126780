<script setup lang="ts">
import { useRouter } from "vue-router";

import LoadingIndicator from "@/app/base/loading/LoadingIndicator.vue";
import { useI18n } from "@/app/base/utils/i18n";
import { useOpportunityStore } from "@/app/opportunities/store/OpportunityStore";
import { UserAuthService } from "@/services/auth/UserAuthService";
import { type Opportunity } from "@/services/backend/models/Opportunity";

import OpportunityDetail from "./OpportunityDetail.vue";

const props = defineProps<{ opportunityId: number }>();

const router = useRouter();

const { t } = useI18n();

const opportunityStore = useOpportunityStore();

const opportunity = opportunityStore.fetch(props.opportunityId);

const onRemove = async (opportunityId: number) => {
  await opportunityStore.remove(opportunityId);

  const { href } = router.resolve({
    name: "opportunityBoard",
  });
  await router.push(href);
};

const onSave = async (updatedOpp: Opportunity) => {
  updatedOpp.lastModificationDate = new Date().toISOString();
  await opportunityStore.update(updatedOpp);
};
</script>

<template>
  <OpportunityDetail
    v-if="opportunity"
    :creating="false"
    :opportunity="opportunity"
    :onSave="onSave"
    :onRemove="onRemove"
    :userMail="UserAuthService.getUserMail()"
  />
  <LoadingIndicator v-else :items="t('opportunities.opportunityDetails')" />
</template>
