<script setup lang="ts">
import { computed, shallowRef, watch } from "vue";
import { useRouter } from "vue-router";
import { VBtn, VIcon } from "vuetify/components";

import { useI18n } from "@/app/base/utils/i18n";
import FilteredDataTable from "@/app/process/list/FilteredDataTable.vue";
import { type CellContent, type RowItem } from "@/app/process/list/TableTypes";
import ProcessOutputValue from "@/app/process/output/ProcessOutputValue.vue";
import { useActivityService } from "@/app/process/service/ActivityService";
import { useFieldService } from "@/app/process/service/FieldService";
import { useProcessService } from "@/app/process/service/ProcessService";
import { iconifyType } from "@/app/process/utils";
import {
  type ActivityOutputDto,
  EntityType,
  type ProcessOutputDto,
} from "@/gql/types";

const props = defineProps<{
  outputs: ActivityOutputDto[];
  contextKey: string;
  height?: string;
  processId?: string;
  processOutputs?: ProcessOutputDto[];
  canRemove?: boolean;
  showTemplate?: boolean;
  showType?: boolean;
}>();

const emits = defineEmits<(event: "remove", outputId: string) => void>();

const { t, tEnum } = useI18n();
const router = useRouter();

const activityService = useActivityService();
const processService = useProcessService();
const fieldService = useFieldService();

const activityTitle = t("processes.activity");
const outputTitle = t("processes.output");
const valueTitle = t("processes.value");
const typeTitle = t("processes.singleView.outputTypeLabel");
const deleteTitle = "";

const exposedColumns = computed(() => {
  const result = [activityTitle, outputTitle, valueTitle, typeTitle];
  if (props.canRemove) {
    result.push(deleteTitle);
  }
  return result;
});

const mandatoryColumns = computed(() => {
  const result = [activityTitle, outputTitle];
  if (props.canRemove) {
    result.push(deleteTitle);
  }
  return result;
});

const rowItems = shallowRef<RowItem[]>([]);

watch(
  [() => props.outputs, () => props.processOutputs],
  () => {
    rowItems.value = createRowItems();
  },
  { immediate: true },
);

function createRowItems(): RowItem[] {
  return props.outputs.map((activityOutput) => {
    const processOutput = props.processOutputs?.find(
      (output) => output.activityOutputId === activityOutput.id,
    );

    const cells: Record<string, CellContent> = {
      [activityTitle]: createActivityCell(activityOutput),
      [outputTitle]: createOutputCell(activityOutput),
      [valueTitle]: createValueCell(activityOutput, processOutput),
      [typeTitle]: createTypeCell(activityOutput),
    };

    if (props.canRemove) {
      cells[deleteTitle] = createDeleteCell(activityOutput);
    }

    const tags = fieldService.getActiveTagNames(
      activityOutput.activityId ?? "undefined",
    );

    return {
      key: activityOutput.id,
      cells,
      tags,
    };
  });
}

function createActivityCell(activityOutput: ActivityOutputDto) {
  if (!activityOutput.activityId) {
    console.error("Activity output without activity ID", activityOutput);
    return { content: "" };
  }
  const processActivity = props.processId
    ? processService.getProcessActivityByTemplate(
        props.processId,
        activityOutput.activityId,
      )
    : undefined;
  return {
    content:
      activityService.getActivity(activityOutput.activityId)?.name ?? undefined,
    props: {
      class: processActivity ? "pointer" : undefined,
    },
    events: processActivity
      ? {
          click: () =>
            router.push({
              name: "processActivity",
              params: {
                processId: processActivity?.processId,
                processActivityId: processActivity?.id,
              },
            }),
        }
      : undefined,
  };
}

function createValueCell(
  activityOutput: ActivityOutputDto,
  processOutput: ProcessOutputDto | undefined,
) {
  return {
    component: ProcessOutputValue,
    props: {
      activityOutput,
      processOutput,
    },
  };
}

function createDeleteCell(activityOutput: ActivityOutputDto) {
  return {
    component: VBtn,
    props: {
      variant: "plain",
      size: "tiny",
      color: "error",
      icon: "mdi-delete",
    },
    events: {
      click: () => emits("remove", activityOutput.id),
    },
  };
}

function createTypeCell(activityOutput: ActivityOutputDto) {
  return {
    content: activityOutput.type
      ? tEnum("processes.singleView.outputTypes", activityOutput.type)
      : "",
    component: VIcon,
    props: {
      icon: activityOutput.type ? iconifyType(activityOutput.type) : "",
    },
  };
}

function createOutputCell(activityOutput: ActivityOutputDto) {
  return {
    content: activityOutput.name ?? undefined,
  };
}
</script>

<template>
  <FilteredDataTable
    :contextKey="props.contextKey"
    :exposedColumns="exposedColumns"
    :rowItems="rowItems"
    :mandatoryColumns="mandatoryColumns"
    :initialColumns="exposedColumns"
    :searchByTextColumns="[activityTitle, outputTitle]"
    :availableTags="fieldService.getTagFieldKeys(EntityType.Activity)"
    :height="props.height"
  />
</template>
