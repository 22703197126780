<script setup lang="ts">
import { useStorage } from "@vueuse/core";
import {
  type Component,
  computed,
  onMounted,
  shallowRef,
  type StyleValue,
  watch,
} from "vue";

import { deduplicate } from "@/app/base/utils/array";
import { DateFormat, useI18n } from "@/app/base/utils/i18n";
import { ContentType, type RowItem } from "@/app/process/list/TableTypes";
import UniversalFilterInput from "@/app/process/list/UniversalFilterInput.vue";
import { useProcessUIStore } from "@/app/process/service/ProcessUIStore";
import { StorageKeys } from "@/config";
import { type FieldKeyDto } from "@/gql/types";

const { d } = useI18n();

const uiStore = useProcessUIStore();

const props = defineProps<{
  contextKey: string;
  rowItems: RowItem[];
  availableTags: FieldKeyDto[];
  exposedColumns?: string[];
  mandatoryColumns?: string[];
  initialColumns?: string[];
  searchByTextColumns?: string[];
  hideSearchBar?: boolean;
  height?: string;
  disableColumnToggle?: boolean;
  grabFocusOnKeydown?: boolean;
  noDataText?: string;
  sortBy?: { key: string; order?: boolean | "asc" | "desc" }[];
  mustSort?: boolean;
}>();

const activePage = useStorage(
  StorageKeys.app.activeTablePage.key,
  { [props.contextKey]: 1 },
  StorageKeys.app.activeTablePage.storage,
  { deep: true, initOnMounted: true },
);

function initializeVisibleColumns() {
  const visibleColumns =
    filterParameters.value.visibleColumns ??
    props.initialColumns ??
    props.mandatoryColumns ??
    props.exposedColumns ??
    [];

  uiStore.updateFilterParameters(props.contextKey, {
    visibleColumns: deduplicate(
      visibleColumns.concat(props.mandatoryColumns ?? []),
    ),
  });
}

onMounted(() => {
  initializeVisibleColumns();
});

// Required when the route stays the same, but the contextKey changes (e.g. when switching between process templates)
// as in that case no onMounted is triggered -> otherwise the columns are not reinitialized
watch(
  [
    () => props.contextKey,
    () => props.exposedColumns,
    () => props.mandatoryColumns,
  ],
  () => {
    initializeVisibleColumns();
  },
);

function getAllColumns() {
  return props.rowItems.length
    ? deduplicate(props.rowItems.flatMap((item) => Object.keys(item.cells)))
    : props.exposedColumns ?? [];
}

function hideColumn(column: string | null) {
  uiStore.updateFilterParameters(props.contextKey, {
    visibleColumns: filterParameters.value.visibleColumns?.filter(
      (name) => name !== column,
    ),
  });
}

function showColumn(column: string) {
  uiStore.updateFilterParameters(props.contextKey, {
    visibleColumns: (props.exposedColumns ?? getAllColumns()).filter(
      (name) =>
        name === column ||
        filterParameters.value.visibleColumns?.includes(name),
    ),
  });
}

function toggleColumn(column: string) {
  if (filterParameters.value.visibleColumns?.includes(column)) {
    hideColumn(column);
  } else {
    showColumn(column);
  }
}

const optionalColumns = computed(() => {
  if (props.disableColumnToggle) {
    return [];
  }
  const baseSet = props.exposedColumns ?? getAllColumns();
  const mandatoryColumns = props.mandatoryColumns ?? [];

  return baseSet.filter((column) => !mandatoryColumns.includes(column));
});

const filterParameters = computed(() =>
  uiStore.getFilterParameters(props.contextKey),
);

const filteredRowItems = computed(() => {
  return props.rowItems.filter(
    (rowItem) =>
      matchesTagFilters(rowItem) &&
      matchesNamedFilters(rowItem) &&
      matchesStringSearchTerms(rowItem),
  );
});

const filteredItems = computed(() =>
  filteredRowItems.value.map((rowItem) => {
    const item: {
      [key: string]:
        | string
        | number
        | Record<string, unknown>
        | Component
        | StyleValue
        | undefined;
      __key: string;
      __class?: string | Record<string, unknown>;
      __style?: StyleValue;
      __events?: string | Record<string, unknown>;
    } = {
      __key: rowItem.key,
      __class: rowItem.class,
      __style: rowItem.style,
      __events: rowItem.events,
    };
    for (const column of Object.keys(rowItem.cells)) {
      const cellContent = rowItem.cells[column];
      item[column] = cellContent.content ?? cellContent.sortValue;
      item[column + "_component"] = shallowRef(cellContent.component);
      item[column + "_props"] = cellContent.props;
      item[column + "_events"] = cellContent.events;
      item[column + "_type"] = cellContent.type;
    }
    return item;
  }),
);

function matchesTagFilters(rowItem: RowItem) {
  return filterParameters.value.tagFilters.every((tag) =>
    rowItem.tags.includes(tag),
  );
}

function matchesNamedFilters(rowItem: RowItem) {
  const possibleValuesPerColumn = new Map<string, string[]>();
  filterParameters.value.valueFieldFilters.forEach((fieldComparison) => {
    const values = possibleValuesPerColumn.get(fieldComparison.left) ?? [];
    values.push(fieldComparison.right);
    possibleValuesPerColumn.set(fieldComparison.left, values);
  });

  for (const columnName of possibleValuesPerColumn.keys()) {
    const possibleValues = possibleValuesPerColumn.get(columnName) ?? [];

    if (Array.isArray(rowItem.cells[columnName]?.content)) {
      const cellContent = rowItem.cells[columnName]?.content;
      if (!cellContent.some((item) => possibleValues.includes(item))) {
        return false;
      }
    } else {
      return possibleValues.includes(rowItem.cells[columnName]?.content ?? "");
    }
  }
  return true;
}

function matchesStringSearchTerms(rowItem: RowItem) {
  const searchByTextColumns = filterParameters.value.visibleColumns ??
    props.searchByTextColumns ??
    props.exposedColumns ?? [...contentsLookup.value.keys()];
  return filterParameters.value.searchTerms.every(
    (term) =>
      typeof term !== "string" ||
      searchByTextColumns.some((column) => {
        const content = rowItem.cells[column]?.content;

        if (!content) {
          return false;
        }

        if (Array.isArray(content)) {
          return content.some((item) =>
            item.toLowerCase().includes(term.toLowerCase()),
          );
        } else {
          return content.toLowerCase().includes(term.toLowerCase());
        }
      }),
  );
}

const contentsLookup = computed(() => {
  const uniqueValues = new Map<string, Set<string>>();

  for (const rowItem of props.rowItems) {
    for (const column of Object.keys(rowItem.cells)) {
      const cellContent = rowItem.cells[column];
      const results = uniqueValues.get(column) ?? new Set<string>();
      if (!cellContent.content) {
        continue;
      }

      if (Array.isArray(cellContent.content)) {
        const items = cellContent.content;
        items.forEach((item) => results.add(item));
      } else {
        results.add(cellContent.content ?? "");
      }

      uniqueValues.set(column, results);
    }
  }

  const plainResults = new Map<string, string[]>();
  for (const [column, uniqueColumnValues] of uniqueValues) {
    plainResults.set(column, [...uniqueColumnValues]);
  }
  return plainResults;
});
</script>

<template>
  <div class="d-flex flex-column ga-1">
    <div class="d-flex justify-end align-center w-100">
      <UniversalFilterInput
        :contextKey="props.contextKey"
        :fieldNames="[...contentsLookup.keys()]"
        :fieldValues="contentsLookup"
        :availableTags="availableTags"
        :grabFocusOnKeydown="grabFocusOnKeydown"
      />
    </div>

    <slot name="header">
      <div class="d-flex flex-row align-center">
        <slot name="header-left"> </slot>
        <div style="margin-left: auto">
          <slot name="header-right"></slot>
        </div>
      </div>
    </slot>

    <VCard variant="flat" class="bg-transparent rounded-0 border">
      <VDataTable
        class="universal-table"
        density="compact"
        :headers="
          filterParameters.visibleColumns?.map((title) => {
            return {
              title,
              key: title,
            };
          })
        "
        :items="filteredItems"
        itemsPerPageText=""
        itemsPerPage="25"
        :page="activePage[contextKey] ?? 1"
        pageText="{0}-{1} von {2}"
        :height="props.height"
        :fixedHeader="true"
        :no-data-text="props.noDataText ?? $t('ui.noData')"
        :sortBy="props.sortBy"
        :mustSort="props.mustSort"
        @update:page="activePage[contextKey] = $event"
      >
        <template #headers="{ columns, isSorted, getSortIcon, toggleSort }">
          <tr>
            <template v-for="column in columns" :key="column.key">
              <th>
                <div
                  class="d-flex mr-2 pointer"
                  :data-testid="'filtered-data-table-header-' + column.title"
                  @click="() => toggleSort(column)"
                >
                  {{ column.title }}
                  <template v-if="isSorted(column)">
                    <VIcon :icon="getSortIcon(column)" />
                  </template>
                  <template v-else>
                    <VIcon icon="mdi-arrow-up" class="hover-icon" />
                  </template>
                </div>
              </th>
            </template>
            <th
              v-if="!props.disableColumnToggle"
              class="pl-1 pr-2 text-right"
              style="width: 1rem"
            >
              <VBtn
                size="tiny"
                class="pa-1"
                color="caeli6"
                variant="plain"
                data-testid="filtered-data-table-column-selection-button"
              >
                <VIcon icon="mdi-table-column-plus-after" />
                <VMenu activator="parent" :closeOnContentClick="false">
                  <VList>
                    <template v-for="column in optionalColumns" :key="column">
                      <VListItem
                        density="compact"
                        :value="column"
                        @click="() => toggleColumn(column)"
                      >
                        <VCheckboxBtn
                          density="compact"
                          color="caeli6"
                          style="font-size: 0.8rem"
                          :modelValue="
                            filterParameters.visibleColumns?.includes(column)
                          "
                          :data-testid="`filtered-data-table-column-${column}`"
                          @update:modelValue="(_) => toggleColumn(column)"
                        >
                          <template #label
                            ><span class="text-caeli2 text-body-2">{{
                              column
                            }}</span></template
                          >
                        </VCheckboxBtn>
                      </VListItem>
                    </template>
                  </VList>
                </VMenu>
              </VBtn>
            </th>
          </tr>
        </template>
        <template #item="{ item }">
          <tr
            :class="item['__class']"
            :style="item['__style']"
            data-testid="filtered-data-table-row"
            v-on="item['__events'] ?? {}"
          >
            <td
              v-for="column in filterParameters.visibleColumns"
              :key="`${item['__key']}-${column}`"
            >
              <Component
                :is="item[column + '_component']"
                v-if="item[column + '_component']"
                v-bind="item[column + '_props'] as Object"
                v-on="item[column + '_events'] ?? {}"
              />
              <span
                v-else-if="item[column + '_type'] === ContentType.DATE"
                v-bind="item[column + '_props'] as Object"
                v-on="item[column + '_events'] ?? {}"
                >{{ d(item[column] as string, DateFormat.MEDIUM) ?? "" }}</span
              >
              <span
                v-else
                v-bind="item[column + '_props'] as Object"
                v-on="item[column + '_events'] ?? {}"
                >{{ item[column] ?? "" }}</span
              >
            </td>
          </tr>
        </template>
      </VDataTable>
    </VCard>
  </div>
</template>

<style>
.universal-table td {
  font-size: 13px;
}

.universal-table th {
  font-weight: bold !important;
  font-size: 13px;
}

.universal-table tr.released > td:first-child,
.universal-table tr.has-released > td:first-child {
  border-left: 2px solid rgb(var(--v-theme-caeli5));
}
</style>

<style scoped>
.hover-icon {
  color: transparent;
}

:hover > .hover-icon {
  color: #bbb;
}
</style>
