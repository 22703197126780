<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";

import { useI18n } from "@/app/base/utils/i18n";
import FilteredDataTable from "@/app/process/list/FilteredDataTable.vue";
import { createFieldCells } from "@/app/process/list/FilteredDataTableUtil";
import { type CellContent, type RowItem } from "@/app/process/list/TableTypes";
import ProvidedOutputsDisplay from "@/app/process/output/ProvidedOutputsDisplay.vue";
import { useActivityService } from "@/app/process/service/ActivityService";
import { useFieldService } from "@/app/process/service/FieldService";
import { useProcessService } from "@/app/process/service/ProcessService";
import { compareByName } from "@/app/process/utils";
import { EntityType, type ProcessEto } from "@/gql/types";

const fieldService = useFieldService();
const activityService = useActivityService();
const processService = useProcessService();

const { t } = useI18n();
const router = useRouter();

const i18n = {
  processTitle: t("processes.process"),
  processTemplateTitle: t("processes.processTemplate"),
  yes: t("boolean.yes"),
  no: t("boolean.no"),
  progressTitle: t("processes.output", 2),
};

const rowItems = computed((): RowItem[] => {
  const processes = processService.getProcesses().sort(compareByName);

  return processes.map((process) => {
    const cells: Record<string, CellContent> = {
      ...createTitleCell(process),
      ...createTemplateCell(process),
      ...createProgressCell(process),
      ...createFieldCells(process.startActivityId),
      ...createFieldCells(process.id),
    };
    const tags = processService
      .getActivities(process.id)
      .flatMap((activity) => fieldService.getActiveTagNames(activity.id));
    return {
      key: process.id,
      cells,
      tags,
    };
  });
});

function createTitleCell(process: ProcessEto): Record<string, CellContent> {
  return {
    [i18n.processTitle]: {
      content: process.name ?? "",
      props: { class: "pointer" },
      events: {
        click: () =>
          router.push({ name: "process", params: { processId: process.id } }),
      },
    },
  };
}

function createTemplateCell(process: ProcessEto): Record<string, CellContent> {
  return {
    [i18n.processTemplateTitle]: {
      content:
        activityService.getActivity(process.startActivityId ?? "undefined")
          ?.name ?? "",
    },
  };
}

function createProgressCell(process: ProcessEto): Record<string, CellContent> {
  return {
    [i18n.progressTitle]: {
      component: ProvidedOutputsDisplay,
      props: {
        outputPresentCount: process.status?.outputPresentCount ?? 0,
        outputAllCount: process.status?.outputAllCount ?? 0,
      },
      sortValue: getProgressSortValue(process),
    },
  };
}

function getProgressSortValue(process: ProcessEto) {
  const outputPresentCount = process.status?.outputPresentCount ?? 0;
  const outputAllCount = process.status?.outputAllCount ?? 0;
  if (outputPresentCount === outputAllCount) {
    return Number.MAX_SAFE_INTEGER - outputPresentCount;
  } else {
    return outputPresentCount ?? 0;
  }
}
</script>

<template>
  <FilteredDataTable
    contextKey="processOverview"
    :rowItems="rowItems"
    :availableTags="fieldService.getTagFieldKeys(EntityType.Process)"
    :mandatoryColumns="[i18n.processTitle]"
    :initialColumns="[i18n.processTitle, i18n.processTemplateTitle]"
  />
</template>
