<script setup lang="ts">
import { computed } from "vue";

import { useEnumOptions } from "@/app/base/utils/i18n";
import AreaTileInfo from "@/app/common/area/AreaTileInfo.vue";
import { type WindStats } from "@/app/common/area/WindStats";
import KanbanBoard from "@/app/common/kanban/KanbanBoard.vue";
import { AfterSalesStage } from "@/services/backend/models/AfterSalesStage";
import { MarketingType } from "@/services/backend/models/MarketingType";
import { type ProjectView } from "@/services/backend/models/ProjectView";

const props = defineProps<{
  projects: ProjectView[];
}>();

const emit = defineEmits<{
  (e: "move", project: ProjectView, newStage: AfterSalesStage): void;
  (e: "open", project: ProjectView): void;
}>();

const getProject = (id: number) =>
  props.projects.find((project) => project.id === id);

const stages = useEnumOptions("projects.stage", AfterSalesStage);
const marketingTypes = useEnumOptions("projects.marketingType", MarketingType);

const move = (id: number, newStage: string) => {
  const project = getProject(id);
  if (project) {
    emit("move", project, newStage as AfterSalesStage);
  }
};
const cssGridTemplateRows = computed(() => stages.value.length);

const toWindStats = (projectView: ProjectView): WindStats => {
  return {
    name: projectView.name,
    area: projectView.areaHaDevelopableSum,
    capacity: projectView.totalPowerMwSum,
  };
};
</script>

<template>
  <KanbanBoard
    class="project-kanban"
    :items="projects"
    columnKey="stage"
    idKey="id"
    :columns="stages"
    @move="move"
  >
    <template #item="{ item: project }: { item: ProjectView }">
      <VCardText @click="emit('open', project)">
        <span
          v-if="project.marketingType"
          class="float-right mt-n4 mr-n4 px-1 text-blue-grey-lighten-1 rounded-bs text-no-wrap"
        >
          {{
            (marketingTypes.find((it) => it.value === project.marketingType)
              ?.title || " ")[0]
          }}
        </span>
        <AreaTileInfo :area="toWindStats(project)" />
      </VCardText>
    </template>
  </KanbanBoard>
</template>

<style scoped>
.project-kanban {
  grid-template-columns: repeat(v-bind(cssGridTemplateRows), 1fr);
}
</style>
