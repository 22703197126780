/* istanbul ignore file */
/* eslint-disable */

export enum QualificationStage {
  CONTACT_INITIATION_INITIAL_MEETING = 'CONTACT_INITIATION_INITIAL_MEETING',
  ONBOARDING = 'ONBOARDING',
  AUCTION_ELIGIBILITY = 'AUCTION_ELIGIBILITY',
  ABORT_PD = 'ABORT_PD',
  ABORT_CAELI = 'ABORT_CAELI',
}
