/* istanbul ignore file */
/* eslint-disable */

export enum QualificationEvent {
  INITIAL_MEETING_PASSED = 'INITIAL_MEETING_PASSED',
  BROKERAGE_CONTRACT_SENT = 'BROKERAGE_CONTRACT_SENT',
  SELF_DISCLOSURE_SENT = 'SELF_DISCLOSURE_SENT',
  SELF_DISCLOSURE_RECEIVED = 'SELF_DISCLOSURE_RECEIVED',
  PROFILE_PUBLICATION_CONFIRMED = 'PROFILE_PUBLICATION_CONFIRMED',
  BROKERAGE_CONTRACT_SIGNED = 'BROKERAGE_CONTRACT_SIGNED',
  PROFILE_SET_UP = 'PROFILE_SET_UP',
  USER_ACCOUNT_SET_UP = 'USER_ACCOUNT_SET_UP',
  TR_DATA_REQUESTED = 'TR_DATA_REQUESTED',
  TR_DATA_AVAILABLE = 'TR_DATA_AVAILABLE',
  CREDIT_CHECK_PASSED = 'CREDIT_CHECK_PASSED',
  AUCTION_PARTICIPATION_POSSIBLE = 'AUCTION_PARTICIPATION_POSSIBLE',
  PROJECT_DEVELOPER_HAS_CANCELED_NO_INTEREST = 'PROJECT_DEVELOPER_HAS_CANCELED_NO_INTEREST',
  PROJECT_DEVELOPER_HAS_CANCELED_NO_RESOURCES = 'PROJECT_DEVELOPER_HAS_CANCELED_NO_RESOURCES',
}
