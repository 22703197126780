<script setup lang="ts">
import { storeToRefs } from "pinia";
import { ref, withDefaults } from "vue";

import AppButton from "@/app/base/button/AppButton.vue";
import { formStyles } from "@/app/base/form/formStyles";
import { useValidators } from "@/app/base/form/validators";
import { useUrlStore } from "@/app/common/attachments/store/UrlStore";
import { type Url } from "@/services/backend/models/Url";

interface Props {
  url?: Url;
}

const props = withDefaults(defineProps<Props>(), {
  url: () => {
    return {
      id: -1,
      name: "",
      address: "",
      creationDate: new Date().toISOString(),
      lastModificationDate: new Date().toISOString(),
    };
  },
});

const editedUrl = ref<Url>({ ...props.url });
const urlStore = useUrlStore();
const { isCreateMode, editedUrlId } = storeToRefs(urlStore);
const { required, webUrl, isWebUrl } = useValidators();

async function createOrEdit() {
  if (editedUrlId.value === editedUrl.value.id) {
    await urlStore.update(editedUrl.value);
  } else {
    await urlStore.create(editedUrl.value);
  }
}
async function handleEnterKey() {
  if (canSave()) {
    await createOrEdit();
  }
}

function cancel() {
  isCreateMode.value = false;
  editedUrlId.value = undefined;
}

function canSave(): boolean {
  return (
    editedUrl.value.address.length > 0 &&
    editedUrl.value.name.length > 0 &&
    isWebUrl(editedUrl.value.address) &&
    (props.url.name !== editedUrl.value.name ||
      props.url.address !== editedUrl.value.address)
  );
}
</script>

<template>
  <VCard :elevation="0" class="container gradient1 border1">
    <div class="text-field-container">
      <VTextField
        v-bind="formStyles"
        v-model="editedUrl.name"
        :rules="[required]"
        label="Name"
      />
      <VTextField
        v-bind="formStyles"
        v-model="editedUrl.address"
        :rules="[required, webUrl]"
        label="URL"
        @keydown.enter="handleEnterKey"
      />
    </div>

    <AppButton icon="mdi-cancel" @clickWithEvent.prevent="cancel" />
    <AppButton
      icon="mdi-content-save"
      :disabled="!canSave()"
      @clickWithEvent.prevent="createOrEdit"
    />
  </VCard>
</template>

<style scoped>
.text-field-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 0.5em;
}
.container {
  display: flex;
  padding: 0.5em;
  flex-direction: row;
  align-items: center;
  flex: 1;
  min-width: 100%;
}
</style>
