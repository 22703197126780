import { computed, type ComputedRef, reactive, type Ref, ref } from "vue";

type SetView<T> = [
  array: Readonly<ComputedRef<T[]>>,
  getBooleanModel: (value: T) => Ref<boolean>,
  isEmpty: ComputedRef<boolean>,
  clear: () => void,
];

/**
 * A data structure containing zero or more unique elements, controllable by a
 * ref for each possible value.
 */
export function useSet<T>(): SetView<T> {
  const selected = reactive(new Set<T>()) as Set<T>;

  const array = computed(() => [...selected]);

  const getBooleanModel = (value: T) =>
    computed<boolean>({
      get: () => selected.has(value),
      set: (enable) => (enable ? selected.add(value) : selected.delete(value)),
    });

  const isEmpty = computed(() => selected.size === 0);

  const clear = () => selected.clear();

  return [array, getBooleanModel, isEmpty, clear];
}

/** A data structure containing zero or one elements, with the same interface as
 * useSet.
 *
 * The existing value is overwritten when a new value is added.
 */
export function useSingleSet<T>(): SetView<T> {
  const selected = ref<T>();

  const array = computed<T[]>(() =>
    selected.value === undefined ? [] : [selected.value],
  );

  const getBooleanModel = (value: T) =>
    computed<boolean>({
      get: () => selected.value === value,
      set: (enable) => (selected.value = enable ? value : undefined),
    });

  const isEmpty = computed(() => selected.value === undefined);

  const clear = () => (selected.value = undefined);

  return [array, getBooleanModel, isEmpty, clear];
}
