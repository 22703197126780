<script setup lang="ts">
import { computed } from "vue";

import LoadingIndicator from "@/app/base/loading/LoadingIndicator.vue";
import { useI18n } from "@/app/base/utils/i18n";

import { FunnelColumn, useChildFunnels } from "./FunnelProvider";

withDefaults(
  defineProps<{
    value: string;
    sharedTitle?: string;
    gridRows?: string;
  }>(),
  {
    sharedTitle: undefined,
    gridRows:
      "[shared-title] max-content [card] max-content [title] max-content [funnel] 26.5em",
  },
);

const { t } = useI18n();

const columns = useChildFunnels();

const columnWidth = (column: FunnelColumn) => {
  switch (column) {
    case FunnelColumn.FUNNEL:
      return "2fr";
    case FunnelColumn.LABEL:
      return "1fr";
    default:
      return "0fr";
  }
};

// These are calculated from the nested columns, depending on which columns are
// grouped. Funnel columns are twice as wide as label columns
// See FunnelProvider for the logic for fetching these widths
const gridTemplateColumns = computed(() =>
  columns.value.map((column) => columnWidth(column)).join(" "),
);
</script>

<template>
  <VWindowItem :value="value" :eager="true">
    <Suspense>
      <div class="funnels-container">
        <h2 v-if="sharedTitle" class="shared-title">{{ sharedTitle }}</h2>
        <div class="funnels">
          <slot />
        </div>
      </div>
      <template #fallback>
        <LoadingIndicator :items="t('reporting.reporting')" />
      </template>
    </Suspense>
  </VWindowItem>
</template>

<style scoped>
.v-window-item {
  height: 100%;
  max-width: var(--total-width);
  margin: auto;
}

.funnels-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  gap: 1em;
}

.funnels {
  --funnel-carousel-gap-x: 2em;

  display: grid;
  grid-template-rows: v-bind(gridRows);
  grid-template-columns: v-bind(gridTemplateColumns);
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 0 var(--funnel-carousel-gap-x);
  overflow: auto;
}

.shared-title {
  text-align: center;
}
</style>
