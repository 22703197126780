<script setup lang="ts">
import { useRouter } from "vue-router";

import { UserAuthService } from "@/services/auth/UserAuthService";
import { type Opportunity } from "@/services/backend/models/Opportunity";
import { type SalesStage } from "@/services/backend/models/SalesStage";
import { OpportunityControllerService } from "@/services/backend/services/OpportunityControllerService";

import OpportunityList from "./OpportunityList.vue";

const props = defineProps<{ salesStages: SalesStage[] }>();

const router = useRouter();

const opportunities = await OpportunityControllerService.getOpportunities({
  salesStages: props.salesStages,
  withInfrawindInfo: true,
});

async function addOpportunity(opportunity: Opportunity) {
  opportunity.creationDate = new Date().toISOString();
  const { id } = await OpportunityControllerService.createOpportunity({
    requestBody: opportunity,
  });

  await router.push(
    router.resolve({
      name: "opportunitySingle",
      params: { opportunityId: id },
    }).href,
  );
}
</script>

<template>
  <OpportunityList
    :opportunities="opportunities"
    :salesStages="salesStages"
    :onAdd="addOpportunity"
    :userMail="UserAuthService.getUserMail()"
  />
</template>
