import {
  createRouter,
  createWebHistory,
  type RouteLocationNormalized,
  type RouteRecordRaw,
  type RouterHistory,
} from "vue-router";

import { type TranslationPath } from "@/app/base/utils/i18n";
import OpportunityCustomerWidgetContainer from "@/app/customer/widget/OpportunityCustomerWidgetContainer.vue";
import ProjectCustomerWidgetContainer from "@/app/customer/widget/ProjectCustomerWidgetContainer.vue";
import GrafanaPage from "@/app/dashboard/GrafanaPage.vue";
import FieldKeyOverviewPage from "@/app/FieldKeyOverviewPage.vue";
import ErrorPage from "@/app/notification/ErrorPage.vue";
import OpportunityAreaListPage from "@/app/opportunities/area-list/OpportunityAreaListPage.vue";
import OpportunityBoardPage from "@/app/opportunities/board/OpportunityBoardPage.vue";
import OpportunityListPage from "@/app/opportunities/list/OpportunityListPage.vue";
import OpportunitySinglePage from "@/app/opportunities/single/OpportunitySinglePage.vue";
import GraphViewerPage from "@/app/process/graphviewer/GraphViewerPage.vue";
import DocumentListView from "@/app/process/list/DocumentListView.vue";
import MessageListView from "@/app/process/list/MessageListView.vue";
import PersonListView from "@/app/process/list/PersonListView.vue";
import ProcessActivityListView from "@/app/process/list/ProcessActivityListView.vue";
import ProcessListView from "@/app/process/list/ProcessListView.vue";
import ProcessTemplateActivityListView from "@/app/process/list/ProcessTemplateActivityListView.vue";
import ProcessTemplateListView from "@/app/process/list/ProcessTemplateListView.vue";
import ActivitySingleView from "@/app/process/single/ActivitySingleView.vue";
import DocumentSingleEditView from "@/app/process/single/DocumentSingleEditView.vue";
import DocumentView from "@/app/process/single/DocumentView.vue";
import MessageSingleEditView from "@/app/process/single/MessageSingleEditView.vue";
import MessageView from "@/app/process/single/MessageView.vue";
import PersonSingleEditView from "@/app/process/single/PersonSingleEditView.vue";
import PersonView from "@/app/process/single/PersonView.vue";
import ProcessActivitySingleView from "@/app/process/single/ProcessActivitySingleView.vue";
import ProcessSingleView from "@/app/process/single/ProcessSingleView.vue";
import ProjectBoardPage from "@/app/projects/board/ProjectBoardPage.vue";
import ProjectSinglePage from "@/app/projects/single/ProjectSinglePage.vue";
import ReportingPage from "@/app/reporting/ReportingPage.vue";
import { CustomerControllerService } from "@/services/backend/services/CustomerControllerService";

import { Sequence } from "./combineProps";

declare module "vue-router" {
  interface RouteMeta {
    /**
     * Whether to show the back button (if defaultBackTarget or internal history exists,
     * and linkTranslationKey is given for the target page),
     */
    showBackButton?: boolean;
    /** The `name` of a route to always go back to regardless of the internal history */
    hardBackTarget?: string;
    /** The `name` of the route to go back to if no history is available internally */
    defaultBackTarget?: string;
    /** Translation key for back button to this page */
    linkTranslationKey?: TranslationPath;
    /** Does the user have to be logged into a valid account */
    requiresAuth?: boolean;
  }
}

async function redirectToPersonPage(to: RouteLocationNormalized) {
  const customer = await CustomerControllerService.getCustomer({
    customerId: +to.params.customerId,
  });

  if (!customer) {
    return { name: "dashboard" };
  }

  return {
    name: "personView",
    params: { personId: customer?.personId },
  };
}

export const routes: RouteRecordRaw[] = [
  {
    name: "dashboard",
    path: "/",
    component: GrafanaPage,
    meta: {
      showBackButton: true,
      // no default back target - only show if there is a history
      linkTranslationKey: "linkTranslationKey.dashboard",
    },
  },
  {
    name: "landOwnerSingle",
    path: "/land-owners/:customerId",
    component: { render: () => null },
    beforeEnter: redirectToPersonPage,
  },
  {
    name: "projectDeveloperSingle",
    path: "/project-developers/:customerId",
    component: { render: () => null },
    beforeEnter: redirectToPersonPage,
  },
  {
    name: "otherCustomersSingle",
    path: "/other-customers/:customerId",
    component: { render: () => null },
    beforeEnter: redirectToPersonPage,
  },
  {
    name: "projectBoard",
    path: "/planning-projects",
    component: ProjectBoardPage,
    meta: {
      showBackButton: true,
      // no default back target - only show if there is a history
      linkTranslationKey: "linkTranslationKey.projectOverview",
    },
  },
  {
    name: "projectSingle",
    path: "/planning-projects/:projectId",
    props: {
      default: (route) => ({
        projectId: route.params.projectId,
      }),
    },
    components: {
      default: ProjectSinglePage,
    },
    children: [
      {
        name: "projectSingleSidebar",
        path: "",
        props: true,
        components: {
          right: Sequence(ProjectCustomerWidgetContainer),
        },
      },
    ],
    meta: {
      showBackButton: true,
      defaultBackTarget: "projectBoard",
      linkTranslationKey: "linkTranslationKey.projectDetails",
    },
  },
  {
    name: "opportunityBoard",
    path: "/opportunities",
    component: OpportunityBoardPage,
    meta: {
      showBackButton: true,
      // no default back target - only show if there is a history
      linkTranslationKey: "linkTranslationKey.opportunityOverview",
    },
  },
  {
    name: "opportunityList",
    path: "/opportunities/stage/:salesStages+",
    component: OpportunityListPage,
    props: true,
    meta: {
      showBackButton: true,
      defaultBackTarget: "opportunityBoard",
      linkTranslationKey: "linkTranslationKey.opportunityList",
    },
  },
  {
    name: "areaStageList",
    path: "/areas/stage/:salesStages+",
    component: OpportunityAreaListPage,
    props: true,
    meta: {
      showBackButton: true,
      defaultBackTarget: "opportunityBoard",
      linkTranslationKey: "linkTranslationKey.opportunityList",
    },
  },
  {
    name: "areaLandOwnerList",
    path: "/areas/landOwner/:landOwnerId",
    component: OpportunityAreaListPage,
    props: true,
    meta: {
      showBackButton: true,
      defaultBackTarget: "reporting",
      linkTranslationKey: "reporting.reporting",
    },
  },
  {
    name: "opportunitySingle",
    path: "/opportunities/:opportunityId",
    props: {
      default: (route) => ({
        opportunityId: route.params.opportunityId,
      }),
    },
    components: {
      default: OpportunitySinglePage,
    },
    children: [
      {
        name: "opportunitySingleSidebar",
        path: "",
        props: true,
        components: {
          right: Sequence(OpportunityCustomerWidgetContainer),
        },
      },
    ],
    meta: {
      showBackButton: true,
      defaultBackTarget: "opportunityBoard",
      linkTranslationKey: "opportunities.opportunityDetails",
    },
  },
  {
    name: "reporting",
    path: "/reporting",
    component: ReportingPage,
    meta: {
      showBackButton: true,
      // no default back target - only show if there is a history
      linkTranslationKey: "reporting.reporting",
    },
  },
  {
    name: "activity",
    path: "/process/template/:activityId",
    component: ActivitySingleView,
    props: true,
    meta: {
      showBackButton: true,
    },
  },
  {
    name: "customActivity",
    path: "/process/:processId/activity/custom/:activityId",
    component: ActivitySingleView,
    props: true,
    meta: {
      showBackButton: true,
    },
  },
  {
    name: "processTemplate",
    path: "/process/template/list/:rootActivityId",
    component: ProcessTemplateActivityListView,
    props: true,
    meta: {
      linkTranslationKey: "linkTranslationKey.processTemplateList",
      showBackButton: true,
      hardBackTarget: "processTemplateList",
    },
  },
  {
    name: "processTemplateList",
    path: "/process/template/list",
    component: ProcessTemplateListView,
    meta: {
      linkTranslationKey: "linkTranslationKey.processTemplateList",
      showBackButton: true,
      hardBackTarget: "processList",
    },
  },
  {
    name: "processList",
    path: "/process/list",
    component: ProcessListView,
    meta: {
      linkTranslationKey: "linkTranslationKey.processList",
      showBackButton: true,
      hardBackTarget: "dashboard",
    },
  },
  {
    name: "processActivityList",
    path: "/process/activity/list",
    component: ProcessActivityListView,
    meta: {
      linkTranslationKey: "linkTranslationKey.processActivityList",
      showBackButton: true,
    },
  },
  {
    name: "process",
    path: "/process/:processId",
    component: ProcessSingleView,
    props: true,
    meta: {
      showBackButton: true,
    },
  },
  {
    name: "processActivity",
    path: "/process/:processId/activity/:processActivityId",
    component: ProcessActivitySingleView,
    props: true,
    meta: {
      showBackButton: true,
    },
  },
  {
    name: "activityGraph",
    path: "/process/template/:rootActivityId/graph/",
    component: GraphViewerPage,
    props: true,
    meta: {
      showBackButton: true,
    },
  },
  {
    name: "processGraph",
    path: "/process/template/:rootActivityId/graph/:processId",
    component: GraphViewerPage,
    props: true,
    meta: {
      showBackButton: true,
    },
  },
  {
    name: "personList",
    path: "/person/list",
    component: PersonListView,
    props: true,
    meta: {
      showBackButton: true,
    },
  },
  {
    name: "personEdit",
    path: "/person/:personId/edit",
    component: PersonSingleEditView,
    props: true,
    meta: {
      showBackButton: true,
    },
  },
  {
    name: "personView",
    path: "/person/:personId",
    component: PersonView,
    props: true,
    meta: {
      showBackButton: true,
      hardBackTarget: "personList",
    },
  },
  {
    name: "documentList",
    path: "/document/list",
    component: DocumentListView,
    props: true,
    meta: {
      showBackButton: true,
    },
  },
  {
    name: "documentEdit",
    path: "/document/:documentId/edit",
    component: DocumentSingleEditView,
    props: true,
    meta: {
      showBackButton: true,
    },
  },
  {
    name: "documentView",
    path: "/document/:documentId",
    component: DocumentView,
    props: true,
    meta: {
      showBackButton: true,
      hardBackTarget: "documentList",
    },
  },
  {
    name: "messageList",
    path: "/message/list",
    component: MessageListView,
    props: true,
    meta: {
      showBackButton: true,
    },
  },
  {
    name: "messageEdit",
    path: "/message/:messageId/edit",
    component: MessageSingleEditView,
    props: true,
    meta: {
      showBackButton: true,
    },
  },
  {
    name: "messageView",
    path: "/message/:messageId",
    component: MessageView,
    props: true,
    meta: {
      showBackButton: true,
      hardBackTarget: "messageList",
    },
  },
  {
    name: "fieldKeyOverview",
    path: "/fieldKeyOverview",
    component: FieldKeyOverviewPage,
    meta: {
      showBackButton: true,
      hardBackTarget: "dashboard",
    },
  },
  {
    name: "error",
    path: "/error",
    component: ErrorPage,
  },
  //   Fallback route, must be the last route :)
  {
    path: "/:pathMatch(.*)*",
    redirect: (to) => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return {
        path: "/error",
        query: {
          error: 404,
          invalidPath: to.fullPath,
        },
      };
    },
  },
];

const router = (history?: RouterHistory) =>
  createRouter({
    history: history ?? createWebHistory(import.meta.env.BASE_URL),
    routes,
  });

export default router;
