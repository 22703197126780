<script setup lang="ts">
import { ref, watch } from "vue";

import { useDefaultModel } from "@/app/base/utils/useModel";

const props = defineProps<{
  modelValue?: string;
  arrowWidth: number;
}>();

const emit = defineEmits<(e: "update:modelValue", value: string) => void>();

const model = useDefaultModel(props, "modelValue", "")(emit);

const fullscreenContainer = ref<HTMLDivElement>();

const fullScreen = ref(false);

watch(fullScreen, async () => {
  if (fullScreen.value) {
    await fullscreenContainer.value?.requestFullscreen();
  } else {
    if (document.fullscreenElement) {
      await document.exitFullscreen();
    }
  }
});

function updateFullScreen() {
  if (document.fullscreenElement === fullscreenContainer.value) {
    fullScreen.value = true;
  } else {
    fullScreen.value = false;
  }
}

const canFullscreen = document.fullscreenEnabled;
</script>

<template>
  <div
    ref="fullscreenContainer"
    class="fullscreen-container"
    @fullscreenchange="updateFullScreen"
  >
    <VCarousel v-model="model" hideDelimiters showArrows height="100%">
      <template #prev="{ props: { onClick } }">
        <div class="arrow">
          <VBtn variant="text" icon="mdi-chevron-left" @click="onClick" />
        </div>
      </template>
      <template #next="{ props: { onClick } }">
        <div class="arrow">
          <VBtn
            v-if="canFullscreen"
            variant="text"
            class="top-icon"
            :icon="fullScreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen'"
            @click="fullScreen = !fullScreen"
          />
          <VBtn variant="text" icon="mdi-chevron-right" @click="onClick" />
        </div>
      </template>

      <slot />
    </VCarousel>
  </div>
</template>

<style scoped>
.arrow {
  width: calc(v-bind(arrowWidth) * 1px);
  align-self: stretch;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: white;
  position: relative;
}

.v-carousel :deep(.v-window__controls) {
  padding: 0;
}

.top-icon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  bottom: calc(100% - v-bind(arrowWidth) * 1px);
}

.fullscreen-container {
  display: contents;
  background-color: white;
}

:slotted(.v-carousel-item) {
  padding: calc(v-bind(arrowWidth) * 1px);
}
</style>
