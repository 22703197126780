/* istanbul ignore file */
/* eslint-disable */
import type { Url } from "../models/Url";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class UrlControllerService {
  /**
   * @returns Url OK
   * @throws ApiError
   */
  public static getOne({ urlId }: { urlId: number }): CancelablePromise<Url> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/pisys/v1/attachments/url/{urlId}",
      path: {
        urlId: urlId,
      },
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static update({
    urlId,
    requestBody,
  }: {
    urlId: number;
    requestBody: Url;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/api/pisys/v1/attachments/url/{urlId}",
      path: {
        urlId: urlId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static delete({ urlId }: { urlId: number }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/api/pisys/v1/attachments/url/{urlId}",
      path: {
        urlId: urlId,
      },
    });
  }

  /**
   * @returns Url OK
   * @throws ApiError
   */
  public static getAll({
    projectId,
    customerId,
    opportunityId,
  }: {
    projectId?: number;
    customerId?: number;
    opportunityId?: number;
  }): CancelablePromise<Array<Url>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/pisys/v1/attachments/url",
      query: {
        projectId: projectId,
        customerId: customerId,
        opportunityId: opportunityId,
      },
    });
  }

  /**
   * @returns Url OK
   * @throws ApiError
   */
  public static create({
    requestBody,
  }: {
    requestBody: Url;
  }): CancelablePromise<Url> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/pisys/v1/attachments/url",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @returns Url OK
   * @throws ApiError
   */
  public static getUnlinked(): CancelablePromise<Array<Url>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/pisys/v1/attachments/url/unlinked",
    });
  }
}
