import { defineStore } from "pinia";

import { useStoreCache } from "@/app/common/store/useStoreCache";
import { type Customer } from "@/services/backend/models/Customer";
import { CustomerControllerService } from "@/services/backend/services/CustomerControllerService";

export const useCustomerStore = defineStore("customer", () => {
  const { fetch, update, remove } = useStoreCache<Customer>(
    "customer",
    (customerId) => CustomerControllerService.getCustomer({ customerId }),
    (customer) =>
      CustomerControllerService.updateCustomer({
        customerId: customer.id,
        requestBody: customer,
      }),
    (customerId) => CustomerControllerService.deleteCustomer({ customerId }),
  );

  return { fetch, update, remove };
});
