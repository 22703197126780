<script lang="ts" setup>
import { computed } from "vue";

import AppDialog from "@/app/base/dialog/AppDialog.vue";
import { useI18n } from "@/app/base/utils/i18n";
import { Action, usePromptService } from "@/app/notification/PromptService";
import { useActivityService } from "@/app/process/service/ActivityService";
import { useProcessService } from "@/app/process/service/ProcessService";
import {
  PROCESS_DIALOG,
  useProcessUIStore,
} from "@/app/process/service/ProcessUIStore";
import { type ActivityDto } from "@/gql/types";

const { t } = useI18n();

const activityService = useActivityService();
const processService = useProcessService();
const processUiStore = useProcessUIStore();
const promptService = usePromptService();

const emits = defineEmits<(event: "beforeDelete", ids: string[]) => void>();

const dialogOpen = computed(() =>
  processUiStore.isDialogOpen(PROCESS_DIALOG.DELETE_ACTIVITY),
);

function deleteActivity() {
  const ids = processUiStore.dialogActivityList.map((it) => it.id);
  emits("beforeDelete", ids);

  deleteNextActivity(processUiStore.dialogActivityList);
  processUiStore.closeDialog(PROCESS_DIALOG.DELETE_ACTIVITY);
}

function deleteNextActivity(remainingActivities: ActivityDto[]) {
  if (remainingActivities.length === 0) {
    return;
  }
  const activityToDelete = remainingActivities[0];

  // if the activity to be deleted is a custom activity we also need to delete the processActivity of it
  if (activityToDelete.custom) {
    const processActivityToDelete = processService.getProcessActivityByTemplate(
      processUiStore.dialogProcessId,
      activityToDelete.id,
    );
    if (processActivityToDelete) {
      processService
        .deleteProcessActivity(processActivityToDelete.id)
        .catch((reason) =>
          promptService.failure(
            processActivityToDelete.id,
            Action.DELETE,
            reason,
          ),
        );
    }
  }
  activityService
    .deleteActivity(remainingActivities[0].id)
    .then(() => {
      deleteNextActivity(remainingActivities.slice(1));
    })
    .catch((reason) =>
      promptService.failure(remainingActivities[0].id, Action.DELETE, reason),
    );
}
</script>

<template>
  <AppDialog
    v-model="dialogOpen"
    :title="
      processUiStore.dialogActivityList.length === 1
        ? t('processes.listView.deleteActivity')
        : t('processes.listView.deleteProcessTemplate')
    "
    :confirmMessage="t('action.delete')"
    :cancelMessage="t('action.cancel')"
    destructive
    @confirm="deleteActivity"
    @cancel="() => processUiStore.closeDialog(PROCESS_DIALOG.DELETE_ACTIVITY)"
  >
    <p>
      {{
        processUiStore.dialogActivityList.length === 1
          ? t("processes.listView.deleteActivityText", {
              name: processUiStore.dialogActivityList[0].name,
            })
          : t(
              "processes.listView.deleteProcessTemplateText",
              {
                name: processUiStore.dialogActivityList[0].name,
                subActivityCount: processUiStore.dialogActivityList.length - 1,
              },
              processUiStore.dialogActivityList.length - 1,
            )
      }}
    </p>
  </AppDialog>
</template>
