<script setup lang="ts">
import { computed } from "vue";

import { useEnumOptions, useI18n } from "@/app/base/utils/i18n";
import { useDefaultModel } from "@/app/base/utils/useModel";
import EntityLinkerPanel from "@/app/common/linker/EntityLinkerPanel.vue";
import { CustomerType } from "@/services/backend/models/CustomerType";
import { type CustomerView } from "@/services/backend/models/CustomerView";

const props = withDefaults(
  defineProps<{
    items: CustomerView[];
    open?: boolean;
    single?: boolean;
    onLink: (value: CustomerView[]) => Promise<void>;
    onCreate: (value: CustomerView) => void;
    customerType: CustomerType | undefined;
  }>(),
  {
    open: undefined,
    single: false,
  },
);

const emit = defineEmits<(e: "update:open", value: boolean) => void>();

const { t } = useI18n();

const createButtonSubtypes = useEnumOptions(
  "customers.customerType",
  CustomerType,
);

const heading = computed(() => {
  if (props.customerType) {
    return props.customerType === CustomerType.LAND_OWNER
      ? t("customers.linkLandOwner")
      : t("customers.linkProjectDeveloper");
  } else {
    return t("customers.link");
  }
});

const openModel = useDefaultModel(props, "open", true)(emit);

async function link(customerIds: number[]) {
  await props.onLink(
    props.items.filter((item) => customerIds.includes(item.id)),
  );
  openModel.value = false;
}
</script>

<template>
  <EntityLinkerPanel
    v-model:open="openModel"
    :single="single"
    :onLink="link"
    :createButtonSubtypes="customerType ? [] : createButtonSubtypes"
    :items="items"
    :loadingNames="t('customers.customer', 2)"
    :heading="heading"
  />
</template>
