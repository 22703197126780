import { defineStore } from "pinia";
import { ref } from "vue";
import { useRouter } from "vue-router";

import { type Area } from "@/services/backend/models/Area";
import { type ContactView } from "@/services/backend/models/ContactView";
import { type CustomerView } from "@/services/backend/models/CustomerView";
import { type OpportunityView } from "@/services/backend/models/OpportunityView";
import { type ProjectView } from "@/services/backend/models/ProjectView";

export const useLinkStore = defineStore("links", () => {
  const customerLinks = ref<CustomerView[]>();
  const contactLinks = ref<ContactView[]>();
  const projectLinks = ref<ProjectView[]>();
  const opportunityLinks = ref<OpportunityView[]>();
  const areaLinks = ref<Area[]>();

  const router = useRouter();

  router.beforeEach((to, from) => {
    // ignore hash-only changes
    if (to.path !== from.path) {
      customerLinks.value = [];
      contactLinks.value = [];
      projectLinks.value = [];
      opportunityLinks.value = [];
      areaLinks.value = [];
    }
  });

  return {
    customerLinks,
    contactLinks,
    projectLinks,
    opportunityLinks,
    areaLinks,
  };
});
