<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";

import { useEnumOptions } from "@/app/base/utils/i18n";
import AreaTileInfo from "@/app/common/area/AreaTileInfo.vue";
import { type WindStats } from "@/app/common/area/WindStats";
import KanbanBoard from "@/app/common/kanban/KanbanBoard.vue";
import { type OpportunityView } from "@/services/backend/models/OpportunityView";
import { SalesStage } from "@/services/backend/models/SalesStage";

const props = defineProps<{
  opportunities: OpportunityView[];
}>();

const emit = defineEmits<{
  (e: "move", opportunity: OpportunityView, newStage: SalesStage): void;
  (e: "open", opportunity: OpportunityView): void;
}>();

const router = useRouter();

const getOpportunity = (id: number) =>
  props.opportunities.find((opportunity) => opportunity.id === id);

const stages = useEnumOptions("opportunities.stage", SalesStage);

const cssGridTemplateRows = computed(() => stages.value.length);

const stagesWithLink = computed(() =>
  stages.value.map((stage) => ({
    ...stage,
    onClick() {
      return router.push(
        router.resolve({
          name: "opportunityList",
          params: { salesStages: [stage.value] },
        }).href,
      );
    },
  })),
);

const move = (id: number, newStage: string) => {
  const opportunity = getOpportunity(id);
  if (opportunity) {
    emit("move", opportunity, newStage as SalesStage);
  }
};

const toWindStats = (opportunityView: OpportunityView): WindStats => {
  return {
    name: opportunityView.name,
    area: opportunityView.areaHaDevelopableSum,
    capacity: opportunityView.totalPowerMwSum,
  };
};
</script>

<template>
  <KanbanBoard
    class="opportunity-kanban"
    :items="opportunities"
    columnKey="stage"
    idKey="id"
    :columns="stagesWithLink"
    @move="move"
  >
    <template #item="{ item: opportunity }: { item: OpportunityView }">
      <VCardText @click="emit('open', opportunity)">
        <AreaTileInfo :area="toWindStats(opportunity)" />
      </VCardText>
    </template>
  </KanbanBoard>
</template>

<style scoped>
.opportunity-kanban {
  grid-template-columns: repeat(v-bind(cssGridTemplateRows), 1fr);
}
</style>
