import { defineStore, storeToRefs } from "pinia";
import { computed, type Ref, ref, watch } from "vue";
import { useRouter } from "vue-router";

import { useLinkStore } from "@/app/common/store/LinkStore";
import { type CancelablePromise } from "@/services/backend/core/CancelablePromise";
import { type ContactView } from "@/services/backend/models/ContactView";
import { type CustomerView } from "@/services/backend/models/CustomerView";
import { type OpportunityView } from "@/services/backend/models/OpportunityView";
import { type ProjectView } from "@/services/backend/models/ProjectView";

interface AttachmentStructure<T> {
  items: T[];
  potentialContacts: ContactView[];
  potentialCustomers: CustomerView[];
  potentialOpportunities: OpportunityView[];
  potentialProjects: ProjectView[];
}

interface BaseAttachment {
  id: number;
  creationDate: string;
  lastModificationDate: string;
  contactId?: number;
  customerId?: number;
  opportunityId?: number;
  projectId?: number;
}

interface FetchParams {
  projectId?: number;
  customerId?: number;
  contactId?: number;
  opportunityId?: number;
}

export function defineAttachmentStore<AT extends BaseAttachment>({
  storeId,
  service,
}: {
  storeId: string;
  service: {
    getAll: (params: FetchParams) => CancelablePromise<AttachmentStructure<AT>>;
    create: (requestBody: AT) => CancelablePromise<AT>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    update: (id: number, requestBody: AT) => CancelablePromise<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    remove: (id: number) => CancelablePromise<any>;
  };
}) {
  return defineStore(storeId, () => {
    const initialized = ref(false);
    const currentRequest = ref<CancelablePromise<AttachmentStructure<AT>>>();
    const activeReferences = ref<FetchParams>();

    const linkStore = useLinkStore();

    const router = useRouter();

    router.beforeEach((to, from) => {
      initialized.value = false;
      // ignore hash-only changes
      if (to.path !== from.path && currentRequest.value) {
        currentRequest.value?.cancel();
      }
    });

    const { customerLinks, contactLinks, projectLinks, opportunityLinks } =
      storeToRefs(linkStore);
    watch(
      [customerLinks, contactLinks, projectLinks, opportunityLinks],
      async () => {
        if (!initialized.value) {
          return;
        }
        await fetch(activeReferences.value);
      },
      { deep: true },
    );

    const items = ref<BaseAttachment[]>([]);
    const editedItems = ref<Map<number, AT>>(new Map<number, AT>()) as Ref<
      Map<number, AT>
    >;
    const loading = ref(false);

    const potentialContacts = ref<ContactView[]>([]);
    const potentialCustomers = ref<CustomerView[]>([]);
    const potentialOpportunities = ref<OpportunityView[]>([]);
    const potentialProjects = ref<ProjectView[]>([]);

    const assignedContactLookup = computed(() => {
      return provideAssignedLookup(potentialContacts.value, "contactId");
    });
    const assignedCustomerLookup = computed(() => {
      return provideAssignedLookup(potentialCustomers.value, "customerId");
    });
    const assignedOpportunityLookup = computed(() => {
      return provideAssignedLookup(
        potentialOpportunities.value,
        "opportunityId",
      );
    });
    const assignedProjectLookup = computed(() => {
      return provideAssignedLookup(potentialProjects.value, "projectId");
    });

    const assignedContact = (attachmentId: number) =>
      assignedContactLookup.value.get(attachmentId);
    const assignedCustomer = (attachmentId: number) =>
      assignedCustomerLookup.value.get(attachmentId);
    const assignedOpportunity = (attachmentId: number) =>
      assignedOpportunityLookup.value.get(attachmentId);
    const assignedProject = (attachmentId: number) =>
      assignedProjectLookup.value.get(attachmentId);

    async function fetch(params?: FetchParams) {
      if (params === undefined) {
        return;
      }

      activeReferences.value = params;
      loading.value = true;
      if (currentRequest.value) {
        currentRequest.value?.cancel();
      }
      currentRequest.value = service.getAll(params ?? {});
      await currentRequest.value.then(
        (result: AttachmentStructure<AT>) => {
          items.value = result.items;
          potentialContacts.value = result.potentialContacts;
          potentialCustomers.value = result.potentialCustomers;
          potentialOpportunities.value = result.potentialOpportunities;
          potentialProjects.value = result.potentialProjects;
          loading.value = false;
          currentRequest.value = undefined;
          initialized.value = true;
        },
        () => {
          loading.value = false;
          currentRequest.value = undefined;
        },
      );
    }

    function provideAssignedLookup<T extends { id: number }>(
      sourceArray: T[],
      idFieldName: keyof BaseAttachment,
    ) {
      const result = new Map<number, T | undefined>();
      if (!sourceArray) {
        return result;
      }
      const editedItemIds = new Set<number>();
      editedItems.value.forEach((editedItem) => {
        result.set(
          editedItem.id,
          sourceArray.find((candidate) => {
            return candidate.id === editedItem[idFieldName];
          }),
        );
        editedItemIds.add(editedItem.id);
      });
      items.value.forEach((item) => {
        if (editedItemIds.has(item.id)) {
          return;
        }
        result.set(
          item.id,
          sourceArray.find((candidate) => {
            return candidate.id === item[idFieldName];
          }),
        );
      });
      return result;
    }

    async function create(item: AT) {
      await service.create(item);
      await fetch(activeReferences.value);
    }

    function createOrUpdatePending(changes: Partial<AT>) {
      if (changes.id === undefined) {
        console.warn("partial item should include an id!");
        return;
      }
      const existingItem = editedItems.value.get(changes.id);

      const newItem = existingItem
        ? {
            ...existingItem,
            ...changes,
          }
        : ({
            ...changes,
            creationDate: new Date().toISOString(),
            lastModificationDate: new Date().toISOString(),
            id: changes.id,
          } as AT);

      editedItems.value.set(changes.id, newItem);
    }

    function discardChanges(id: number) {
      editedItems.value.delete(id);
    }

    async function persistChanges(id: number) {
      const editedItem = editedItems.value.get(id);
      if (!editedItem) {
        console.warn(`No changes to persist for ID ${id}`);
        return;
      }
      if (editedItem.id === 0) {
        editedItem.creationDate = new Date().toISOString();
      }
      editedItem.lastModificationDate = new Date().toISOString();

      if (editedItem.id === 0) {
        await service.create(editedItem);
      } else {
        await service.update(id, editedItem);
      }
      await fetch(activeReferences.value);
      editedItems.value.delete(id);
    }

    async function remove(id: number) {
      await service.remove(id);
      await fetch(activeReferences.value);
    }

    function assignmentModelProvider<T extends { id: number }>(
      id: number,
      sourceArrayRef: Ref<T[]>,
      linkIdKey: keyof AT,
      idFieldsToClear: (keyof AT)[] = [],
    ) {
      return computed<T | undefined>({
        get: () => {
          const editedItem = editedItems.value.get(id);
          if (!editedItem || !editedItem[linkIdKey]) {
            return undefined;
          }
          return sourceArrayRef.value.find((it) => {
            return it.id === editedItem[linkIdKey];
          });
        },
        set: (value: T | undefined) => {
          const editedItem = editedItems.value.get(id);
          if (!editedItem) {
            return;
          }
          if (!value) {
            const changes: Partial<AT> = {
              ...editedItem,
              [linkIdKey]: undefined,
            };
            idFieldsToClear.forEach((key) => {
              changes[key] = undefined;
            });
            createOrUpdatePending(changes);
          } else {
            createOrUpdatePending({
              ...editedItem,
              [linkIdKey]: value.id,
            });
          }
        },
      });
    }

    return {
      potentialCustomers,
      assignedCustomer,
      potentialContacts,
      assignedContact,
      assignedContactLookup,
      potentialOpportunities,
      assignedOpportunity,
      potentialProjects,
      assignedProject,
      items,
      editedItems,
      loading,
      fetch,
      create,
      createOrUpdatePending,
      discardChanges,
      persistChanges,
      remove,
      assignmentModelProvider,
    };
  });
}
