<script setup lang="ts">
import DetailPane from "@/app/base/layout/DetailPane.vue";
import { useI18n } from "@/app/base/utils/i18n";
import { useDefaultModel } from "@/app/base/utils/useModel";
import ProjectDetail from "@/app/projects/single/ProjectDetail.vue";
import { AfterSalesStage } from "@/services/backend/models/AfterSalesStage";
import { type Project } from "@/services/backend/models/Project";

const props = withDefaults(
  defineProps<{
    open?: boolean;
    onAdd: (value: Project) => Promise<void> | void;
    userMail: string;
    location?: "left" | "bottom" | "right";
  }>(),
  { open: undefined, location: "right" },
);

const emit = defineEmits<(e: "update:open", value: boolean) => void>();

const { t } = useI18n();

const openModel = useDefaultModel(props, "open", true)(emit);

const project: Project = {
  id: 0,
  name: "",
  stage: AfterSalesStage.GENERAL_RESEARCH_AND_PLANNING_LAW,
  areaList: [],
  customers: [],
  creationDate: new Date().toISOString(),
  lastModificationDate: new Date().toISOString(),
};
</script>

<template>
  <DetailPane
    v-model="openModel"
    :location="location"
    temporary
    transient
    closeButton
    :header="t('projects.new')"
  >
    <ProjectDetail
      :project="project as any"
      :creating="true"
      hideHeader
      :button="t('ui.add')"
      :onSave="onAdd"
      :userMail="userMail"
      @close="openModel = false"
    />
  </DetailPane>
</template>
