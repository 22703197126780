<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, onMounted, ref, watch } from "vue";

import LoadingIndicator from "@/app/base/loading/LoadingIndicator.vue";
import {
  addToEndOfArray,
  deleteElementsFromArray,
} from "@/app/base/utils/array";
import { useI18n } from "@/app/base/utils/i18n";
import AreaDetailPanel from "@/app/common/area/AreaDetailPanel.vue";
import AreaLinkerPanel from "@/app/common/linker/AreaLinkerPanel.vue";
import { useLinkStore } from "@/app/common/store/LinkStore";
import { BackendService } from "@/services/backend/BackendService";
import { type ApiError } from "@/services/backend/core/ApiError";
import { type Area } from "@/services/backend/models/Area";
import { CustomerType } from "@/services/backend/models/CustomerType";
import { type Project } from "@/services/backend/models/Project";
import { ProjectControllerService } from "@/services/backend/services/ProjectControllerService";

const props = defineProps<{ projectId: number }>();

const { t } = useI18n();

const badGateway = ref(false);

const project = ref<Project | undefined>();

const { areaLinks, customerLinks } = storeToRefs(useLinkStore());

onMounted(async () => {
  project.value = await ProjectControllerService.getProject({
    projectId: props.projectId,
  });
});

// Linked areas depend on the project. Right side components depend on the area links.
// Make sure to update the links in the store whenever the project changes.
watch(project, () => (areaLinks.value = project.value?.areaList ?? []));

// Linkable areas depend on the land owner linked to this project.
// As this link can change, watch the store and reload if the link changes.
const linkableAreas = ref<Area[]>([]);
const linkedArea = ref<Area | undefined>(undefined);

function getLinkableAreas() {
  return () => {
    if (customerLinks.value && customerLinks.value.length > 0) {
      BackendService.setReactively(
        linkableAreas,
        () =>
          ProjectControllerService.getLinkableAreas({
            projectId: props.projectId,
          }),
        (e: ApiError) => {
          if (e.status === 502) {
            badGateway.value = true;
            return true;
          }
          return false;
        },
      );
    } else {
      linkableAreas.value = [];
    }
  };
}

watch(customerLinks, getLinkableAreas(), { deep: true });
watch(areaLinks, getLinkableAreas(), { deep: true });
watch(
  areaLinks,
  () => {
    linkedArea.value =
      areaLinks.value && areaLinks.value.length > 0
        ? areaLinks.value[0]
        : undefined;
  },
  { deep: true },
);

const linking = ref(false);

const onLink = () => {
  linking.value = true;
};

const addAreas = async (areaIds: string[]) => {
  const areaId = areaIds[0];
  linking.value = false;
  await ProjectControllerService.addAreaLink({
    projectId: props.projectId,
    areaId,
  });
  const addedAreas =
    linkableAreas.value?.filter((area) => areaId === area.id) || [];
  deleteElementsFromArray(linkableAreas.value, ...addedAreas);
  addToEndOfArray(areaLinks.value, ...addedAreas);
};

const removeArea = async () => {
  if (linkedArea.value) {
    const areaId = linkedArea.value?.id;

    await ProjectControllerService.deleteAreaLinks({
      projectId: props.projectId,
      areaId,
    });
    const removedArea = areaLinks.value?.find((area) => area.id === areaId);
    if (removedArea) {
      deleteElementsFromArray(areaLinks.value, removedArea);
    }
  }
};

const landOwnerLinked = computed<boolean>(() => {
  return (
    customerLinks.value !== undefined &&
    customerLinks.value.findIndex(
      (customer) => customer.customerType === CustomerType.LAND_OWNER,
    ) !== -1
  );
});

const infrawindError = computed<boolean>(() => {
  const unenrichedAreasPresent =
    areaLinks.value?.findIndex((area) => !area.name) !== -1;
  return badGateway.value || unenrichedAreasPresent;
});
</script>

<template>
  <AreaDetailPanel
    v-if="project"
    :allowAdding="project.areaList.length === 0"
    :linkedArea="linkedArea"
    :onLink="onLink"
    :onRemove="removeArea"
    :landOwnerLinked="landOwnerLinked"
    :infrawindError="infrawindError"
  />
  <LoadingIndicator v-else :items="t('entities.area', 2)" />

  <AreaLinkerPanel
    v-model:open="linking"
    single
    :areas="linkableAreas || []"
    :onLink="addAreas"
  />
</template>
