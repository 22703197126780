<script setup lang="ts">
import { computed } from "vue";

import DetailPane from "@/app/base/layout/DetailPane.vue";
import { useI18n } from "@/app/base/utils/i18n";
import { useDefaultModel } from "@/app/base/utils/useModel";
import OpportunityDetail from "@/app/opportunities/single/OpportunityDetail.vue";
import { type Opportunity } from "@/services/backend/models/Opportunity";
import { SalesStage } from "@/services/backend/models/SalesStage";

const props = withDefaults(
  defineProps<{
    open?: boolean;
    salesStage?: SalesStage;
    onAdd: (value: Opportunity) => Promise<void> | void;
    userMail: string;
  }>(),
  {
    open: undefined,
    salesStage: SalesStage.LAND_OWNER_STATED,
  },
);

const emit = defineEmits<(e: "update:open", value: boolean) => void>();

const { t } = useI18n();

const openModel = useDefaultModel(props, "open", true)(emit);

const opportunity = computed<Opportunity>(() => ({
  id: 0,
  name: "",
  stage: props.salesStage,
  creationDate: new Date().toISOString(),
  customers: [],
  areaList: [],
  lastModificationDate: new Date().toISOString(),
}));
</script>

<template>
  <DetailPane
    v-model="openModel"
    location="right"
    temporary
    transient
    closeButton
    :header="t('opportunities.new')"
  >
    <OpportunityDetail
      :opportunity="opportunity"
      :creating="true"
      hideHeader
      :button="t('ui.add')"
      :onSave="onAdd"
      :userMail="userMail"
      @close="openModel = false"
    />
  </DetailPane>
</template>
