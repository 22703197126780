<script setup lang="ts">
import { computed, ref } from "vue";

import { NumberFormat, useI18n } from "@/app/base/utils/i18n";
import LandOwnerContractReporting from "@/app/reporting/landOwnerContract/LandOwnerContractReporting.vue";
import {
  ContractReportingStatus,
  type LandOwnerSalesStageByOpportunityWithContractStatus,
  salesStageToContractReportingStatus,
} from "@/app/reporting/landOwnerContract/LandOwnerContractReportingUtil";
import { BackendService } from "@/services/backend/BackendService";
import { SalesStage } from "@/services/backend/models/SalesStage";
import { type SalesStageCount } from "@/services/backend/models/SalesStageCount";
import { ReportingControllerService } from "@/services/backend/services/ReportingControllerService";

const { tEnum, n } = useI18n();

const label = (stages: SalesStage[]) => {
  if (stages.length === 1) {
    return tEnum("opportunities.stage", stages[0]);
  }
  const parts: string[] = [];
  stages.forEach((stage) => {
    parts.push(tEnum("opportunities.stage", stage), " / ");
  });
  parts.pop();
  return parts.join("");
};

function getStageCount(
  stageCounts: SalesStageCount[],
  stages: SalesStage[],
): number {
  return stageCounts
    .filter((stageCount) => stages.indexOf(stageCount.stage) > -1)
    .reduce((sum, stagesCount) => sum + stagesCount.count, 0);
}

const contractStatusFilter = ref<ContractReportingStatus | undefined>(
  undefined,
);

const updateStatusFilter = (status: ContractReportingStatus) => {
  if (contractStatusFilter.value === status) {
    contractStatusFilter.value = undefined;
  } else {
    contractStatusFilter.value = status;
  }
};

const opportunityByStage =
  await ReportingControllerService.countOpportunitiesByStage();

const landOwnerByOpp = BackendService.fetchReactively(() =>
  ReportingControllerService.getLandOwnerSalesStagesByOpportunity(),
);

const landOwnerByOppWithContractStatus = computed(() => {
  return landOwnerByOpp.value
    ?.map((lo) => {
      return {
        ...lo,
        contractStatus: salesStageToContractReportingStatus(
          lo.opportunitySalesStage,
        ),
      } as LandOwnerSalesStageByOpportunityWithContractStatus;
    })
    .filter(
      (lo) =>
        contractStatusFilter.value === undefined ||
        lo.contractStatus === contractStatusFilter.value,
    );
});

const funnelData = computed(() => {
  const transmittedAndNegotiationCount = getStageCount(opportunityByStage, [
    SalesStage.VSV_IN_NEGOTIATION,
  ]);
  const completedCount = getStageCount(opportunityByStage, [
    SalesStage.VSV_COMPLETED,
  ]);
  const declinedOrAbortedCount = getStageCount(opportunityByStage, [
    SalesStage.VSV_DECLINED,
    SalesStage.ABORT,
  ]);
  const remainingCount =
    opportunityByStage.reduce(
      (sum, stagesCount) => sum + stagesCount.count,
      0,
    ) -
    transmittedAndNegotiationCount -
    completedCount -
    declinedOrAbortedCount;

  return [
    {
      value: n(remainingCount, NumberFormat.INTEGER),
      label: "---",
      color: "funnel1",
      onClick: () => updateStatusFilter(ContractReportingStatus.EMPTY),
    },
    {
      value: n(transmittedAndNegotiationCount, NumberFormat.INTEGER),
      label: label([SalesStage.VSV_IN_NEGOTIATION]),
      color: "funnel2",
      onClick: () => updateStatusFilter(ContractReportingStatus.VSV_SENT),
    },
    {
      value: n(completedCount, NumberFormat.INTEGER),
      label: label([SalesStage.VSV_COMPLETED]),
      color: "funnel3",
      onClick: () => updateStatusFilter(ContractReportingStatus.VSV_COMPLETED),
    },
    {
      value: n(declinedOrAbortedCount, NumberFormat.INTEGER),
      label: label([SalesStage.VSV_DECLINED, SalesStage.ABORT]),
      color: "error",
      onClick: () => updateStatusFilter(ContractReportingStatus.VSV_REJECTED),
    },
  ];
});
</script>

<template>
  <LandOwnerContractReporting
    :landOwnersByOpp="landOwnerByOppWithContractStatus ?? []"
    :funnelData="funnelData"
  />
</template>

<style scoped></style>
