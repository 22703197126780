<script lang="ts" setup>
import { computed } from "vue";
import { useRouter } from "vue-router";

import { useActivityService } from "@/app/process/service/ActivityService";
import {
  PROCESS_DIALOG,
  useProcessUIStore,
} from "@/app/process/service/ProcessUIStore";

const router = useRouter();

const activityService = useActivityService();
const processUiStore = useProcessUIStore();
const dialogOpen = computed(() =>
  processUiStore.isDialogOpen(PROCESS_DIALOG.CREATE_TEMPLATE),
);

function createTemplate() {
  activityService
    .createNewProcessTemplate(processUiStore.dialogTemplateName)
    .then(
      (createdId) => {
        if (!createdId) {
          console.error("No ID returned from createNewProcessTemplate");
          return;
        }
        router
          .push({
            name: "processTemplate",
            params: { rootActivityId: createdId },
          })
          .catch((error) => console.error(error));
        processUiStore.closeDialog(PROCESS_DIALOG.CREATE_TEMPLATE);
      },
      (error) => console.error(error),
    );
}
</script>

<template>
  <VDialog v-model="dialogOpen" maxWidth="400">
    <VCard>
      <VCardTitle>{{ $t("processes.createProcessTemplate") }}</VCardTitle>
      <VCardText>
        <VTextField
          v-model="processUiStore.dialogTemplateName"
          density="compact"
          variant="outlined"
          :label="$t('processes.nameLabel')"
          hideDetails="auto"
          data-testid="create-process-template-dialog-name"
        />
      </VCardText>
      <VCardActions class="d-flex flex-column">
        <VBtn
          variant="flat"
          color="caeli5"
          :disabled="
            !processUiStore.dialogTemplateName ||
            !processUiStore.dialogTemplateName.length
          "
          block
          data-testid="create-process-template-dialog-create-button"
          @click="createTemplate"
        >
          {{ $t("action.create") }}
        </VBtn>
        <VBtn
          variant="flat"
          block
          @click="
            () => processUiStore.closeDialog(PROCESS_DIALOG.CREATE_TEMPLATE)
          "
        >
          {{ $t("action.cancel") }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>
