/**
 * This file defines Vue hooks for accessing v-models as computed refs, allowing
 * them to be passed on as mutable variables to child components’ v-models.
 */

import { computed, shallowRef, type WritableComputedRef } from "vue";

type Defined<T> = T extends undefined ? never : T;

const isDefined = <T>(value: T): value is Defined<T> => value !== undefined;

/**
 * The functions useModel and useDefaultModel return a UseModelEmitReceiver,
 * which takes an emit function and returns a writable ref to the model value.
 *
 * `emit` must be passed as part of a second parameter list in order to avoid
 * problems with type inference (specifically when there are two model
 * parameters of the same type, and when there is a default value).
 */
type UseModelEmitReceiver<Prop extends string, Value> = (
  emit: (e: `update:${Prop}`, val: Value) => void,
) => WritableComputedRef<Value>;

/**
 * Convert a v-model (combination of a prop and an `update:···` event) into a
 * writable ref.
 *
 * Note that the type of the prop and the event must exactly match, so if the
 * prop is optional, the event must include the type undefined.
 *
 * To avoid this limitation, use useDefaultModel.
 *
 * Example:
 *
 * ```typescript
 * const props = defineProps<{
 *   search: string;
 * }>();
 *
 * const emit = defineEmits<{
 *   (e: "update:search", value: string): void;
 * }>();
 *
 * const searchModel = useModel(props, "search")(emit);
 * ```
 */
export const useModel =
  <Props extends object, Prop extends Extract<keyof Props, string>>(
    props: Props,
    prop: Prop,
  ): UseModelEmitReceiver<Prop, Props[Prop]> =>
  (emit) => {
    return computed<Props[Prop]>({
      get(): Props[Prop] {
        return props[prop];
      },
      set(newValue) {
        if (props[prop] === newValue) {
          return;
        }
        emit(`update:${prop}`, newValue);
      },
    });
  };

/**
 * Convert a v-model (combination of a prop and an `update:···` event) into a
 * writable ref, using a default value if the provided value is undefined.
 *
 * Example:
 *
 * ```typescript
 * const props = defineProps<{
 *   search?: string;
 * }>();
 *
 * const emit = defineEmits<{
 *   (e: "update:search", value: string): void;
 * }>();
 *
 * const searchModel = useDefaultModel(props, "search", "")(emit);
 * ```
 */
export const useDefaultModel =
  <
    Props extends object,
    Prop extends Extract<keyof Props, string>,
    DefaultValue,
  >(
    props: Props,
    prop: Prop,
    defaultValue: DefaultValue,
  ): UseModelEmitReceiver<Prop, Defined<Props[Prop]> | DefaultValue> =>
  (emit) => {
    type Value = Defined<Props[Prop]> | DefaultValue;

    const internal = shallowRef<Value>(defaultValue);

    const get = (): Value => {
      const value = props[prop];
      return isDefined(value) ? value : internal.value;
    };

    return computed<Value>({
      get,
      set(newValue) {
        if (get() === newValue) {
          return;
        }
        internal.value = newValue;
        emit(`update:${prop}`, newValue);
      },
    });
  };
