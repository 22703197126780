<script setup lang="ts">
import { nextTick, ref, watch } from "vue";

const props = withDefaults(
  defineProps<{
    open?: boolean;
  }>(),
  {
    open: true,
  },
);

const content = ref<HTMLElement>();
const cssClosed = ref(!props.open);
const height = ref("auto");
const transitionMs = 500;

watch(
  () => props.open,
  async () => {
    if (content.value) {
      height.value = `${content.value.clientHeight}px`;
      await nextTick();
    }
    cssClosed.value = !props.open;
  },
);

const releaseHeight = () => {
  if (props.open) {
    height.value = "auto";
  } else {
    height.value = "0";
  }
};
</script>
<template>
  <div
    class="app-expandable"
    :class="{ closed: cssClosed }"
    @animationend="releaseHeight"
  >
    <div ref="content" class="app-expandable-content">
      <slot />
    </div>
  </div>
</template>

<style scoped>
@keyframes height-to-zero {
  from {
    height: v-bind(height);
  }
  to {
    height: 0;
  }
}

@keyframes height-from-zero {
  from {
    height: 0;
  }
  to {
    height: v-bind(height);
  }
}

@keyframes translate-to-zero {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

@keyframes translate-from-zero {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.app-expandable {
  overflow: hidden;
  height: v-bind(height);
  animation: calc(v-bind(transitionMs) * 1ms) ease both;
}

.app-expandable.closed {
  animation-name: height-to-zero;
}

.app-expandable:not(.closed) {
  animation-name: height-from-zero;
}

.app-expandable-content {
  animation: calc(v-bind(transitionMs) * 1ms) ease;
}

.app-expandable.closed .app-expandable-content {
  animation-name: translate-to-zero;
}

.app-expandable:not(.closed) .app-expandable-content {
  animation-name: translate-from-zero;
}
</style>
