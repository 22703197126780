/* istanbul ignore file */
/* eslint-disable */

export enum AfterSalesEvent {
  PLANNING_LAW_RESEARCH_COMPLETED = 'PLANNING_LAW_RESEARCH_COMPLETED',
  REQUEST_TO_FLIGHTCONTROL_COMPLETED = 'REQUEST_TO_FLIGHTCONTROL_COMPLETED',
  SITE_INSPECTION_COMPLETED = 'SITE_INSPECTION_COMPLETED',
  INITIAL_FAUNISTIC_ASSESSMENT_COMPLETED = 'INITIAL_FAUNISTIC_ASSESSMENT_COMPLETED',
  REQUEST_TO_ARMED_FORCES_COMPLETED = 'REQUEST_TO_ARMED_FORCES_COMPLETED',
  FINAL_PLANNING_COMPLETED = 'FINAL_PLANNING_COMPLETED',
  REQUEST_FOR_POWER_CONNECTION_COMPLETED = 'REQUEST_FOR_POWER_CONNECTION_COMPLETED',
  INFO_MEMO_AND_PROCEDURE_LETTER_CREATED = 'INFO_MEMO_AND_PROCEDURE_LETTER_CREATED',
  AUCTION_SETUP_INCL_DATA_ROOM_COMPLETED = 'AUCTION_SETUP_INCL_DATA_ROOM_COMPLETED',
  SITE_CHECK_COMPLETED = 'SITE_CHECK_COMPLETED',
  AUCTION_STARTED = 'AUCTION_STARTED',
  AUCTION_COMPLETED = 'AUCTION_COMPLETED',
  PERMISSION_CONTRACT_SIGNED = 'PERMISSION_CONTRACT_SIGNED',
  SIGNING_FEE_ACCOUNTED = 'SIGNING_FEE_ACCOUNTED',
  PROOF_OF_THE_COMMISSIONING_OF_THE_AVIFAUNAL_SURVEY = 'PROOF_OF_THE_COMMISSIONING_OF_THE_AVIFAUNAL_SURVEY',
  PROOF_OF_THE_FINALISED_AVIFAUNAL_REPORT = 'PROOF_OF_THE_FINALISED_AVIFAUNAL_REPORT',
  PROOF_OF_THE_SUBMITTED_APPLICATION_ACCORDING_TO_BIMSCHG = 'PROOF_OF_THE_SUBMITTED_APPLICATION_ACCORDING_TO_BIMSCHG',
  PROOF_OF_THE_ISSUED_PERMIT_ACCORDING_TO_BIMSCHG = 'PROOF_OF_THE_ISSUED_PERMIT_ACCORDING_TO_BIMSCHG',
  PROOF_OF_THE_START_OF_CONSTRUCTION = 'PROOF_OF_THE_START_OF_CONSTRUCTION',
  PROOF_OF_COMMISSIONING = 'PROOF_OF_COMMISSIONING',
}
