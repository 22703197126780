import { defineStore } from "pinia";

import { useStoreCache } from "@/app/common/store/useStoreCache";
import { type Opportunity } from "@/services/backend/models/Opportunity";
import { OpportunityControllerService } from "@/services/backend/services/OpportunityControllerService";

export const useOpportunityStore = defineStore("opportunity", () => {
  const { fetch, update, modify, remove, cache } = useStoreCache<Opportunity>(
    "opportunity",
    (opportunityId) =>
      OpportunityControllerService.getOpportunity({ opportunityId }),
    (opportunity) =>
      OpportunityControllerService.updateOpportunity({
        opportunityId: opportunity.id,
        requestBody: opportunity,
      }),
    (opportunityId) =>
      OpportunityControllerService.deleteOpportunity({ opportunityId }),
  );

  return { fetch, update, modify, remove, cache };
});
