<script setup lang="ts">
import AppButton from "@/app/base/button/AppButton.vue";
import SidebarCard from "@/app/base/sidebar/SidebarCard.vue";
import SidebarCardContainer from "@/app/base/sidebar/SidebarCardContainer.vue";
import { useI18n } from "@/app/base/utils/i18n";
import { type CustomerView } from "@/services/backend/models/CustomerView";

withDefaults(
  defineProps<{
    customers: CustomerView[];
    removeButtonLabel: string;
    removeButtonDisabled?: (customerId: number) => boolean;
  }>(),
  {
    removeButtonDisabled: () => {
      return false;
    },
  },
);

const emit = defineEmits<{
  (e: "remove-customer", customer: CustomerView): void;
  (e: "open-customer", customer: CustomerView): void;
}>();

const { t } = useI18n();
</script>

<template>
  <SidebarCardContainer :emptyMessage="t('customers.noLinked')">
    <SidebarCard
      v-for="customer in customers"
      :key="customer.id"
      :openTooltip="t('customers.openCustomer')"
      @open="emit('open-customer', customer)"
    >
      <template #top-buttons>
        <AppButton
          size="x-small"
          icon="mdi-close"
          highlight
          :disabled="removeButtonDisabled(customer.id)"
          :tooltip="removeButtonLabel"
          @click="emit('remove-customer', customer)"
        />
      </template>

      <div class="pb-1">{{ customer.name }}</div>
    </SidebarCard>
  </SidebarCardContainer>
</template>
