<script setup lang="ts">
import { computed } from "vue";

import { registerFunnel } from "./FunnelProvider";
import { type FunnelStage } from "./FunnelStage";

const props = defineProps<{
  title?: string;
  subtitle?: string;
  stages: FunnelStage[];
}>();

const stageCount = computed(() => props.stages.length);

const labelledColumns = registerFunnel();
</script>

<template>
  <header class="funnel-title mb-3">
    <a v-if="title" class="title">{{ title }}</a>
    <a v-if="subtitle" class="subtitle">{{ subtitle }}</a>
  </header>

  <div class="funnel-container">
    <VCard
      v-for="(stage, i) of stages"
      :key="stage.label"
      class="funnel-row"
      :style="{ '--stage-index': i }"
      :color="stage.color"
      @click="stage.onClick?.()"
    >
      {{ stage.value }}
    </VCard>
  </div>
  <div v-if="labelledColumns" class="funnel-labels">
    <div
      v-for="stage of stages"
      :key="stage.label"
      class="funnel-label-container"
    >
      <div
        class="funnel-label"
        :style="{
          '--label-span': labelledColumns,
          borderBottomColor: `rgb(var(--v-theme-${stage.color}))`,
        }"
      >
        {{ stage.label }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.funnel-title {
  grid-row: title;
  text-align: center;
}

.subtitle {
  font-size: 1.17em; /*h3 font size */
}
.title {
  font-size: 1.5em; /*h2 font size*/
}

.funnel-container,
.funnel-labels {
  grid-row: funnel;
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  max-height: 30em;
  hyphens: auto;
}

.funnel-row {
  width: 100%;
  max-width: 9.5em;
  margin: auto;
  flex: 1;
  display: flex;
  justify-content: center;
  border-radius: 0;
  border: solid white;
  border-width: 0.1em 0;
  font-size: 2em;
  transform: translateZ(
    0
  ); /* this seems to fix the grey lines bug in chrome most of the times (from https://stackoverflow.com/questions/51542919/clip-path-on-chrome-leaves-a-strange-line-on-the-edge) */
  align-items: center;
  color: white !important;
  clip-path: polygon(
    calc(35% * var(--stage-index) / v-bind(stageCount)) 0,
    calc(100% - 35% * var(--stage-index) / v-bind(stageCount)) 0,
    calc(100% - 35% * (var(--stage-index) + 1) / v-bind(stageCount)) 100%,
    calc(35% * (var(--stage-index) + 1) / v-bind(stageCount)) 100%
  );
}

.funnel-labels {
  grid-row: funnel;
}

.funnel-label-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.funnel-label {
  margin: auto 0;
  --overhang-left: calc(
    (200% + var(--funnel-carousel-gap-x)) * var(--label-span) - 100%
  );
  margin-left: calc(-1 * var(--overhang-left));
  padding-left: var(--overhang-left);
  border-bottom: 1px solid currentColor;
}
</style>
