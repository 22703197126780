<script setup lang="ts">
import { useDefaultModel } from "@/app/base/utils/useModel";

const props = withDefaults(
  defineProps<{
    label?: string;

    /** When single is set, the control will look like a radio button */
    single?: boolean;

    modelValue?: boolean;
    density?: "default" | "compact" | "comfortable";
  }>(),
  {
    label: undefined,
    single: false,
    modelValue: undefined,
    density: "default",
  },
);

const emit = defineEmits<(e: "update:modelValue", value: boolean) => void>();

const model = useDefaultModel(props, "modelValue", false)(emit);
</script>

<template>
  <VCheckbox
    v-model="model"
    class="app-checkbox"
    :density="density"
    hideDetails="auto"
    :label="label"
    :falseIcon="single ? '$radioOff' : '$checkboxOff'"
    :trueIcon="single ? '$radioOn' : '$checkboxOn'"
  />
</template>

<style scoped>
.app-checkbox :deep(.v-selection-control) {
  height: unset;
  contain: none;
}

.app-checkbox {
  grid-template-columns: max-content minmax(max-content, 1fr) max-content;
}
</style>
